import { Condition } from './condition';
import { SelectItem } from 'primeng/api';

export interface SelectableCondition {
  conditionTypes: Condition[];
  conditionValues: SelectItem[];
  selectedType?: Condition;
  selectedValue?: Condition;
}

export class SelectableConditionImpl implements SelectableCondition {
  conditionTypes: Condition[];
  conditionValues: SelectItem[];
  selectedType?: Condition;
  selectedValue?: Condition;
}
