/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./error-popup.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/primeng/components/messages/messages.ngfactory";
import * as i3 from "primeng/components/messages/messages";
import * as i4 from "primeng/components/common/messageservice";
import * as i5 from "./error-popup.component";
import * as i6 from "../../logging-errors.service";
var styles_ErrorPopupComponent = [i0.styles];
var RenderType_ErrorPopupComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ErrorPopupComponent, data: {} });
export { RenderType_ErrorPopupComponent as RenderType_ErrorPopupComponent };
export function View_ErrorPopupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p-messages", [], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = ((_co.errors = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_Messages_0, i2.RenderType_Messages)), i1.ɵdid(1, 245760, null, 0, i3.Messages, [[2, i4.MessageService]], { value: [0, "value"] }, { valueChange: "valueChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errors; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ErrorPopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-error-popup", [], null, null, null, View_ErrorPopupComponent_0, RenderType_ErrorPopupComponent)), i1.ɵdid(1, 114688, null, 0, i5.ErrorPopupComponent, [i6.LoggingErrorsService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ErrorPopupComponentNgFactory = i1.ɵccf("app-error-popup", i5.ErrorPopupComponent, View_ErrorPopupComponent_Host_0, {}, {}, []);
export { ErrorPopupComponentNgFactory as ErrorPopupComponentNgFactory };
