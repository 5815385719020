export interface RegistrationsResponse {
  cards: RegistrationsTableRowDataDto[];
}

export interface RegistrationsTableRowDataDto {
  id: number;
  createdAt: string;
  status: RegistrationStatusEnum;
  firstName?: string;
  lastName?: string;
  dateTimestamp?: number;
}

export enum RegistrationStatusEnum {
  REQUESTED = 'REQUESTED',
  ACCEPTED = 'ACCEPTED',
  DENIED = 'DENIED'
}

