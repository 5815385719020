<p-dialog header="{{headerName}}"
          [(visible)]="displayModal"
          (onHide)="hideTreeDetailsModal()"
          [modal]="true"
          [draggable]="false"
          [dismissableMask]="true">

  <div *ngIf="graph">
    <label for="Name" style="margin-right: 10px">
      {{'drs.graph.root.node.details.modal.field.name' | translate}}
    </label>
    <input pInputText id="Name" [size]="50" [(ngModel)]="graph.label"/>
  </div>

  <p-footer>
    <button type="button"
            pButton
            icon="pi pi-check"
            label="{{'btn.save' | translate}}"
            (click)="onSaveTreeDetails()"></button>
  </p-footer>
</p-dialog>
