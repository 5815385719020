/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./waiting-room.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./patient-card/patient-card.component.ngfactory";
import * as i4 from "./patient-card/patient-card.component";
import * as i5 from "../../core/user.service";
import * as i6 from "@angular/common";
import * as i7 from "./waiting-room.component";
import * as i8 from "../../skygear.service";
import * as i9 from "../../drs/shared/services/chat.service";
var styles_WaitingRoomComponent = [i0.styles];
var RenderType_WaitingRoomComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WaitingRoomComponent, data: {} });
export { RenderType_WaitingRoomComponent as RenderType_WaitingRoomComponent };
function View_WaitingRoomComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " (", ") "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("chat.waitingRoom.sortField.open")); var currVal_1 = _co.countOpenConversations; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_WaitingRoomComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " (", ") "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("chat.waitingRoom.sortField.mine")); var currVal_1 = _co.countMineConversations; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_WaitingRoomComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " (", ") "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("chat.waitingRoom.sortField.completed")); var currVal_1 = _co.countCompletedConversations; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_WaitingRoomComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-patient-card", [["style", "margin: 0;"]], null, [[null, "selectedPatient"], [null, "finishedConversation"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedPatient" === en)) {
        var pd_0 = (_co.onSelectPatient($event) !== false);
        ad = (pd_0 && ad);
    } if (("finishedConversation" === en)) {
        var pd_1 = (_co.onFinishConversation($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_PatientCardComponent_0, i3.RenderType_PatientCardComponent)), i1.ɵdid(1, 49152, null, 0, i4.PatientCardComponent, [i2.TranslateService, i5.UserService], { patient: [0, "patient"], isSelected: [1, "isSelected"] }, { selectedPatient: "selectedPatient", finishedConversation: "finishedConversation" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = (_v.context.$implicit.misantoConversation.id === ((_co.currentPatient == null) ? null : ((_co.currentPatient.misantoConversation == null) ? null : _co.currentPatient.misantoConversation.id))); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_WaitingRoomComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { filterTopBar: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 16, "div", [["class", "waiting-room-container"]], null, null, null, null, null)), i1.ɵprd(512, null, i6.ɵNgClassImpl, i6.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i6.NgClass, [i6.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "show": 0 }), (_l()(), i1.ɵeld(5, 0, [[1, 0], ["filterTopBar", 1]], null, 9, "div", [["class", "top-menu-toolbar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, [["btnOpen", 1]], null, 2, "div", [["class", "filter-buttons"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleFilter(i1.ɵnov(_v, 6), _co.FILTER_OPEN) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WaitingRoomComponent_1)), i1.ɵdid(8, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, [["btnMine", 1]], null, 2, "div", [["class", "filter-buttons"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleFilter(i1.ɵnov(_v, 9), _co.FILTER_MINE) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WaitingRoomComponent_2)), i1.ɵdid(11, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, [["btnCompleted", 1]], null, 2, "div", [["class", "filter-buttons"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleFilter(i1.ɵnov(_v, 12), _co.FILTER_COMPLETED) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WaitingRoomComponent_3)), i1.ɵdid(14, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 2, "div", [["class", "all-patients-cards-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WaitingRoomComponent_4)), i1.ɵdid(17, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "waiting-room-container"; var currVal_1 = _ck(_v, 4, 0, _co.activeClass); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.allPatientsWaiting; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.allPatientsWaiting; _ck(_v, 11, 0, currVal_3); var currVal_4 = _co.allPatientsWaiting; _ck(_v, 14, 0, currVal_4); var currVal_5 = _co.patientsWaiting; _ck(_v, 17, 0, currVal_5); }, null); }
export function View_WaitingRoomComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-waiting-room", [], null, null, null, View_WaitingRoomComponent_0, RenderType_WaitingRoomComponent)), i1.ɵdid(1, 245760, null, 0, i7.WaitingRoomComponent, [i8.SkygearService, i9.ChatService, i1.NgZone], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WaitingRoomComponentNgFactory = i1.ɵccf("app-waiting-room", i7.WaitingRoomComponent, View_WaitingRoomComponent_Host_0, { messageReceived: "messageReceived", activeClass: "activeClass", conversationFinishedSuccessfully: "conversationFinishedSuccessfully" }, { selectedPatient: "selectedPatient", finishedConversation: "finishedConversation", setNewPatient: "setNewPatient", restartChatPanel: "restartChatPanel" }, []);
export { WaitingRoomComponentNgFactory as WaitingRoomComponentNgFactory };
