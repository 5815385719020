import * as _ from 'lodash';
import { ISubscription } from 'rxjs/src/Subscription';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DrsConditionImpl } from './condition';
import { SelectableCondition } from './selectable-condition';
import { DrsAnswerDto } from '../shared/model/drs-answer-dto';
import { DrsClassificationType } from '../shared/model/drs-classification-type';
import { ConditionType, DrsConditionService } from '../shared/services/drs.condition.service';

@Component({
  selector: 'app-condition',
  templateUrl: './condition.component.html',
  styleUrls: ['./condition.component.scss'],
})
export class ConditionComponent implements OnInit, OnDestroy {
  @Input() condition: SelectableCondition;
  @Input() answers: DrsAnswerDto[];
  @Input() classificationTypes: DrsClassificationType[];
  @Input() disabled: boolean;

  private possibleAnswerModifiedSubscription: ISubscription;

  constructor(private drsConditionService: DrsConditionService) {}

  ngOnInit(): void {
    this.possibleAnswerModifiedSubscription = this.drsConditionService.onPossibleAnswerModifiedSelect$.subscribe(
      event => this.onPossibleAnswerModified(),
    );
  }

  ngOnDestroy(): void {
    this.possibleAnswerModifiedSubscription.unsubscribe();
  }

  private onPossibleAnswerModified() {
    if (this.condition.selectedType && this.condition.selectedType.type === ConditionType.ANSWER) {
      this.updateClassificationValuesOnAnswerModified(this.condition.selectedType);
    }
  }

  onClassificationTypeChange(event) {
    const selectedClassificationType = <DrsConditionImpl>event.value;
    this.condition.selectedType = selectedClassificationType;
    this.updateClassificationValuesOnTypeChange(selectedClassificationType);
  }

  private updateClassificationValuesOnTypeChange(selectedClassificationType: DrsConditionImpl) {
    this.updateConditionValues(selectedClassificationType);
    if (this.condition.conditionValues && this.condition.conditionValues.length > 0) {
      this.condition.selectedValue = this.condition.conditionValues[0].value;
    }
  }

  private updateClassificationValuesOnAnswerModified(selectedClassificationType: DrsConditionImpl) {
    this.updateConditionValues(selectedClassificationType);
    if (this.condition.selectedValue) {
      this.condition.selectedValue = _.find(
        this.condition.conditionValues,
        condition => condition.value.uuid === this.condition.selectedValue.uuid,
      ).value;
    }
    if (!this.condition.selectedValue && this.condition.conditionValues && this.condition.conditionValues.length > 0) {
      this.condition.selectedValue = this.condition.conditionValues[0].value;
    }
  }

  private updateConditionValues(selectedClassificationType: DrsConditionImpl) {
    this.condition.conditionValues = this.drsConditionService.getConditionValuesForConditionType(
      selectedClassificationType,
      this.answers,
      this.classificationTypes,
    );
  }

  onClassificationValueChange(event) {
    this.condition.selectedValue = event.value;
  }
}
