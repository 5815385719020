/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./chat-header-timer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./chat-header-timer.component";
import * as i4 from "@ngx-translate/core";
var styles_ChatHeaderTimerComponent = [i0.styles];
var RenderType_ChatHeaderTimerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChatHeaderTimerComponent, data: {} });
export { RenderType_ChatHeaderTimerComponent as RenderType_ChatHeaderTimerComponent };
function View_ChatHeaderTimerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "chat-timer-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 4, "span", [["class", "customer-timer-span"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(5, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(6, { "red-timer": 0 }), (_l()(), i1.ɵted(7, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "customer-timer-span"; var currVal_2 = _ck(_v, 6, 0, (_co.timerIsAboveTimeLimit && _co.patient.isInStateOngoing())); _ck(_v, 5, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.customerWaitingTimeLabel; _ck(_v, 2, 0, currVal_0); var currVal_3 = _co.customerWaitingTime; _ck(_v, 7, 0, currVal_3); }); }
export function View_ChatHeaderTimerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatHeaderTimerComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.patient; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ChatHeaderTimerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-chat-header-timer", [], null, null, null, View_ChatHeaderTimerComponent_0, RenderType_ChatHeaderTimerComponent)), i1.ɵdid(1, 573440, null, 0, i3.ChatHeaderTimerComponent, [i4.TranslateService], null, null)], null, null); }
var ChatHeaderTimerComponentNgFactory = i1.ɵccf("app-chat-header-timer", i3.ChatHeaderTimerComponent, View_ChatHeaderTimerComponent_Host_0, { patient: "patient" }, {}, []);
export { ChatHeaderTimerComponentNgFactory as ChatHeaderTimerComponentNgFactory };
