<p-messages
  [value]="localMessages$ | async"></p-messages>

<app-preview-panel [isPreviewSidebarOpen]="isPreviewSidebarOpen" [previewData]="previewData"
                   (previewPopupClose)="changeIsPreviewSidebarOpen($event)"></app-preview-panel>

<p-confirmDialog header="{{'drs.edit.relation.delete.confirm.header' | translate}}"
                 icon="pi pi-question-circle"
                 [style]='{width: "425px"}'
                 #cd>
  <p-footer>
    <button type="button" pButton icon="pi pi-times" label="{{'btn.no' | translate}}" (click)="cd.reject()"></button>
    <button type="button" pButton icon="pi pi-check" label="{{'btn.yes' | translate}}" (click)="cd.accept()"></button>
  </p-footer>
</p-confirmDialog>

<div class="tree-container" #drsGraphContainer>
  <p-organizationChart [value]="drsTreeData"
                       selectionMode="single"
                       [selection]="selectedNode"
                       (onNodeSelect)="onNodeSelect($event)"
                       (onNodeUnselect)="onNodeUnselect()">
    <ng-template let-node pTemplate="FEATURE_NODE">
      <div class="node">
        <div class="node-header ui-corner-top node-header">{{node.label}}</div>
      </div>
      <div class="tree-condition-panel" *ngIf="node.data.edge">
        <div class="tree-condition-panel-text">
          {{ buildConditionString(node.data.edge) }}
        </div>
      </div>
    </ng-template>
    <ng-template let-node pTemplate="LINK_NODE">
      <div class="node">
        <div class="node-header ui-corner-top" *ngIf="node.label">{{'drs.edit.linkNode.prefix' | translate}}&nbsp;&apos;{{node.label}}&apos;</div>
      </div>
      <div class="tree-condition-panel" *ngIf="node.data.edge">
        <div class="tree-condition-panel-text">
          {{ buildConditionString(node.data.edge) }}
        </div>
      </div>
    </ng-template>
  </p-organizationChart>
</div>

<div class="zoom-buttons">
  <button (click)="zoomInDrsTree()">+</button>
  <button (click)="zoomOutDrsTree()">&ndash;</button>
</div>

<p-sidebar (onHide)="hideClickableMap(); hidePreviewSidebar()" [(visible)]="editData" position="right" class="sidebar-right">
  <div *ngIf="editData" class="mis-drs-node-panel">
    <p-header>
      <h2 class="ui-panel-title">{{selectedNode?.label}}</h2>
      <div class="ui-helper-clearfix">
          <div class="btn-group">
            <button pButton
                    icon="pi pi-clock"
                    class="ui-button-raised ui-button-primary"
                    title="{{'drs.node.revisions.button.label' | translate}}"
                    (click)="showNodeRevisions()" ></button>
            <button pButton
                    icon="pi pi-copy"
                    class="ui-button-raised ui-button-primary"
                    title="{{'btn.copy' | translate}}"
                    (click)="showCopyPopup()"
                    [disabled]="isEditorLocked$ | async"></button>
            <button pButton
                    icon="pi pi-minus-circle"
                    class="ui-button-raised ui-button-primary"
                    title="{{'btn.cut' | translate}}"
                    (click)="showCutPopup()"
                    [disabled]="(isEditorLocked$ | async) || isRootNodeSelected()"></button>
          </div>
          <div class="btn-group">
            <button pButton
                    icon="pi pi-eye"
                    class="ui-button-raised ui-button-primary"
                    title="{{'btn.preview' | translate}}"
                    (click)="showPreviewSidebar()"></button>
          </div>
          <div class="btn-group">
            <button pButton
                    type="button"
                    icon="pi pi-save"
                    class="ui-button-raised ui-button-primary"
                    title="{{'btn.save' | translate}}"
                    *ngIf="!isEditFeatureMode"
                    (click)="onSaveNodeClicked($event)"
                    [disabled]="isEditorLocked$ | async"></button>
            <button pButton
                    type="button"
                    icon="pi pi-save"
                    class="ui-button-raised ui-button-primary red-btn"
                    title="{{'btn.save' | translate}}"
                    *ngIf="isEditFeatureMode"
                    (click)="onSaveFeatureClicked($event)"></button>
            <button pButton
                    type="button"
                    icon="pi pi-plus-circle"
                    class="ui-button-raised ui-button-primary"
                    title="{{'btn.duplicateFeature' | translate}}"
                    (click)="onDuplicateFeatureClicked($event)"
                    [disabled]="isEditorLocked$ | async"></button>
            <button pButton
                    type="button"
                    icon="pi pi-pencil"
                    class="ui-button-raised ui-button-primary red-btn"
                    title="{{'btn.editFeature' | translate}}"
                    (click)="onEditFeatureClicked($event)"
                    [disabled]="isEditFeatureMode || (isEditorLocked$ | async)"></button>
          </div>
        </div>
    </p-header>

    <div class="inner-wrapper" [ngClass]="{'edit-mode':isEditFeatureMode}" >
      <div class="wrapper-for-languges">
        <ul class="language-list">
          <li class="select">DE</li>
          <li>EN</li>
          <li>FR</li>
          <li>IT</li>
        </ul>
      </div>

      <div class="wrapper-for-answers">
        <span class="ui-float-label input-box ui-fluid">
          <input id="name-input" type="text" size="30" maxlength="255" pInputText [(ngModel)]="editData.name" [disabled]="!isEditFeatureMode">
          <label for="name-input" [translate]="'drs.edit.node.title'"></label>
        </span>
        <span class="ui-float-label input-box ui-fluid">
          <input id="question-input" type="text" size="30" maxlength="255" pInputText [(ngModel)]="editData.question" [disabled]="!isEditFeatureMode">
          <label for="question-input" [translate]="'drs.edit.node.question'"></label>
        </span>
        <span class="ui-float-label input-box ui-fluid">
          <input id="description-input" type="text" size="30" maxlength="255" pInputText [(ngModel)]="editData.description" [disabled]="!isEditFeatureMode">
          <label for="description-input" [translate]="'drs.edit.node.description'"></label>
        </span>
        <span class="ui-float-label input-box ui-fluid">
          <p-dropdown (onChange)="hideClickableMap()" [disabled]="!isEditFeatureMode" [options]="featureTypes"
                      [(ngModel)]="editData.featureRepresentationType" placeholder="Select a type"></p-dropdown>
        </span>
        <app-image-uploader (imageChange)="onImageUpload($event)" (featureImgId)="onDeleteImage($event)"
                            [imagePath]="imagePath" [disabled]="!isEditFeatureMode"
                            (imageUrlChange)="changeMapImageUrl($event)"></app-image-uploader>
        <div class="separator"></div>
        <div class="input-group">
          <div class="input-list-header">
            <label for="description-input" [translate]="'drs.edit.node.possibleAnswers'"></label>
            <button pButton
                    type="button"
                    icon="pi pi-plus"
                    label="{{'drs.edit.node.possibleAnswers.new' | translate}}"
                    class="ui-button-secondary btn-link"
                    (click)="onAddAnswerClicked($event)"
                    [disabled]="!isEditFeatureMode"></button>
          </div>
          <div *ngFor="let answer of editData.possibleAnswers; let i = index" class="input-list">
            <div class="ui-inputgroup">
              <span class="ui-inputgroup-addon">{{i + 1}}</span>
              <input id="answer_{{i}}" type="text" size="30" maxlength="255" pInputText [(ngModel)]="answer.description"
                     (blur)="onAnswerModified(answer)" [disabled]="!isEditFeatureMode">
              <button pButton type="button" icon="pi pi-times" class="ui-button-secondary"
                      (click)="onRemoveAnswerIfPossible(i)" [disabled]="!isEditFeatureMode"></button>
              <button style="margin-bottom: 0;" *ngIf="isFeatureTypeClickableMap()"
                      pButton type="button" icon="pi pi-user-edit" class="ui-button-secondary"
                      [disabled]="!isEditFeatureMode" (click)="openClickableMap(answer, editData)"></button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="inner-wrapper" *ngIf="!(selectedNode.data.featureType === featureType.CHAT)">
      <div class="relations-wrapper">
        <div class="input-group">
          <div class="input-list-header">
            <label for="description-input" [translate]="'drs.edit.node.edges'"></label>
            <button *ngIf="editData"
                    pButton
                    type="button"
                    icon="pi pi-plus"
                    label="{{'drs.edit.node.edges.new' | translate}}"
                    class="ui-button-secondary btn-link"
                    (click)="onAddEdgeClicked()"
                    [disabled]="!isEditFeatureMode"></button>
          </div>

          <p-accordion *ngIf="editData" [multiple]="true">
            <div *ngFor="let child of editChildren; let childIndex = index">
              <p-accordionTab header="{{child.data.name}}" [selected]="isNodeFocused(child)">
                <button pButton
                        type="button"
                        icon="pi pi-trash"
                        class="btn-accordion btn-icons"
                        (click)="onRemoveChildClicked(childIndex)"
                        [disabled]="!isEditFeatureMode"></button>
                <p class="conditions-if">
                  <span class="text" [translate]="'drs.edit.node.condition.if'">If</span>
                </p>
                <div class="wrapper-conditions">
                  <div *ngFor="let condition of child.conditions; let conditionIndex = index" class="condition-row">
                    <app-condition [condition]="condition"
                                   [answers]="editData.possibleAnswers"
                                   [classificationTypes]="drsAppData.classificationTypes"
                                   [disabled]="!isEditFeatureMode"></app-condition>
                    <button pButton
                            type="button"
                            *ngIf="condition.conditionValues"
                            icon="pi pi-times"
                            class="btn-remove-condition"
                            (click)="onRemoveCondition(child, conditionIndex)"
                            [disabled]="!isEditFeatureMode"></button>
                  </div>
                </div>
                <div class="input-list-header">
                  <button pButton
                          type="button"
                          class="ui-button-secondary btn-link"
                          icon="pi pi-plus"
                          iconPos="left"
                          label="{{'drs.edit.node.condition.new' | translate}}"
                          (click)="onAddCondition(child)"
                          [disabled]="!isEditFeatureMode"></button>
                </div>
                <div *ngIf="!isEditFeatureMode" class="search-component">
                  <div [ngSwitch]="child.type">
                    <ng-template #featureNode [ngSwitchCase]="'FEATURE_NODE'">
                      <app-search-feature (featureNodeSelected)="featureNodeSelected($event, child)"
                                          (featureNodeClear)="onRemoveRelation(child)"
                                          [placeholder]="child.data.name || 'drs.searchField.feature.placeholder' | translate"
                                          [noFeatureSelection]="!child.data.name"
                                          [labelField]="'name'"
                                          [typeOfSearchIsFeature]="true"
                                          [isFocused]="isNodeFocused(child)">
                      </app-search-feature>
                    </ng-template>
                    <ng-template #linkNode [ngSwitchCase]="'LINK_NODE'">
                      <app-search-feature (featureNodeSelected)="linkedGraphSelected($event, child)"
                                          (featureNodeClear)="onRemoveRelation(child)"
                                          [placeholder]="child.data.name || 'drs.searchField.graph.placeholder' | translate"
                                          [noFeatureSelection]="!child.data.name"
                                          [labelField]="'label'"
                                          [searchType]="drsGraphSearchType"
                                          [isFocused]="isNodeFocused(child)">
                      </app-search-feature>
                    </ng-template>
                    <ng-template #nodeTemplate [ngSwitchCase]="'GRAPH_TEMPLATE'">
                      <app-search-feature (featureNodeSelected)="nodeTemplateSelected($event, child)"
                                          (featureNodeClear)="onRemoveRelation(child)"
                                          [placeholder]="child.data.name || 'drs.edit.node.searchMode.nodeTemplate' | translate"
                                          [noFeatureSelection]="!child.data.name"
                                          [labelField]="'label'"
                                          [searchType]="graphTemplateSearchType"
                                          [isFocused]="isNodeFocused(child)">
                      </app-search-feature>
                    </ng-template>
                  </div>
                  <p-selectButton [options]="searchModeTypes" [(ngModel)]="child.type"></p-selectButton>
                  <div class="button-wrapper">
                    <button *ngIf="editData && !(selectedNode.data.featureType === featureType.DIAGNOSIS)"
                            pButton
                            type="button"
                            icon="pi pi-plus"
                            label="Status"
                            class="ui-button-raised ui-button-secondary node-button"
                            (click)="onCreateFeatureStatusClicked(child)"
                            [disabled]="isEditorLocked$ | async"></button>
                    <button *ngIf="editData && !(selectedNode.data.featureType === featureType.DIAGNOSIS)"
                            pButton
                            type="button"
                            icon="pi pi-plus"
                            label="Chat"
                            class="ui-button-raised ui-button-secondary chat-button"
                            (click)="onCreateFeatureChatClicked(child)"
                            [disabled]="isEditorLocked$ | async"></button>
                    <button *ngIf="editData"
                            pButton
                            type="button"
                            icon="pi pi-plus"
                            label="Procedere"
                            class="ui-button-raised ui-button-secondary status-button"
                            (click)="onCreateFeatureProcedereClicked(child)"
                            [disabled]="isEditorLocked$ | async"></button>
                    <button *ngIf="editData && !(selectedNode.data.featureType === featureType.DIAGNOSIS)"
                            pButton
                            type="button"
                            icon="pi pi-plus"
                            label="{{'drs.edit.node.new.diagnosis' | translate}}"
                            class="ui-button-raised ui-button-secondary diagnosis-button"
                            (click)="onCreateFeatureDiagnosisClicked(child)"
                            [disabled]="isEditorLocked$ | async"></button>
                  </div>
                </div>
              </p-accordionTab>
            </div>
          </p-accordion>
        </div>
      </div>
    </div>

    <p-footer>
      <div class="btn-footer" *ngIf="!isEditFeatureMode">
        <button pButton
                type="button"
                icon="pi pi-times"
                label="{{'btn.close' | translate}}"
                class="ui-button-raised ui-button-secondary btn-default"
                (click)="onCancelClicked($event)"></button>
        <button pButton
                type="button"
                icon="pi pi-check"
                label="{{'btn.save' | translate}}"
                class="ui-button-raised ui-button-primary"
                (click)="onSaveNodeClicked($event)"
                [disabled]="isEditorLocked$ | async"></button>
      </div>
      <div class="btn-footer" *ngIf="isEditFeatureMode">
        <button pButton type="button" icon="pi pi-times" label="{{'btn.cancel' | translate}}" class="ui-button-raised ui-button-secondary btn-default"
                (click)="onEditFeatureCancelClicked($event)" *ngIf="isEditFeatureMode"></button>
        <button pButton type="button" icon="pi pi-check" label="{{'btn.saveFeature' | translate}}" class="ui-button-raised ui-button-danger"
                (click)="onSaveFeatureClicked($event)" *ngIf="isEditFeatureMode"></button>
      </div>
    </p-footer>
  </div>
</p-sidebar>

<p-dialog header="{{'drs.edit.clickableMap.label' | translate}}"
          [baseZIndex]="10001"
          [(visible)]="displayClickableMap"
          [style]='{marginTop: "20px", maxWidth: "800px", minHeight: 0, minWidth: 0}'>
  <div #clickableMap class="clickable-map-image-container">
    <img class="clickable-map-image" *ngIf="imagePath" [src]="imagePath" border="0"
         (error)="toggleClickableMapImage($event, 'none')" (load)="toggleClickableMapImage($event, 'inline')"/>
  </div>
  <p-footer>
    <button pButton type="button" icon="pi pi-times" label="{{'btn.cancel' | translate}}" class="ui-button-secondary"
            (click)="hideClickableMap()"></button>
    <button pButton type="button" icon="pi pi-check" label="{{'btn.save' | translate}}" class="ui-button-primary"
            (click)="saveDataFromClickableMap()"></button>
  </p-footer>
</p-dialog>

<p-dialog header="{{'drs.edit.cut.popup.header.title' | translate}}"
          [baseZIndex]="10001"
          [modal]="true"
          [dismissableMask]="true"
          [(visible)]="showCutNodeTemplatePopup">
  <div class="ui-grid ui-grid-responsive ui-fluid">
    <div class="ui-grid-row">
      <div class="ui-grid-col-4">
        <label for="cut-node-tempalate-name">{{'drs.edit.templateName.label' | translate}}</label>
      </div>
      <div class="ui-grid-col-8"><input pInputText id="cut-node-tempalate-name" [(ngModel)]="cutNodeTemplateName"/>
      </div>
    </div>
  </div>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-helper-clearfix">
      <button type="button" pButton icon="pi pi-check" (click)="cutNodeTemplate()" label="{{'btn.save' | translate}}"></button>
    </div>
  </p-footer>
</p-dialog>

<p-dialog header="{{'drs.edit.copy.popup.header.title' | translate}}"
          [baseZIndex]="10001"
          [modal]="true"
          [dismissableMask]="true"
          [(visible)]="showCopyNodeTemplatePopup">
  <div class="ui-grid ui-grid-responsive ui-fluid">
    <div class="ui-grid-row">
      <div class="ui-grid-col-4">
        <label for="copy-node-tempalate-name">{{'drs.edit.templateName.label' | translate}}</label>
      </div>
      <div class="ui-grid-col-8"><input pInputText id="copy-node-tempalate-name" [(ngModel)]="copyNodeTemplateName"/>
      </div>
    </div>
  </div>
  <p-footer>
    <div class="ui-dialog-buttonpane ui-helper-clearfix">
      <button type="button" pButton icon="pi pi-check" (click)="copyNodeTemplate()" label="{{'btn.save' | translate}}"></button>
    </div>
  </p-footer>
</p-dialog>

<app-revisions-modal #revisionModal></app-revisions-modal>

<app-delete-confirmation-modal #deleteConfirmationModal
                               modalKey="deleteAnswer"></app-delete-confirmation-modal>

<p-dialog header="{{'drs.edit.disableDeletingAnswer.popup.header' | translate}}"
          [baseZIndex]="10001"
          [modal]="true"
          [dismissableMask]="true"
          [(visible)]="showAnswerIsUsedInConditionsPopup">
  <div>{{'drs.edit.disableDeletingAnswer.popup.body' | translate}}</div>
  <p-footer>
    <button type="button"
            pButton
            label="{{'btn.ok' | translate}}"
            (click)="closeAnswerIsUsedInConditionsPopup()"></button>
  </p-footer>
</p-dialog>
