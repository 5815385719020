import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PatientDto } from './drs/shared/model/patients/patient-dto';
import {PatientDocumentDto, PatientDocumentsResponse} from './drs/shared/model/patients/patient-document-dto';
import {
  DocumentDetailDto,
  UpdateDocumentStatusRequest
} from './drs/shared/model/patients/document-detail-dto';
import {PatientConsultationsResponse} from './drs/shared/model/patients/patient-consultation-dto';
import { Consultation } from './drs/shared/model/chat/consultation';

@Injectable()
export class PatientsService {
  private API_PATH = '/api';
  private PATIENT_PATH = 'patient';
  private DOCUMENTS_PATH = 'document';
  private CONSULTATIONS_PATH = 'consultation';

  constructor(private http: HttpClient) {}

  /**
   * Get all patients.
   * @returns {Observable<PatientDto[]>}
   */
  public getPatients(): Observable<PatientDto[]> {
    return this.http.get<PatientDto[]>(`${this.API_PATH}/${this.PATIENT_PATH}/`);
  }

  /**
   * Get patient documents by id.
   *
   * @param patientId the id of the patient
   */
  getPatientDocuments(patientId: number): Observable<PatientDocumentsResponse> {
    return this.http.get<PatientDocumentsResponse>(`${this.API_PATH}/${this.PATIENT_PATH}/${patientId}/${this.DOCUMENTS_PATH}`);
  }

  /**
   * Get consultation by id.
   *
   * @param patientId the patient id.
   * @param consultationId the consultation id.
   */
  getConsultationById(patientId: number, consultationId: number): Observable<Consultation> {
    return this.http.get<Consultation>(`${this.API_PATH}/${this.PATIENT_PATH}/${patientId}/
${this.CONSULTATIONS_PATH}/${consultationId}`);
  }

  /**
   * Get patient consultations by id.
   *
   * @param patientId the id of the patient
   */
  getPatientConsultations(patientId: number): Observable<PatientConsultationsResponse> {
    return this.http.get<PatientConsultationsResponse>(`${this.API_PATH}/${this.PATIENT_PATH}/${patientId}/${this.CONSULTATIONS_PATH}`);
  }

  /**
   * Get patient's document by id.
   * @returns {Observable<PatientDocumentDto>}
   */
  public getDocumentById(patientId: number, documentId: number): Observable<DocumentDetailDto> {
    return this.http.get<DocumentDetailDto>(`${this.API_PATH}/${this.PATIENT_PATH}/${patientId}/${this.DOCUMENTS_PATH}/${documentId}`);
  }

  /**
   * Update patient's document.
   *
   * @returns {Observable<DocumentDetailDto>}
   */
  public updatePatientDocument(patientId: number, document: DocumentDetailDto): Observable<DocumentDetailDto> {
    return this.http.put<DocumentDetailDto>(
      `${this.API_PATH}/${this.PATIENT_PATH}/${patientId}/${this.DOCUMENTS_PATH}/${document.id}`, document);
  }

  /**
   * Updates a patient document status.
   *
   * @param patientId the id of the patient.
   * @param documentId the id of the document to delete.
   * @param status the status of the document to be updated.
   */
  public updatePatientDocumentStatus(
    patientId: number, documentId: number, status: UpdateDocumentStatusRequest): Observable<DocumentDetailDto> {

    return this.http.patch<DocumentDetailDto>(
      `${this.API_PATH}/${this.PATIENT_PATH}/${patientId}/${this.DOCUMENTS_PATH}/${documentId}`, status);
  }
}
