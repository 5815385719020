import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import {
  RegistrationsResponse
} from '../model/registrations/registrations-table-row-data-dto';
import { SingleRegistrationDataDto } from '../model/registrations/single-registration-data-dto';
import {UserIdentificationUpdateRequest} from '../model/registrations/user-identification-update-request';

/**
 * Service for chat.
 */
@Injectable()
export class UserIdentificationService {

  public static USER_IDENTIFICATION_FILE_PATH = '/api/user-identification/image/';
  private USER_IDENTIFICATION_PATH = '/api/user-identification/card';

  constructor(private http: HttpClient) {}

  /**
   * Get all registrations.
   */
  getAllRegistrations(): Observable<RegistrationsResponse> {
    return this.http.get<RegistrationsResponse>(this.USER_IDENTIFICATION_PATH);
  }

  /**
   * Get one registration by id
   */
  getRegistrationById(id: number): Observable<SingleRegistrationDataDto> {
    return this.http.get<SingleRegistrationDataDto>(`${this.USER_IDENTIFICATION_PATH}/${id}`);
  }

  /**
   * Set the patient identification status to DENIED.
   */
  rejectPatientIdCard(id: number): Observable<void> {
    return this.http.delete<void>(`${this.USER_IDENTIFICATION_PATH}/${id}`);
  }

  /**
   * Update registration.
   */
  updateRegistration(id: number, userIdentificationRegistration: UserIdentificationUpdateRequest):
    Observable<SingleRegistrationDataDto> {

    return this.http.put<SingleRegistrationDataDto>(`${this.USER_IDENTIFICATION_PATH}/${id}`,
      userIdentificationRegistration);
  }

}
