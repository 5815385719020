import {MimeTypes} from '../drs/shared/model/file/mime-types';
/**
 * Constructs blob from JSON string.
 *
 * @param object the object used for constructing the blob
 * @param type MIME-type
 */
export function toBlob(object: any, type: MimeTypes): Blob {
  const stringData = JSON.stringify(object);

  return new Blob([ stringData ], { type });
}
