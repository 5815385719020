import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RegistrationStatusEnum } from '../drs/shared/model/registrations/registrations-table-row-data-dto';
import { UserIdentificationService } from '../drs/shared/services/user-identification-service';
import {SingleRegistrationDataDto} from '../drs/shared/model/registrations/single-registration-data-dto';
import {UserIdentificationUpdateRequest} from '../drs/shared/model/registrations/user-identification-update-request';
import {PatientDetailsHeaderStore} from '../drs/shared/services/patient-details-header-store.service';

@Component({
  selector: 'app-registration-details',
  templateUrl: './registration-details.component.html',
  styleUrls: ['./registration-details.component.scss']
})
export class RegistrationDetailsComponent implements OnInit {
  registration: SingleRegistrationDataDto;
  previewModeOn: boolean;
  statusSet = false;
  requestIsDenied = false;
  checkboxStatusObject = {
    acceptRequest: false,
    declineRequest: false
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private patientDetailsHeaderStore: PatientDetailsHeaderStore,
    private userIdentificationService: UserIdentificationService
  ) {}

  ngOnInit() {
    this.route.data.subscribe((data) => {
      this.registration = data.registration;
      this.patientDetailsHeaderStore.showPatientDetails(this.registration.accountId);
      this.previewModeOn = this.registration.status === RegistrationStatusEnum.ACCEPTED;
      this.setCheckboxState();
    });
  }

  setCheckboxState() {
    if (this.registration.status === RegistrationStatusEnum.ACCEPTED) {
      this.checkboxStatusObject.acceptRequest = true;
    } else if (this.registration.status === RegistrationStatusEnum.DENIED) {
      this.checkboxStatusObject.declineRequest = true;
    }
  }

  setRegistrationStatus(event) {
    this.statusSet = event.acceptRequest || event.declineRequest;
    this.requestIsDenied = false;
    if (event.acceptRequest) {
      this.registration.status = RegistrationStatusEnum.ACCEPTED;
    } else if (event.declineRequest) {
      this.requestIsDenied = true;
      this.registration.status = RegistrationStatusEnum.DENIED;
    }
  }

  cancel() {
    this.router.navigateByUrl('/registrations');
  }

  onSubmit() {
    if (this.statusSet && !this.requestIsDenied) {
      const userIdentificationRegistration: UserIdentificationUpdateRequest = {
        firstName: this.registration.firstName,
        lastName: this.registration.lastName,
        idNumber: this.registration.idNumber,
        status: this.registration.status,
        streetName: this.registration.streetName,
        streetNumber: this.registration.streetNumber,
        zipCode: this.registration.zipCode,
        city: this.registration.city,
      };

      this.userIdentificationService.updateRegistration(this.registration.id, userIdentificationRegistration).subscribe(() => {
        this.router.navigateByUrl('/registrations');
      });
    } else if (this.statusSet && this.requestIsDenied) {
      this.userIdentificationService.rejectPatientIdCard(this.registration.id).subscribe(() => {
        this.router.navigateByUrl('/registrations');
      });
    }
  }

  getCardFrontImageId(): string {
    return `${this.registration.id}--${this.registration.idCardFrontImageId}`;
  }

  getCardBackImageId(): string {
    return `${this.registration.id}--${this.registration.idCardBackImageId}`;
  }

  getCardSelfieImageId(): string {
    return `${this.registration.id}--${this.registration.selfieImageId}`;
  }
}
