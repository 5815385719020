import { Component, Input, ViewEncapsulation } from '@angular/core';
import {UserIdentificationService} from '../../drs/shared/services/user-identification-service';

@Component({
  selector: 'app-card-image-registration-detail',
  templateUrl: './card-image-registration-detail.component.html',
  styleUrls: ['./card-image-registration-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CardImageRegistrationDetailComponent {
  @Input() cardTitle: number;
  @Input() cardImgId: string;
  FILE_PATH = UserIdentificationService.USER_IDENTIFICATION_FILE_PATH;
}
