<div class="patient-card-container" *ngIf="patient" [ngClass]="{'patient-selected': isSelected}">
  <div class="picture-container">
    <div class="patient-status status-waiting" *ngIf="patient.isInStateWaiting()"></div>
    <div class="patient-status status-in-conversation" *ngIf="patient.isInStateOngoing() || patient.isInStateWaitingForPatient()"></div>
  </div>
  <div class="name-waiting-time-container">
    <h4 title="{{patient.name}}">{{patient.name}}</h4>

    <button pButton class="btn btn-s btn-bordered green-btn"
            *ngIf="isClaimButtonVisible()"
            [disabled]="!(canClaimChat$ | async)"
            (click)="joinConversation(patient)" >
      <label *ngIf="patient.isInStateWaiting()">{{'chat.claim.button.text' | translate}}</label>
    </button>

    <button pButton class="btn btn-s btn-bordered green-btn"
            *ngIf="isOpenButtonVisible()"
            [disabled]="isSelected && (patient.isInStateDone() || patient.isInStateDeclined())"
            (click)="joinConversation(patient)" >
      <label>{{'chat.open.button.text' | translate}}</label>
    </button>

    <button pButton class="btn btn-s btn-bordered red-btn"
            *ngIf="isFinishButtonVisible()"
            [disabled]="finishButtonDisabled"
            (click)="finishConversation()">
      <label>{{'chat.finish.button.text' | translate}}</label>
    </button>

    <p class="waiting-time" *ngIf="!patient.misantoConversation.chatServerConversationId">{{getWaitingText(patient)}}</p>
    <p *ngIf="patient.unreadMessageCount > 0" class="unread-message-count">{{patient.unreadMessageCount}}
      {{'chat.new.messages' | translate}}</p>
  </div>
</div>
