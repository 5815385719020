<app-patient-header></app-patient-header>
<div class="patient-info-wrapper">
  <div class="patient-documents">
    <h4>{{ 'patient.cockpit.details.heading.consultations' | translate }}</h4>
    <div class="document-panel" *ngFor="let consultation of consultations">
      <div class="row">
        <div class="col align-right">
        </div>
      </div>
      <div class="row">
        <div class="col">
          <span>{{ 'patient.cockpit.consultations.diagnosis.label' | translate }}</span>
          <p>{{ consultation.diagnosis }}</p>
        </div>
        <div class="col">
          <span>{{ 'patient.cockpit.consultations.date.label' | translate }}</span>
          <p>{{ reformatConsultationDate(consultation.date) }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <span>{{ 'patient.cockpit.documents.doctorFirstName.label' | translate }}</span>
          <p>{{ consultation.doctorFirstName }}</p>
        </div>
        <div class="col">
          <span>{{ 'patient.cockpit.documents.doctorLastName.label' | translate }}</span>
          <p>{{ consultation.doctorLastName }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <a routerLink="/patient/{{selectedPatientId}}/consultation/{{consultation.id}}"
             class="basic-link">{{ 'patient.cockpit.link.details.label' | translate }}</a>
        </div>
      </div>
    </div>
  </div>

  <div class="patient-border"></div>

  <div class="patient-documents-wrapper">
    <app-patient-detail-document
      headerKey="patient.cockpit.details.heading.documents"
      [documents]="activeDocuments"
      [patientId]="selectedPatientId"
      [markedDeleted]="false"></app-patient-detail-document>

    <app-patient-detail-document
      headerKey="patient.cockpit.details.heading.archived.documents"
      [documents]="archivedDocuments"
      [patientId]="selectedPatientId"
      [markedDeleted]="true"></app-patient-detail-document>
  </div>
</div>
