import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppCoreService} from '../../../core/services/app-core.service';
import {Observable} from 'rxjs';
import {AuditRecordDto} from '../model/audit-record-dto';

@Injectable()
export class AuditingService {
  private drsBaseRestUrl;
  private revisionsPath = '/revisions';
  private nodeRevisionsPath = '/node';

  constructor(private http: HttpClient) {
    this.drsBaseRestUrl = AppCoreService.REST_BASE_URL + this.revisionsPath;
  }

  /**
   * Get conversation for given chat user.
   *
   */
  getNodeRevisions(nodeId: number): Observable<AuditRecordDto[]> {
    return this.http.get<AuditRecordDto[]>(`${this.drsBaseRestUrl}${this.nodeRevisionsPath}/${nodeId}`);
  }
}
