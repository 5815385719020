import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { Condition, DrsConditionImpl } from '../../condition/condition';
import { DrsAnswerDto, DrsAnswerDtoImpl } from '../model/drs-answer-dto';
import { SelectableConditionImpl } from '../../condition/selectable-condition';
import { DrsNodeDto } from '../model/drs-node-dto';
import { DrsClassificationType } from '../model/drs-classification-type';
import { Subject } from 'rxjs';
import { DrsClassificationDtoImpl } from '../model/drs-classification-dto';
import { SelectItem } from 'primeng/api';

export enum ConditionType {
  NONE = 'NONE',
  ANSWER = 'ANSWER',
  CLASSIFICATION = 'CLASSIFICATION',
}

export const CONDITION_TYPE_ANSWER_ID = -2;

@Injectable()
export class DrsConditionService {
  private possibleAnswerModifiedSource = new Subject<void>();
  public onPossibleAnswerModifiedSelect$ = this.possibleAnswerModifiedSource.asObservable();

  possibleAnswerModified() {
    this.possibleAnswerModifiedSource.next();
  }

  initializeNodeConditions(
    child: DrsNodeDto,
    allClassifications: DrsClassificationType[],
    possibleAnswers: DrsAnswerDto[],
  ) {
    if (!child.conditions) {
      child.conditions = [];
    }
    this.initializeClassificationConditions(child, possibleAnswers, allClassifications);
    this.initializeAnswerConditions(child, possibleAnswers, allClassifications);
  }

  private initializeClassificationConditions(
    child: DrsNodeDto,
    possibleAnswers: DrsAnswerDto[],
    allClassifications: DrsClassificationType[],
  ) {
    const conditionTypes = this.prepareConditionTypes(allClassifications);
    child.data.edge.classifications.forEach(classification => {
      const condition = new SelectableConditionImpl();
      condition.conditionTypes = conditionTypes;
      condition.selectedType = _.find(
        conditionTypes,
        conditionType =>
          conditionType.type === ConditionType.CLASSIFICATION && conditionType.id === classification.typeId,
      );
      condition.conditionValues = this.getConditionValuesForConditionType(
        condition.selectedType,
        possibleAnswers,
        allClassifications,
      );
      condition.selectedValue = _.find(
        condition.conditionValues,
        conditionValue =>
          conditionValue.value.type === ConditionType.CLASSIFICATION && conditionValue.value.id === classification.id,
      ).value;
      child.conditions.push(condition);
    });
  }

  private initializeAnswerConditions(
    child: DrsNodeDto,
    possibleAnswers: DrsAnswerDto[],
    allClassifications: DrsClassificationType[],
  ) {
    const conditionTypes = this.prepareConditionTypes(allClassifications);
    child.data.edge.answers.forEach(answer => {
      const condition = new SelectableConditionImpl();
      condition.conditionTypes = conditionTypes;
      condition.selectedType = _.find(conditionTypes, conditionType => conditionType.type === ConditionType.ANSWER);
      condition.conditionValues = this.getConditionValuesForConditionType(
        condition.selectedType,
        possibleAnswers,
        allClassifications,
      );
      condition.selectedValue = _.find(
        condition.conditionValues,
        conditionValue => conditionValue.value.uuid === answer.uuid,
      ).value;
      child.conditions.push(condition);
    });
  }

  getConditionValuesForConditionType(
    conditionType: DrsConditionImpl,
    answers: DrsAnswerDto[],
    allClassifications: DrsClassificationType[],
  ): SelectItem[] {
    if (conditionType.type === ConditionType.ANSWER) {
      return this.createAnswerValues(answers);
    }
    if (conditionType.type === ConditionType.CLASSIFICATION) {
      return this.addClassificationValues(allClassifications, conditionType);
    }
  }

  private addClassificationValues(classificationTypes: DrsClassificationType[], conditionType: DrsConditionImpl) {
    const selectedClassification = _.find(
      classificationTypes,
      classification => classification.name === conditionType.name,
    );
    return selectedClassification.values.map(classificationValue => {
      const condition = new DrsConditionImpl(
        classificationValue.id,
        classificationValue.name,
        ConditionType.CLASSIFICATION,
      );
      return { label: condition.name, value: condition };
    });
  }

  private createAnswerValues(answers: DrsAnswerDto[]) {
    return answers.map(answer => {
      const answerValue = new DrsConditionImpl(answer.id, answer.description, ConditionType.ANSWER);
      answerValue.uuid = answer.uuid;
      return { label: answerValue.name, value: answerValue };
    });
  }

  prepareConditionTypes(drsClassificationTypes: DrsClassificationType[]): Condition[] {
    const conditionTypes: Condition[] = [];
    conditionTypes.push(new DrsConditionImpl(-1, 'Choose', ConditionType.NONE));
    conditionTypes.push(new DrsConditionImpl(CONDITION_TYPE_ANSWER_ID, 'Answer', ConditionType.ANSWER));
    drsClassificationTypes.forEach(classificationType =>
      conditionTypes.push(
        new DrsConditionImpl(classificationType.id, classificationType.name, ConditionType.CLASSIFICATION),
      ),
    );
    return conditionTypes;
  }

  mapConditionsToNode(node: DrsNodeDto) {
    node.children.forEach(child => {
      child.data.edge.answers = [];
      child.data.edge.classifications = [];

      if (child.conditions === undefined) {
        return;
      }

      child.conditions.filter(condition => condition.selectedType && condition.selectedValue).forEach(condition => {
        if (condition.selectedType.type === ConditionType.ANSWER) {
          this.mapAnswerConditionToNode(condition, child);
        }
        if (condition.selectedType.type === ConditionType.CLASSIFICATION) {
          this.mapClasificationConditionToNode(condition, child);
        }
      });
    });
  }

  private mapClasificationConditionToNode(condition, child) {
    const classificationDto = new DrsClassificationDtoImpl();
    classificationDto.type = condition.selectedType.name;
    classificationDto.typeId = condition.selectedType.id;
    classificationDto.id = condition.selectedValue.id;
    classificationDto.classification = condition.selectedValue.name;
    if (!child.data.edge.classifications) {
      child.data.edge.classifications = [];
    }
    child.data.edge.classifications.push(classificationDto);
  }

  private mapAnswerConditionToNode(condition, child) {
    const answerDto = new DrsAnswerDtoImpl();
    answerDto.id = condition.selectedValue.id;
    answerDto.description = condition.selectedValue.name;
    answerDto.uuid = condition.selectedValue.uuid;
    answerDto.versionUuid = condition.selectedValue.versionUuid;
    if (!child.data.edge.answers) {
      child.data.edge.answers = [];
    }
    child.data.edge.answers.push(answerDto);
  }
}
