/**
 * DrsAnswerDto.
 */
import { DrsVersionDto } from './drs-version-dto';
import { DrsClickableMapRectangleDimensions } from '../services/drawing.service';

export interface DrsAnswerDto {
  id?: number;
  description?: string;
  uuid?: string;
  versionUuid?: string;
  version?: DrsVersionDto;
  clickableAreas: [DrsClickableMapRectangleDimensions];
}

export class DrsAnswerDtoImpl implements DrsAnswerDto {
  id?: number;
  description?: string;
  uuid?: string;
  versionUuid?: string;
  version?: DrsVersionDto;
  clickableAreas: [DrsClickableMapRectangleDimensions];
}
