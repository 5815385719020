<p-dialog header="Revisions {{ title }}"
          [style]="{'width':'98%'}"
          [baseZIndex]="10001"
          [modal]="true"
          [dismissableMask]="true"
          [(visible)]="showRevisionsModal"
          (onHide)="hideRevisionModal()"
          [draggable]="false">
  <p-table *ngIf="revisions.length" [value]="revisions" sortField="createdAt" sortMode="single" [sortOrder]="-1">
    <ng-template pTemplate="header">
      <tr>
        <th class="revision-table-date-column">{{'drs.revisions.table.header.date' | translate}}</th>
        <th class="revision-table-user-column">{{'drs.revisions.table.header.user' | translate}}</th>
        <th class="revision-table-change-column">{{'drs.revisions.table.header.change' | translate}}</th>
        <th class="revision-table-id-column">{{'drs.revisions.table.header.id' | translate}}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-revision>
      <tr>
        <td>{{revision.revisionDate}}</td>
        <td>{{revision.username}}</td>
        <td>
          <div *ngFor="let uiLabel of revision.uiLabels">
            <span>{{ translateLabelDto(uiLabel)}}</span>
          </div>
        </td>
        <td>{{revision.id}}</td>
      </tr>
    </ng-template>
  </p-table>
</p-dialog>
