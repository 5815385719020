export interface ChatMessage {
  createdAt: string;
  createdBy: string;
  message: string;
  type: ChatMessageType;
  attachment: ChatMessageAttachment;
}

export interface ChatMessageAttachment {
  url: string;
  name: string;
  contentType: string;
}

export enum ChatMessageType {
 TEXT = 'TEXT',
 ATTACHMENT = 'ATTACHMENT',
}
