/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./image-uploader.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../bypass-security.pipe";
import * as i4 from "@angular/platform-browser";
import * as i5 from "primeng/components/inputtext/inputtext";
import * as i6 from "@angular/forms";
import * as i7 from "primeng/components/button/button";
import * as i8 from "@angular/common";
import * as i9 from "./image-uploader.component";
var styles_ImageUploaderComponent = [i0.styles];
var RenderType_ImageUploaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImageUploaderComponent, data: {} });
export { RenderType_ImageUploaderComponent as RenderType_ImageUploaderComponent };
function View_ImageUploaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "img", [], [[8, "hidden", 0], [8, "src", 4], [8, "alt", 0]], null, null, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.imagePath; var currVal_1 = _co.imagePath; var currVal_2 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 0, 2, i1.ɵnov(_v, 1).transform("drs.img.preview.alt")), ""); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_ImageUploaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "object", [["class", "pdf-viewer"], ["type", "image/png"]], [[8, "hidden", 0], [8, "data", 5]], null, null, null, null)), i1.ɵppd(1, 1), (_l()(), i1.ɵeld(2, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 2, "a", [], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.imagePath; var currVal_1 = i1.ɵunv(_v, 0, 1, _ck(_v, 1, 0, i1.ɵnov(_v.parent, 0), _co.imagePath)); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("apip.header.pdf.viewer.error.message")); _ck(_v, 3, 0, currVal_2); var currVal_3 = i1.ɵinlineInterpolate(1, "", _co.imagePath, ""); _ck(_v, 5, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("apip.header.pdf.viewer.error.link.label")); _ck(_v, 6, 0, currVal_4); }); }
export function View_ImageUploaderComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.BypassSecurityPipe, [i4.DomSanitizer]), i1.ɵqud(402653184, 1, { fileInput: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 14, "span", [["class", "ui-float-label input-box ui-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "label", [["for", "condition-image-input"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "wrapper-img-button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, [[1, 0], ["fileInput", 1]], null, 1, "input", [["id", "condition-image-input"], ["pInputText", ""], ["type", "file"]], [[8, "disabled", 0], [8, "accept", 0], [2, "ui-inputtext", null], [2, "ui-corner-all", null], [2, "ui-state-default", null], [2, "ui-widget", null], [2, "ui-state-filled", null]], [[null, "change"], [null, "input"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onInput($event) !== false);
        ad = (pd_0 && ad);
    } if (("change" === en)) {
        var pd_1 = (_co.onFileUploaded($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 278528, null, 0, i5.InputText, [i1.ElementRef, [2, i6.NgModel]], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "button", [["class", "ui-button-raised ui-button-primary red-btn"], ["icon", "pi pi-trash"], ["pButton", ""], ["type", "button"]], [[8, "disabled", 0], [8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDeleteImage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 4341760, null, 0, i7.ButtonDirective, [i1.ElementRef], { icon: [0, "icon"] }, null), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(12, 0, null, null, 4, "span", [["class", "condition-image-preview"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageUploaderComponent_1)), i1.ɵdid(14, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageUploaderComponent_2)), i1.ɵdid(16, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 8, 0); var currVal_10 = "pi pi-trash"; _ck(_v, 10, 0, currVal_10); var currVal_11 = _co.isImage; _ck(_v, 14, 0, currVal_11); var currVal_12 = !_co.isImage; _ck(_v, 16, 0, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("drs.feature.add.image.label")); _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.disabled; var currVal_2 = i1.ɵinlineInterpolate(1, "", _co.allowedFileType, ""); var currVal_3 = true; var currVal_4 = true; var currVal_5 = true; var currVal_6 = true; var currVal_7 = i1.ɵnov(_v, 8).filled; _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = _co.disabled; var currVal_9 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 9, 1, i1.ɵnov(_v, 11).transform("btn.deleteImage")), ""); _ck(_v, 9, 0, currVal_8, currVal_9); }); }
export function View_ImageUploaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-image-uploader", [], null, null, null, View_ImageUploaderComponent_0, RenderType_ImageUploaderComponent)), i1.ɵdid(1, 49152, null, 0, i9.ImageUploaderComponent, [], null, null)], null, null); }
var ImageUploaderComponentNgFactory = i1.ɵccf("app-image-uploader", i9.ImageUploaderComponent, View_ImageUploaderComponent_Host_0, { imagePath: "imagePath", disabled: "disabled" }, { imageChange: "imageChange", featureImgId: "featureImgId", imageUrlChange: "imageUrlChange" }, []);
export { ImageUploaderComponentNgFactory as ImageUploaderComponentNgFactory };
