<a href="#" class="close-btn" (click)="hideConsultationPanel()"><i class="pi pi-times"></i></a>
<app-chat-user-details class="chat-user-details-consultations" [patientDetails]="consultation?.patientDetails"></app-chat-user-details>

<div class="consultation-title-container">
  <div id="free-chat-chkbox-wrapper">
    <p-checkbox
      id="free-chat-chbox-element"
      styleClass="free-chat-chkbox"
      binary="true"
      label="{{ 'chat.freeChat' | translate }}"
      [(ngModel)]="freeChat"
      [disabled]="!freeChatCheckboxEnabled"
      (onChange)="onFreeChatCheckChanged($event)">
    </p-checkbox>
  </div>
  <h2 class="consultation-history-title">{{'chat.consultation.title' | translate}}</h2>
</div>

<div class="consultation-panel-container">
  <ul class="consultation-list" *ngIf="consultation">
    <ng-container *ngFor="let question of consultation.questions">
      <li class="question-list-element">
        {{question?.question}}
      </li>
      <li class="answer-list-element">
        {{question?.answer?.answer}}
      </li>
    </ng-container>
  </ul>
</div>
