/**
 * Defines the possible severities of a global message.
 */
export enum MessageSeverity {
  SUCCESS = 'success',
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error'
}

/**
 * Represents a user-friendly application message.
 */
export interface ApplicationMessage {
  severity: MessageSeverity;
  summary: string;
  detail?: string;
  closable?: boolean;
  key?: string;
}
