import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AppDataService} from './app-data/app-data.service';
import {AppCoreService} from './services/app-core.service';
import {AppDataDto} from './app-data/app-data';
import * as moment from 'moment';

/**
 * Service to bootstrap the application.
 */
@Injectable()
export class BootstrapService {

  constructor(private appDataService: AppDataService,
              private appCoreService: AppCoreService,
              private translateService: TranslateService) {
  }

  /**
   * Bootstraps the application with needed server data.
   */
  public async bootstrapApp() {
    const browserLanguage = this.translateService.getBrowserLang();
    const appData = await this.loadAppData(browserLanguage);

    this.appDataService.appData = appData;

    this.initializeTranslateService(appData, browserLanguage);
    this.initializeLibraries(browserLanguage);
  }

  private async loadAppData(browserLanguage: string): Promise<AppDataDto> {
    return this.appCoreService.getAppData(browserLanguage).toPromise();
  }

  private initializeTranslateService(appData: AppDataDto, browserLanguage: string) {
    this.translateService.use(browserLanguage);
    this.translateService.setTranslation(browserLanguage, this.prepareTranslations(appData.texts));
  }

  private initializeLibraries(browserLanguage: string) {
    moment.locale(browserLanguage);
  }

  /**
   * Prepares translations to be used on the UI with the translate module, eg. replaces {0} with {{0}}.
   * This method can be removed as soon as NCA Trema uses {{0}} instead of {0}
   *
   * @param { [key: string]: string } translations the translations
   * @return { [key: string]: string } prepared translations
   */
  private prepareTranslations(translations: { [key: string]: string }) {
    Object.keys(translations).forEach((key) => {
      const value = translations[key];
      translations[key] = value.replace(/({)(\d)(})/g, '$1{$2}$3');
    });
    return translations;
  }
}
