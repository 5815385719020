<p-messages [value]="localMessages$ | async"></p-messages>

<div class="mis-table-container">
  <p-table [value]="drsNodeTemplates" [resizableColumns]="true">
    <ng-template pTemplate="caption">
      {{'drs.graph.root.node.templates.table.header' | translate}}
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th style="width:15%" [pSortableColumn]="'id'" pResizableColumn>
          Id
          <p-sortIcon [field]="'id'"></p-sortIcon>
        </th>
        <th style="width:55%" [pSortableColumn]="'label'">
          Name
          <p-sortIcon [field]="'label'"></p-sortIcon>
        </th>
        <th style="width:10%"></th>
        <th style="width:10%"></th>
        <th style="width:10%"></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-graph>
      <tr>
        <td>
          {{graph.id}}
        </td>
        <td>
          {{graph.label}}
        </td>
        <td>
          <button type="button"
                  pButton
                  icon="pi pi-folder-open"
                  class="ui-button-raised ui-button-secondary"
                  routerLink="/{{graph.fullIdentifier}}"></button>
        </td>
        <td>
          <button type="button"
                  pButton
                  icon="pi pi-pencil"
                  class="ui-button-raised ui-button-secondary"
                  (click)="onEditNodeTemplateName(graph)"
                  [disabled]="isEditorLocked$ | async"></button>
        </td>
        <td>
          <button type="button"
                  pButton
                  icon="pi pi-trash"
                  class="ui-button-raised ui-button-secondary"
                  (click)="onDeleteNodeTemplate(graph)"
                  [disabled]="(isEditorLocked$ | async)"></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<p-confirmDialog header="{{'drs.graph.root.node.delete.confirm.header' | translate}}"
                 icon="pi pi-question-circle"
                 #cd>
  <p-footer>
    <button type="button" pButton icon="pi pi-times" label="{{'btn.no' | translate}}" (click)="cd.reject()"></button>
    <button type="button" pButton icon="pi pi-check" label="{{'btn.yes' | translate}}" (click)="cd.accept()"></button>
  </p-footer>
</p-confirmDialog>

<app-modal-dialog #nodeTemplateDetailsModal
                  headerName="{{'drs.graph.root.node.template.edit.modal.header' | translate}}"
                  (saveChange)="onSaveButtonClick($event)"></app-modal-dialog>
