import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { LoaderService } from './loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  constructor(private loaderService: LoaderService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Exclude -chat requests- and -requests for checking the drs version- from loading interceptor
    const chatPath = /\/chat/gm;
    const versionPath = /\/api\/drs\/\d+$/gm;
    const acceptedUrl = (req.url.search(chatPath) === -1 && req.url.search(versionPath) === -1) ||
      req.url.search('conversation/finish') >= 0;

    // Show loading spinner when http request is done
    if (acceptedUrl) {
      this.loaderService.show();
    }

    return next.handle(req).pipe(
      // Hide loading spinner when proper response is received
      finalize(() => {
        if (acceptedUrl) {
          // Hide loading spinner only if routing is not ongoing
          if (!this.loaderService.isRoutingOngoing()) {
            // Do not hide/stop loading spinner if there are nested calls
            if (!this.loaderService.isStillLoadingWhenCallIsDone()) {
              this.loaderService.setUrl('');
              this.loaderService.hide();

            } else if (this.loaderService.getUrl() === req.url) {
              this.loaderService.keepLoadingWhenCallIsDone(false);
              this.loaderService.setUrl('');
              this.loaderService.hide();
            }
          }
        }
      })
    );
  }
}
