<app-patient-header></app-patient-header>
<h2 class="document-detail-title">{{'document.detail.title' | translate}}</h2>

<div class="ui-grid">

  <div class="ui-grid-col-6">
    <div class="document-view-container" *ngIf="documentFiles.length > 0">
      <p-carousel [circular]="true"
                  headerText="{{'apip.single.document.carousel.title' | translate}}"
                  [value]="documentFiles"
                  [numVisible]="1">
        <ng-template let-fileId pTemplate="document">
          <div class="ui-grid ui-grid-responsive">
            <div class="ui-grid-row">
              <div class="ui-grid-col-12">
                <object class="pdf-viewer" [data]="imageUrl + fileId | bypassSecurity" type="image/png">
                  <p>{{'apip.header.pdf.viewer.error.message' | translate}}</p>
                  <a href="{{imageUrl + fileId}}">{{'apip.header.pdf.viewer.error.link.label' | translate}}</a>
                </object>
              </div>
            </div>
          </div>
        </ng-template>
      </p-carousel>
    </div>
  </div>
  <div class="ui-grid-col-6">
    <div class="documents-data-container">
      <div class="edit-mode-switch-container">
        <p-toggleButton *ngIf="!isMarkedAsDeleted()"
                        onLabel="{{'document.detail.edit.on.label' | translate}}"
                        offLabel="{{'document.detail.edit.off.label' | translate}}"
                        [(ngModel)]="editMode"></p-toggleButton>
        <p-button
          *ngIf="!isMarkedAsDeleted()"
          styleClass="ui-button-danger"
          class="delete-button"
          label="{{'document.detail.delete.label' | translate}}"
          type="submit"
          (onClick)="onDeletePatientDocument()"></p-button>
        <p-button
          *ngIf="isMarkedAsDeleted()"
          styleClass="ui-button-warning"
          label="{{'document.detail.undo.delete.label' | translate}}"
          type="submit"
          (onClick)="onUndoDeleteDocument()"></p-button>
      </div>
      <div class="read-only-metadata">
        <div class="row">
          <div class="col">
            <span>{{'document.detail.type.label' | translate}}</span>
            <p *ngIf="!editMode">{{'api.document.category.' + document.category | translate}}</p>
            <p-dropdown class="input-element" *ngIf="editMode" [options]="categories"
                        [(ngModel)]="document.category"></p-dropdown>
          </div>
          <div class="col">
            <span>{{'document.detail.doctorFirstName.label' | translate}}</span>
            <p *ngIf="!editMode">{{document.doctorFirstName}}</p>
            <input class="input-element" pInputText type="text"
                   *ngIf="editMode" [(ngModel)]="document.doctorFirstName" value="{{document.doctorFirstName}}">
          </div>
          <div class="col">
            <span>{{'document.detail.doctorLastName.label' | translate}}</span>
            <p *ngIf="!editMode">{{document.doctorLastName}}</p>
            <input class="input-element" pInputText type="text"
                   *ngIf="editMode" [(ngModel)]="document.doctorLastName" value="{{document.doctorLastName}}">
          </div>
        </div>
        <div class="row">
          <div class="col">
            <span>{{'document.detail.status.label' | translate}}</span>
            <p *ngIf="!editMode">{{'api.document.status.' + document.status | translate}}</p>
            <p-dropdown class="input-element" *ngIf="editMode" [options]="statuses" [(ngModel)]="document.status"></p-dropdown>
          </div>
          <div class="col">
            <span>{{'document.detail.date.label' | translate}}</span>
            <p *ngIf="!editMode">{{document.date}}</p>
            <p-calendar [style]="{height: '34px'}" *ngIf="editMode" class="input-element"
                        [(ngModel)]="calendarDate" dateFormat="yy.mm.dd" required="true"></p-calendar>
          </div>
          <div class="col">
            <span>{{'document.detail.description.label' | translate}}</span>
            <p *ngIf="!editMode">{{document.description}}</p>
            <input class="input-element" pInputText [(ngModel)]="document.description"
                   *ngIf="editMode" type="text" value="{{document.description}}">
          </div>
        </div>
        <div class="row">
          <div class="col">
            <span>{{'document.detail.comment.label' | translate}}</span>
            <p *ngIf="!editMode">{{document.comment}}</p>
            <textarea pInputTextarea
                      rows="5"
                      class="input-element textarea-element"
                      pInputText [(ngModel)]="document.comment"
                      *ngIf="editMode"
                      type="text"
                      [value]="document.comment"></textarea>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <span>{{'document.detail.uploader.label' | translate}}</span>
            <p>{{ getModeratorKeyTranslated(document.uploadedBy) }}</p>
          </div>
          <div *ngIf="isMarkedAsDeleted()" class="col">
            <span>{{'document.detail.deleted.by.label' | translate}}</span>
            <p>{{ getModeratorKeyTranslated(document.deletedBy) }}</p>
          </div>
        </div>
      </div>
      <div class="save-button" *ngIf="!isMarkedAsDeleted()">
        <button pButton
                type="button"
                icon="pi pi-times"
                label="{{'btn.cancel' | translate}}"
                class="ui-button-secondary btn-default"
                (click)="goBack()"></button>
        <button pButton
                type="button"
                icon="pi pi-check"
                label="{{'btn.save' | translate}}"
                class="ui-button-primary"
                (click)="saveDocument()"></button>
      </div>
    </div>


  </div>

</div>




<app-delete-confirmation-modal #deletePatientDocumentModal
                               modalKey="deleteDocument"></app-delete-confirmation-modal>
<app-delete-confirmation-modal #undoDeletePatientDocumentModal
                               messageKey="modal.confirmation.undo.delete.message"></app-delete-confirmation-modal>
