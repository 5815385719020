import {Component, OnInit } from '@angular/core';
import { Consultation } from '../drs/shared/model/chat/consultation';
import { ActivatedRoute } from '@angular/router';
import { Conversation, ConversationMessageType } from '../drs/shared/model/chat/conversation';
import { ChatService } from '../drs/shared/services/chat.service';
import { PatientDetailsHeaderStore } from '../drs/shared/services/patient-details-header-store.service';
import { PatientsService } from '../patients.service';
import { flatMap } from 'rxjs/operators';

@Component({
  selector: 'app-consultation',
  templateUrl: './consultation.component.html',
  styleUrls: ['./consultation.component.scss']
})
export class ConsultationComponent implements  OnInit {

  consultation: Consultation;
  conversation: Conversation;

  constructor(
    private route: ActivatedRoute,
    private patientService: PatientsService,
    private patientDetailsHeaderStore: PatientDetailsHeaderStore,
    private misantoChatService: ChatService) {}

  ngOnInit(): void {
    const id = +this.route.snapshot.params['patientId'];
    this.patientDetailsHeaderStore.showPatientDetails(id);

    this.loadConsultation();
    this.loadConversation();
  }

  private loadConsultation() {
    this.route.params.pipe(
      flatMap(params => {
        const patientId: number = +params['patientId'];
        const consultationId: number = +params['consultationId'];
        return this.patientService.getConsultationById(patientId, consultationId);
      }))
      .subscribe((data: Consultation) => {
        this.consultation = data;
      });
  }

  private loadConversation() {
    this.route.params.pipe(
      flatMap(params => {
        const consultationId: number = +params['consultationId'];
        return this.misantoChatService.getConversationByConsultation(consultationId);
      }))
      .subscribe((data: Conversation) => {
        this.conversation = data;

        if (this.conversation) {
          this.createImageUrl(this.conversation);
        }
      });
  }

  private createImageUrl(conversation: Conversation): void {

    this.conversation.messages.forEach(message => {
      if (message.type === ConversationMessageType.IMAGE) {
        message.value.imageUrl = this.misantoChatService.buildImageUrl(this.conversation.id, Number(message.value.imageId));
      }
    });
  }
}
