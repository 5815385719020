import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

@Injectable()
export class LoaderService {

  isLoading = new Subject<boolean>();
  private isRouting = false;
  private keepLoading = false;
  private url: string;

  show() {
    this.isLoading.next(true);
  }

  hide() {
    this.isLoading.next(false);
  }

  startRouting() {
    this.isRouting = true;
    this.isLoading.next(true);
  }

  stopRouting() {
    this.isRouting = false;
    this.isLoading.next(false);
  }

  isRoutingOngoing() {
    return this.isRouting;
  }

  setUrl(val) {
    this.url = val;
  }

  getUrl() {
    return this.url;
  }

  keepLoadingWhenCallIsDone(val) {
    this.keepLoading = val;
  }

  isStillLoadingWhenCallIsDone() {
    return this.keepLoading;
  }
}
