/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../node_modules/primeng/components/menu/menu.ngfactory";
import * as i4 from "primeng/components/menu/menu";
import * as i5 from "@angular/forms";
import * as i6 from "./app.topbar.component";
import * as i7 from "./app-container.component";
import * as i8 from "../drs/shared/services/chat.service";
import * as i9 from "../misanto-security.service";
import * as i10 from "../core/user.service";
import * as i11 from "../drs/resolvers/drs-graph-resolver.service";
import * as i12 from "@angular/router";
var styles_AppTopBarComponent = [];
var RenderType_AppTopBarComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppTopBarComponent, data: {} });
export { RenderType_AppTopBarComponent as RenderType_AppTopBarComponent };
function View_AppTopBarComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "content-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "p", [["class", "topbar-graph-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.graph.label; _ck(_v, 2, 0, currVal_0); }); }
function View_AppTopBarComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [["class", "topbar-back-button pi pi-arrow-left"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.backClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
export function View_AppTopBarComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 41, "div", [["class", "layout-topbar mis-topbar"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "content-left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "a", [["class", "menu-btn mis-topbar-button"], ["href", "#"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.container.onMenuButtonClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "i", [["class", "pi pi-bars"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "img", [["class", "topbar-logo"], ["src", "assets/images/misanto-logo.png"]], [[8, "alt", 0]], null, null, null, null)), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppTopBarComponent_1)), i0.ɵdid(7, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 33, "div", [["class", "content-right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 2, "div", [["class", "tool-bar"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppTopBarComponent_2)), i0.ɵdid(11, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(12, 0, null, null, 29, "div", [["class", "user-profile"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showUserInfo() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 19, "div", [["class", "name-info"]], null, null, null, null, null)), i0.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(15, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(16, { "active": 0 }), (_l()(), i0.ɵeld(17, 0, null, null, 2, "p", [["class", "user-name"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 21).toggle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(18, null, [" ", " "])), (_l()(), i0.ɵeld(19, 0, null, null, 0, "i", [["class", "pi pi-chevron-down"]], null, null, null, null, null)), (_l()(), i0.ɵeld(20, 0, null, null, 1, "p-menu", [], null, null, null, i3.View_Menu_0, i3.RenderType_Menu)), i0.ɵdid(21, 180224, [["menu", 4]], 0, i4.Menu, [i0.ElementRef, i0.Renderer2], { model: [0, "model"], popup: [1, "popup"] }, null), (_l()(), i0.ɵeld(22, 0, null, null, 10, "div", [["class", "user-status-label"]], null, null, null, null, null)), (_l()(), i0.ɵeld(23, 0, null, null, 7, "label", [["class", "switch"]], null, null, null, null, null)), (_l()(), i0.ɵeld(24, 0, null, null, 5, "input", [["type", "checkbox"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "change"], [null, "ngModelChange"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i0.ɵnov(_v, 25).onChange($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 25).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("change" === en)) {
        var pd_2 = (_co.changeUserStatus() !== false);
        ad = (pd_2 && ad);
    } if (("ngModelChange" === en)) {
        var pd_3 = ((_co.userActive = $event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i0.ɵdid(25, 16384, null, 0, i5.CheckboxControlValueAccessor, [i0.Renderer2, i0.ElementRef], null, null), i0.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.CheckboxControlValueAccessor]), i0.ɵdid(27, 671744, null, 0, i5.NgModel, [[8, null], [8, null], [8, null], [6, i5.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i5.NgControl, null, [i5.NgModel]), i0.ɵdid(29, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null), (_l()(), i0.ɵeld(30, 0, null, null, 0, "span", [["class", "slider round"]], null, null, null, null, null)), (_l()(), i0.ɵeld(31, 0, null, null, 1, "p", [["class", "status-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(32, null, ["", ""])), (_l()(), i0.ɵeld(33, 0, null, null, 4, "div", [["class", "profile-picture"]], null, null, null, null, null)), (_l()(), i0.ɵeld(34, 0, null, null, 0, "img", [["src", "assets/images/default-chat-user.jpg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(35, 0, null, null, 2, "span", [["class", "status"]], null, null, null, null, null)), i0.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(37, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵeld(38, 0, null, null, 3, "div", [["class", "user-info-overlay"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hideUserInfo($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(40, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(41, { "active": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.isGraphPage(); _ck(_v, 7, 0, currVal_1); var currVal_2 = !_co.isRootPage(); _ck(_v, 11, 0, currVal_2); var currVal_3 = "name-info"; var currVal_4 = _ck(_v, 16, 0, _co.userInfoIsVisible); _ck(_v, 15, 0, currVal_3, currVal_4); var currVal_6 = _co.items; var currVal_7 = true; _ck(_v, 21, 0, currVal_6, currVal_7); var currVal_15 = _co.userActive; _ck(_v, 27, 0, currVal_15); var currVal_17 = "status"; var currVal_18 = (_co.userActive ? "" : "inactive"); _ck(_v, 37, 0, currVal_17, currVal_18); var currVal_19 = "user-info-overlay"; var currVal_20 = _ck(_v, 41, 0, _co.userInfoIsVisible); _ck(_v, 40, 0, currVal_19, currVal_20); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform("drs.logo.alt")), ""); _ck(_v, 4, 0, currVal_0); var currVal_5 = _co.username; _ck(_v, 18, 0, currVal_5); var currVal_8 = i0.ɵnov(_v, 29).ngClassUntouched; var currVal_9 = i0.ɵnov(_v, 29).ngClassTouched; var currVal_10 = i0.ɵnov(_v, 29).ngClassPristine; var currVal_11 = i0.ɵnov(_v, 29).ngClassDirty; var currVal_12 = i0.ɵnov(_v, 29).ngClassValid; var currVal_13 = i0.ɵnov(_v, 29).ngClassInvalid; var currVal_14 = i0.ɵnov(_v, 29).ngClassPending; _ck(_v, 24, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14); var currVal_16 = (_co.userActive ? _co.activeStatus : _co.inactiveStatus); _ck(_v, 32, 0, currVal_16); }); }
export function View_AppTopBarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-topbar", [], null, null, null, View_AppTopBarComponent_0, RenderType_AppTopBarComponent)), i0.ɵdid(1, 114688, null, 0, i6.AppTopBarComponent, [i7.AppContainerComponent, i2.Location, i8.ChatService, i9.MisantoSecurityService, i1.TranslateService, i10.UserService, i11.DrsGraphResolverService, i12.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppTopBarComponentNgFactory = i0.ɵccf("app-topbar", i6.AppTopBarComponent, View_AppTopBarComponent_Host_0, {}, {}, []);
export { AppTopBarComponentNgFactory as AppTopBarComponentNgFactory };
