/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/primeng/components/confirmdialog/confirmdialog.ngfactory";
import * as i2 from "primeng/components/confirmdialog/confirmdialog";
import * as i3 from "primeng/components/common/confirmationservice";
import * as i4 from "../../../../node_modules/primeng/components/common/shared.ngfactory";
import * as i5 from "primeng/components/common/shared";
import * as i6 from "primeng/components/button/button";
import * as i7 from "@ngx-translate/core";
import * as i8 from "./delete-confirmation-modal.component";
var styles_DeleteConfirmationModalComponent = [];
var RenderType_DeleteConfirmationModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DeleteConfirmationModalComponent, data: {} });
export { RenderType_DeleteConfirmationModalComponent as RenderType_DeleteConfirmationModalComponent };
export function View_DeleteConfirmationModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "p-confirmDialog", [["icon", "pi pi-question-circle"]], null, null, null, i1.View_ConfirmDialog_0, i1.RenderType_ConfirmDialog)), i0.ɵdid(1, 180224, [["cd", 4]], 1, i2.ConfirmDialog, [i0.ElementRef, i0.Renderer2, i3.ConfirmationService, i0.NgZone], { icon: [0, "icon"], key: [1, "key"] }, null), i0.ɵqud(603979776, 1, { footer: 0 }), (_l()(), i0.ɵeld(3, 0, null, 0, 7, "p-footer", [], null, null, null, i4.View_Footer_0, i4.RenderType_Footer)), i0.ɵdid(4, 49152, [[1, 4]], 0, i5.Footer, [], null, null), (_l()(), i0.ɵeld(5, 0, null, 0, 2, "button", [["icon", "pi pi-times"], ["pButton", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).reject() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(6, 4341760, null, 0, i6.ButtonDirective, [i0.ElementRef], { label: [0, "label"], icon: [1, "icon"] }, null), i0.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(8, 0, null, 0, 2, "button", [["icon", "pi pi-check"], ["pButton", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).accept() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(9, 4341760, null, 0, i6.ButtonDirective, [i0.ElementRef], { label: [0, "label"], icon: [1, "icon"] }, null), i0.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "pi pi-question-circle"; var currVal_1 = _co.modalKey; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 6, 0, i0.ɵnov(_v, 7).transform("btn.no")), ""); var currVal_3 = "pi pi-times"; _ck(_v, 6, 0, currVal_2, currVal_3); var currVal_4 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 9, 0, i0.ɵnov(_v, 10).transform("btn.yes")), ""); var currVal_5 = "pi pi-check"; _ck(_v, 9, 0, currVal_4, currVal_5); }, null); }
export function View_DeleteConfirmationModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-delete-confirmation-modal", [], null, null, null, View_DeleteConfirmationModalComponent_0, RenderType_DeleteConfirmationModalComponent)), i0.ɵdid(1, 114688, null, 0, i8.DeleteConfirmationModalComponent, [i3.ConfirmationService, i7.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DeleteConfirmationModalComponentNgFactory = i0.ɵccf("app-delete-confirmation-modal", i8.DeleteConfirmationModalComponent, View_DeleteConfirmationModalComponent_Host_0, { modalKey: "modalKey", header: "header", headerKey: "headerKey", message: "message", messageKey: "messageKey" }, {}, []);
export { DeleteConfirmationModalComponentNgFactory as DeleteConfirmationModalComponentNgFactory };
