import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { Observable ,  Subscription } from 'rxjs';

import { DrsService } from '../shared/services/drs.service';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { CreateGraph, DrsGraphOverviewDto, GraphTypeEnum } from '../shared/model/drs-graph-overview';
import { DrsUserRoleDto, DrsUserRoleName } from '../shared/model/drs-user-role-dto';
import { MisantoSecurityService } from '../../misanto-security.service';
import { DrsEditorModeService } from '../shared/services/drs-editor-mode.service';
import { ApplicationMessage } from '../../core/messages/application-message';
import { AppDataService } from '../../core/app-data/app-data.service';

@Component({
  selector: 'app-graph-manager',
  templateUrl: './graph-manager.component.html',
  styleUrls: ['./graph-manager.component.scss'],
  providers: [
    DrsEditorModeService
  ]
})
export class GraphManagerComponent implements OnInit, OnDestroy {
  public drsGraphs: DrsGraphOverviewDto[];
  public displayDialog: boolean;
  public graph: DrsGraphOverviewDto;
  public selectedGraph: DrsGraphOverviewDto;
  public newGraph: boolean;
  public currentVersion: number;
  public userRoles: DrsUserRoleDto[];
  public isUserSuperAdmin = false;

  isEditorLocked$: Observable<boolean>;
  localMessages$: Observable<ApplicationMessage[]>;

  private subscriptions = new Subscription();

  @ViewChild('treeDetailsModal', { static: true }) treeDetailsModal;

  constructor(
    private route: ActivatedRoute,
    private drsService: DrsService,
    private confirmationService: ConfirmationService,
    private translateService: TranslateService,
    private misantoSecurityService: MisantoSecurityService,
    private drsEditorModeService: DrsEditorModeService,
    private appDataService: AppDataService
  ) {}

  ngOnInit() {
    this.isEditorLocked$ = this.drsEditorModeService.isEditorLocked$;
    this.localMessages$ = this.drsEditorModeService.messages$;

    this.route.data.subscribe((data: GraphManagerComponentResolveData) => {
      this.drsGraphs = _.sortBy(data.drsGraphs, graph => graph.label);
    });

    this.currentVersion = this.appDataService.getDefaultDrsVersion();
    this.subscriptions.add(this.appDataService.versionChanged$.subscribe((version: number) => {
      this.currentVersion = version;
    }));

    this.misantoSecurityService.userDetails.subscribe(data => {
      this.userRoles = data.roles;
      this.isUserSuperAdmin = this.userRoles.filter(role => role.name === DrsUserRoleName.AUTH_SUPER_ADMIN).length >= 1;
    });
    this.drsEditorModeService.subscribeForEditorModeChanges();
    this.handleDataReload();
  }

  ngOnDestroy(): void {
    this.drsEditorModeService.unsubscribeFromEditorModeChanges();
    this.subscriptions.unsubscribe();
  }

  private handleDataReload() {
    const subscription = this.drsEditorModeService.reloadData$.subscribe(() => {
      this.drsService.getDrsGraphs('DRS_GRAPH').subscribe((graphs: DrsGraphOverviewDto[]) => this.drsGraphs = graphs);
    });
    this.subscriptions.add(subscription);
  }

  confirmDeletion(graph: DrsGraphOverviewDto) {
    this.confirmationService.confirm({
      message: this.translateService.instant('drs.graph.root.node.delete.confirm'),
      accept: () => {
        this.onRemoveClicked(graph);
      },
    });
  }

  showDialogToAdd() {
    this.graph = {
      label: '',
      type: GraphTypeEnum.DRS_GRAPH
    };
    this.newGraph = true;
    this.treeDetailsModal.setTreeDetailsData(this.graph);
    this.treeDetailsModal.setTreeDetailsModal();
  }

  onSaveButtonClick(event) {
    this.graph = _.cloneDeep(event);

    if (this.newGraph) {
      this.saveGraph();
    } else {
      this.updateGraph();
    }

    this.graph = null;
  }

  private saveGraph() {
    const newGraph: CreateGraph = {
      label: this.graph.label,
      type: this.graph.type,
      drsVersion: this.currentVersion
    };
    this.drsService.createDrsGraph(newGraph).subscribe(
      (graphOverview: DrsGraphOverviewDto) => {
        this.drsGraphs.push(graphOverview);
      },
      () => {
        throw new Error(this.translateService.instant('drs.graph.root.node.create.error'));
      },
    );
  }

  private updateGraph() {
    this.drsService.updateDrsGraph(this.graph).subscribe((graphOverview: DrsGraphOverviewDto) => {
        this.drsGraphs[this.findSelectedGraphIndex()] = graphOverview;
      },
      () => {
        throw new Error(this.translateService.instant('drs.graph.root.node.edit.error'));
      },
    );
  }

  onEditButtonClicked(graph: DrsGraphOverviewDto) {
    this.newGraph = false;
    this.selectedGraph = graph;
    this.graph = _.cloneDeep(graph);

    this.treeDetailsModal.setTreeDetailsData(this.graph);
    this.treeDetailsModal.setTreeDetailsModal();
  }

  findSelectedGraphIndex(): number {
    return this.drsGraphs.indexOf(this.selectedGraph);
  }

  onRemoveClicked(graph: DrsGraphOverviewDto) {
    this.drsService.deleteDrsGraph(graph.fullIdentifier).subscribe(
      () => {
        const index = this.drsGraphs.indexOf(graph);
        if (index > -1) {
          this.drsGraphs.splice(index, 1);
        }
      },
      () => {
        throw new Error(this.translateService.instant('drs.graph.root.node.delete.error', graph.id));
      },
    );
  }

  cloneVersion() {
    this.confirmationService.confirm({
      header: this.translateService.instant('drs.graph.root.node.setToProduction.confirm.header'),
      message: this.translateService.instant('drs.graph.root.node.setToProduction.confirm'),
      accept: () => {
        this.drsService.cloneVersion().subscribe(
          () => {
            this.drsEditorModeService.setLocked(true);
          },
          () => {
            throw new Error(this.translateService.instant('drs.version.clone.error'));
          },
        );
      },
    });
  }
}

export interface GraphManagerComponentResolveData {
  drsGraphs: DrsGraphOverviewDto[];
  userRoles: DrsUserRoleDto[];
}
