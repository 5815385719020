import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { DrsPreviewDataDto } from '../shared/model/drs-preview-data-dto';
import { FeatureRepresentationType } from '../shared/model/drs-node-data-dto';
import { DrsService } from '../shared/services/drs.service';

@Component({
  selector: 'app-preview-panel',
  templateUrl: './preview-panel.component.html',
  styleUrls: ['./preview-panel.component.scss']
})
export class PreviewPanelComponent implements OnChanges {
  @Input() isPreviewSidebarOpen: boolean;
  @Input() previewData: DrsPreviewDataDto;
  @Output() previewPopupClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  displayHelpText = false;
  featureRepresentationType = FeatureRepresentationType;
  imageUrl: string;

  ngOnChanges(changes): void {
    if (changes.previewData && changes.previewData.currentValue) {
      this.imageUrl = null;

      // To reinitialize the <object> element when change from pdf to png/jpeg, To solve the bug with the empty <object>.
      setTimeout(() => {
        this.imageUrl = DrsService.FILE_PATH + this.previewData.imageId;
      });
    }
  }

  onPopupClose() {
    this.hideHelpText();
    this.isPreviewSidebarOpen = false;
    this.previewPopupClose.emit(this.isPreviewSidebarOpen);
  }

  onShowHelpText() {
    this.displayHelpText = true;
  }

  hideHelpText() {
    this.displayHelpText = false;
  }
}
