/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./search-feature.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/primeng/components/autocomplete/autocomplete.ngfactory";
import * as i3 from "primeng/components/autocomplete/autocomplete";
import * as i4 from "@angular/forms";
import * as i5 from "primeng/components/button/button";
import * as i6 from "./search-feature.component";
import * as i7 from "./search-feature-service";
import * as i8 from "primeng/components/common/confirmationservice";
import * as i9 from "@ngx-translate/core";
var styles_SearchFeatureComponent = [i0.styles];
var RenderType_SearchFeatureComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SearchFeatureComponent, data: {} });
export { RenderType_SearchFeatureComponent as RenderType_SearchFeatureComponent };
export function View_SearchFeatureComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { inputElement: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "ui-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["class", "ui-inputgroup"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "p-autoComplete", [], [[2, "ui-inputwrapper-filled", null], [2, "ui-inputwrapper-focus", null], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "onSelect"], [null, "onClear"], [null, "completeMethod"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.featureNode = $event) !== false);
        ad = (pd_0 && ad);
    } if (("onSelect" === en)) {
        var pd_1 = (_co.onFeatureSelect() !== false);
        ad = (pd_1 && ad);
    } if (("onClear" === en)) {
        var pd_2 = (_co.onClearFeature() !== false);
        ad = (pd_2 && ad);
    } if (("completeMethod" === en)) {
        var pd_3 = (_co.search($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_AutoComplete_0, i2.RenderType_AutoComplete)), i1.ɵdid(4, 9879552, [[1, 4], ["inputElement", 4]], 1, i3.AutoComplete, [i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef, i1.IterableDiffers], { placeholder: [0, "placeholder"], field: [1, "field"], suggestions: [2, "suggestions"] }, { completeMethod: "completeMethod", onSelect: "onSelect", onClear: "onClear" }), i1.ɵqud(603979776, 2, { templates: 1 }), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.AutoComplete]), i1.ɵdid(7, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(9, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [["class", "search-cancel"], ["icon", "pi pi-times"], ["pButton", ""], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onConfirmClearFeature() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 4341760, null, 0, i5.ButtonDirective, [i1.ElementRef], { icon: [0, "icon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_9 = i1.ɵinlineInterpolate(1, "", _co.placeholder, ""); var currVal_10 = i1.ɵinlineInterpolate(1, "", _co.labelField, ""); var currVal_11 = (_co.typeOfSearchIsFeature ? _co.results : _co.nodeTemplateResults); _ck(_v, 4, 0, currVal_9, currVal_10, currVal_11); var currVal_12 = _co.featureNode; _ck(_v, 7, 0, currVal_12); var currVal_14 = "pi pi-times"; _ck(_v, 11, 0, currVal_14); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 4).filled; var currVal_1 = (i1.ɵnov(_v, 4).focus && !i1.ɵnov(_v, 4).disabled); var currVal_2 = i1.ɵnov(_v, 9).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 9).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 9).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 9).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 9).ngClassValid; var currVal_7 = i1.ɵnov(_v, 9).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 9).ngClassPending; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_13 = _co.noFeatureSelection; _ck(_v, 10, 0, currVal_13); }); }
export function View_SearchFeatureComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-search-feature", [], null, null, null, View_SearchFeatureComponent_0, RenderType_SearchFeatureComponent)), i1.ɵdid(1, 4243456, null, 0, i6.SearchFeatureComponent, [i7.SearchFeatureService, i8.ConfirmationService, i9.TranslateService], null, null)], null, null); }
var SearchFeatureComponentNgFactory = i1.ɵccf("app-search-feature", i6.SearchFeatureComponent, View_SearchFeatureComponent_Host_0, { featureNode: "featureNode", results: "results", searchUrl: "searchUrl", placeholder: "placeholder", labelField: "labelField", noFeatureSelection: "noFeatureSelection", isFocused: "isFocused", typeOfSearchIsFeature: "typeOfSearchIsFeature", searchType: "searchType" }, { featureNodeSelected: "featureNodeSelected", featureNodeClear: "featureNodeClear" }, []);
export { SearchFeatureComponentNgFactory as SearchFeatureComponentNgFactory };
