import { Component, Input } from '@angular/core';
import { DocumentCategoryEnum, DocumentModeratorEnum } from '../../drs/shared/model/patients/document-detail-dto';
import { formatStringToDate } from '../../utils/date';
import { TranslateService } from '@ngx-translate/core';
import { PatientDocumentDto } from '../../drs/shared/model/patients/patient-document-dto';
import { DateFormat } from '../../utils/date-format';

@Component({
  selector: 'app-patient-detail-document',
  templateUrl: './patient-detail-document.component.html',
  styleUrls: ['./patient-detail-document.component.scss'],
})
export class PatientDetailDocumentComponent {

  @Input() headerKey: string;
  @Input() patientId: number;
  @Input() documents: PatientDocumentDto[];
  @Input() markedDeleted: boolean;

  constructor(private translateService: TranslateService) {}

  getModeratorKeyTranslated(moderator: DocumentModeratorEnum): string {
    if (moderator === DocumentModeratorEnum.PATIENT) {
      return this.translateService.instant('document.detail.moderator.PATIENT');
    } else if (moderator === DocumentModeratorEnum.MISANTO) {
      return 'MiSANTO';
    }
    return '-';
  }

  getCategoryKeyTranslated(category: DocumentCategoryEnum): string {
    return this.translateService.instant('api.document.category.' + category);
  }

  getInvestigationDateFormatted(investigationDate: string) {
    return formatStringToDate(investigationDate, DateFormat.MISANTO_DATE_HYPHEN);
  }

  displayAddDocumentButton(): boolean {
    return this.headerKey === 'patient.cockpit.details.heading.documents';
  }
}
