import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Message } from 'primeng/api';
import { LoggingErrorsService } from '../../../logging-errors.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { AppCoreService } from '../../../core/services/app-core.service';

@Injectable()
export class CustomErrorsHandler implements ErrorHandler {
  private HTTPS_STATUS_CODE_UNAUTHORIZED = 401;
  private HTTPS_STATUS_CODE_FORBIDDEN = 403;
  private HTTPS_STATUS_CODE_NOT_FOUND = 404;
  private HTTPS_STATUS_CODE_METHOD_NOT_ALLOWED = 405;
  private errorMsgTimeout = 60000;
  public errorMessages: Message[] = [];

  constructor(
    private injector: Injector,
    private translateService: TranslateService,
    private appCoreService: AppCoreService,
  ) {}

  handleError(error: Error | HttpErrorResponse) {
    if (error instanceof HttpErrorResponse) {
      // Server or connection error happened
      this.handleServerOrConnectionError(error);
    } else {
      // Handle Client Error (Angular Error, ReferenceError...)
      this.handleErrorInternal([{
        severity: 'error',
        summary: this.translateService.instant('error.generalError')
      }]);
    }
    console.error(error);
  }

  handleServerOrConnectionError(error: HttpErrorResponse) {
    if (!navigator.onLine) {
      // Handle offline error
      this.handleErrorInternal([{
        severity: 'error',
        summary: this.translateService.instant('error.noInternetConnection')
      }]);
    } else {
      // Handle Http Error (error.status === 403, 404...)
      if (
        error.status === this.HTTPS_STATUS_CODE_UNAUTHORIZED ||
        error.status === this.HTTPS_STATUS_CODE_FORBIDDEN ||
        error.status === this.HTTPS_STATUS_CODE_NOT_FOUND ||
        error.status === this.HTTPS_STATUS_CODE_METHOD_NOT_ALLOWED ||
        error.url.includes(AppCoreService.LOGIN_PAGE_PATH)
      ) {
        this.appCoreService.redirectToLoginPage();
      } else {
        this.handleErrorInternal([{
          severity: 'error',
          summary: this.translateService.instant('error.generalError')
        }]);
      }
    }
  }

  handleErrorInternal(errorMessages) {
    const loggingErrorsService = this.injector.get(LoggingErrorsService);
    loggingErrorsService.logError(errorMessages);
    this.timeoutErrorMessage(loggingErrorsService);
  }

  handleErrorResponse(error: any) {
    let errorObject;
    try {
      errorObject = JSON.parse(error);
    } catch (e) {
      errorObject = error;
    }
    this.errorMessages = [];
    if (errorObject.fieldErrors) {
      errorObject.fieldErrors.forEach(fieldError => {
        this.addNewErrorMessage(fieldError.message);
      });
    }
    if (errorObject.globalError) {
      this.addNewErrorMessage(errorObject.globalError.message);
    }
    if (typeof errorObject === 'string') {
      this.addNewErrorMessage(errorObject);
    }
    this.handleErrorInternal(this.errorMessages);
  }

  addNewErrorMessage(summary) {
    this.errorMessages.push({
      severity: 'error',
      summary: summary
    });
  }

  timeoutErrorMessage(loggingErrorsService) {
    const timer = setTimeout(() => {
      this.errorMessages = [];
      loggingErrorsService.logError(this.errorMessages);
      clearTimeout(timer);
    }, this.errorMsgTimeout);
  }
}
