import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { EventEmitter, NgZone, OnDestroy, OnInit } from '@angular/core';
import { SkygearService } from '../../skygear.service';
import { ChatService } from '../../drs/shared/services/chat.service';
import { ConversationState } from '../../drs/shared/model/chat/conversation';
import { Patient } from './patient';
import { SkygearHelper } from '../../drs/shared/model/chat/skygear-model';
import * as moment from 'moment';
var WaitingRoomComponent = /** @class */ (function () {
    function WaitingRoomComponent(skygearService, chatService, ngZone) {
        this.skygearService = skygearService;
        this.chatService = chatService;
        this.ngZone = ngZone;
        this.selectedPatient = new EventEmitter();
        this.finishedConversation = new EventEmitter();
        this.setNewPatient = new EventEmitter();
        this.restartChatPanel = new EventEmitter();
        this.refreshConversationsInterval = 10000;
        this.lastResponseOfConversationDtos = [];
        this.isInitialRequest = true;
        this.FILTER_OPEN = [ConversationState.WAITING_FOR_DOCTOR];
        this.FILTER_MINE = [
            ConversationState.ONGOING,
            ConversationState.WAITING_FOR_PATIENT,
            ConversationState.FINISHED_BY_PATIENT,
        ];
        this.FILTER_COMPLETED = [
            ConversationState.DONE,
            ConversationState.DECLINED
        ];
        this.ACTIVE_FILTER_CLASS = 'active-filter';
        this.OPEN_BUTTON_INDEX = 0;
        this.MINE_BUTTON_INDEX = 1;
    }
    WaitingRoomComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions = this.skygearService.skygearUserLoggedIn.subscribe(function () {
            _this.currentUserId = _this.skygearService.getCurrentUser()._id;
            // Check for new chats immediately and than at intervals
            _this.getAllConversations();
            _this.interval = setInterval(function () {
                _this.getAllConversations();
            }, _this.refreshConversationsInterval);
        });
        if (this.messageReceived) {
            var messageReceivedSubscription = this.messageReceived.subscribe(function (message) { return _this.onMessageReceived(message); });
            this.subscriptions.add(messageReceivedSubscription);
        }
        var conversationFinishedSubscription = this.conversationFinishedSuccessfully.subscribe(function () {
            _this.getAllConversations(function () { return _this.toggleFilter(_this.buttonFilters[_this.OPEN_BUTTON_INDEX], _this.FILTER_OPEN); });
        });
        this.subscriptions.add(conversationFinishedSubscription);
        this.buttonFilters = this.filterTopBar.nativeElement.querySelectorAll('.filter-buttons');
    };
    WaitingRoomComponent.prototype.ngOnDestroy = function () {
        clearInterval(this.interval);
        if (this.subscriptions) {
            this.subscriptions.unsubscribe();
        }
    };
    WaitingRoomComponent.prototype.getAllConversations = function (then) {
        var _this = this;
        if (!this.currentUserId) {
            return;
        }
        // Make the code with asynchronous tasks reenter the Angular zone.
        // Executes it synchronously within the Angular zone and returns value returned by the subscription.
        this.ngZone.run(function () {
            _this.chatService.getConversations(_this.currentUserId).subscribe(function (conversationDtos) {
                if (_this.somethingChangedSinceLastRefresh(conversationDtos)) {
                    console.log('[WaitingRoom] Conversations state changed, re-adding all patients');
                    _this.addPatientFromConversation(conversationDtos);
                    _this.lastResponseOfConversationDtos = conversationDtos;
                }
                if (then) {
                    then();
                }
            }, function (err) {
                console.error('[WaitingRoom] Failed to retrieve conversations for user', _this.currentUserId, err);
            });
        });
    };
    WaitingRoomComponent.prototype.somethingChangedSinceLastRefresh = function (conversationDtos) {
        if (conversationDtos.length === 0 || conversationDtos.length !== this.lastResponseOfConversationDtos.length) {
            return true;
        }
        return !_.isEmpty(_.differenceWith(conversationDtos.sort(), this.lastResponseOfConversationDtos.sort(), _.isEqual));
    };
    WaitingRoomComponent.prototype.addPatientFromConversation = function (conversations) {
        var _this = this;
        this.allPatientsWaiting = [];
        conversations.forEach(function (conversation) {
            if (conversation.patientChatUserId === _this.currentUserId) {
                return;
            }
            var patient = new Patient();
            patient.name = conversation.patientUsername;
            patient.misantoConversation = conversation;
            patient.waitingTime = _this.getWaitingTime(patient.misantoConversation.createdAt);
            _this.allPatientsWaiting.push(patient);
        });
        this.setCurrentPatient();
        this.allPatientsWaiting = _.orderBy(this.allPatientsWaiting, ['misantoConversation.conversationState', 'misantoConversation.createdAt'], ['desc', 'asc']);
        this.filterPatients();
        this.setCountsOfFilteredPatients();
        if (this.isInitialRequest) {
            this.toggleFilter(this.buttonFilters[this.OPEN_BUTTON_INDEX], this.FILTER_OPEN);
            this.loadUnreadCounts(this.patientsWaiting);
        }
    };
    WaitingRoomComponent.prototype.loadUnreadCounts = function (patients) {
        var e_1, _a;
        this.isInitialRequest = false;
        var _loop_1 = function (patient) {
            if (patient.isInStateOngoing()) {
                this_1.skygearService.getConversation(patient.misantoConversation.chatServerConversationId)
                    .then(function (skygearConversation) {
                    patient.unreadMessageCount = skygearConversation.unread_count;
                })
                    .catch(function (err) {
                    console.error('[WaitingRoom] Failed to get conversation', patient.misantoConversation.chatServerConversationId, err);
                });
            }
        };
        var this_1 = this;
        try {
            for (var patients_1 = tslib_1.__values(patients), patients_1_1 = patients_1.next(); !patients_1_1.done; patients_1_1 = patients_1.next()) {
                var patient = patients_1_1.value;
                _loop_1(patient);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (patients_1_1 && !patients_1_1.done && (_a = patients_1.return)) _a.call(patients_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    WaitingRoomComponent.prototype.getWaitingTime = function (timestamp) {
        return moment(timestamp).fromNow();
    };
    WaitingRoomComponent.prototype.onSelectPatient = function (selectedPatient) {
        selectedPatient.unreadMessageCount = 0;
        if (selectedPatient.isInStateWaiting()) {
            selectedPatient.misantoConversation.conversationState = ConversationState.WAITING_FOR_PATIENT;
            this.toggleFilter(this.buttonFilters[this.MINE_BUTTON_INDEX], this.FILTER_MINE);
            this.setCountsOfFilteredPatients();
        }
        this.currentPatient = selectedPatient;
        this.selectedPatient.emit(selectedPatient);
    };
    WaitingRoomComponent.prototype.onMessageReceived = function (message) {
        if (message.record_type === 'message' && message.event_type === 'create') {
            var targetPatient = this.allPatientsWaiting.find(function (patient) {
                return patient.misantoConversation.chatServerConversationId === SkygearHelper.getCoreId(message.record.conversation);
            });
            if (targetPatient) {
                targetPatient.unreadMessageCount++;
            }
        }
    };
    WaitingRoomComponent.prototype.onFinishConversation = function (patient) {
        this.finishedConversation.emit(patient);
    };
    WaitingRoomComponent.prototype.toggleFilter = function (clickedFilter, filterType) {
        var _this = this;
        console.log('[WaitingRoom] toggleFilter', filterType[0]);
        this.currentPatient = null;
        this.restartChatPanel.emit();
        if (!this.patientsWaiting || !this.allPatientsWaiting || this.allPatientsWaiting.length === 0) {
            return;
        }
        this.currentPatientFilter = filterType;
        // change active filter's background color
        this.buttonFilters.forEach(function (button) {
            if (clickedFilter !== button) {
                button.classList.remove(_this.ACTIVE_FILTER_CLASS);
            }
        });
        clickedFilter.classList.add(this.ACTIVE_FILTER_CLASS);
        this.filterPatients();
    };
    WaitingRoomComponent.prototype.filterPatients = function () {
        var _this = this;
        this.patientsWaiting = this.allPatientsWaiting.filter(function (patient) {
            return patient.isInGivenState(_this.currentPatientFilter);
        });
        if (this.currentPatientFilter === this.FILTER_COMPLETED || this.currentPatientFilter === this.FILTER_MINE) {
            this.patientsWaiting = _.orderBy(this.patientsWaiting, 'misantoConversation.createdAt', 'desc');
        }
    };
    WaitingRoomComponent.prototype.setCurrentPatient = function () {
        var _this = this;
        if (!this.currentPatient) {
            return;
        }
        this.currentPatient = this.allPatientsWaiting.find(function (patient) {
            return patient.misantoConversation.id === _this.currentPatient.misantoConversation.id;
        });
        if (!this.currentPatient) {
            return;
        }
        /* Solved the edge case with backend still returning patient with state WAITING_FOR_DOCTOR
         (Reason: The claim request and the polling request happen at the same time) */
        if (this.currentPatient.isInStateWaitingForDoctor()) {
            this.currentPatient.misantoConversation.conversationState = ConversationState.WAITING_FOR_PATIENT;
        }
        this.setNewPatient.emit(this.currentPatient);
    };
    WaitingRoomComponent.prototype.setCountsOfFilteredPatients = function () {
        var _this = this;
        if (this.allPatientsWaiting) {
            this.countOpenConversations = this.allPatientsWaiting.filter(function (patient) {
                return patient.isInGivenState(_this.FILTER_OPEN);
            }).length;
            this.countCompletedConversations = this.allPatientsWaiting.filter(function (patient) {
                return patient.isInGivenState(_this.FILTER_COMPLETED);
            }).length;
            this.countMineConversations = this.allPatientsWaiting.filter(function (patient) {
                return patient.isInGivenState(_this.FILTER_MINE);
            }).length;
        }
    };
    return WaitingRoomComponent;
}());
export { WaitingRoomComponent };
