<app-patient-header></app-patient-header>
<form #documentUploadRequestForm="ngForm">
<div class="ui-grid doc-upload-container">
  <div class="document-data-container ui-grid-col-6 ui-g-offset-3">
    <div class="ui-grid ui-grid-pad ui-fluid">
      <div class="ui-grid-row input-container">
        <div class="ui-grid-col-9">
          <p-toast [style]="{marginTop: '150px', marginLeft: '300px' }" position="top-center"></p-toast>
          <p-fileUpload (onSelect)="onFileSelected($event)"
                        (onRemove)="onFileRemove()"
                        (onClear)="onClear()"
                        chooseLabel="{{'document.upload.btn.chooseFile.label' | translate}}"
                        cancelLabel="{{'document.upload.btn.cancelFile.label' | translate}}"
                        [disabled]="chooseButtonDisabled"
                        [showUploadButton]="false"
                        accept=".pdf"
                        name="documentFile">
            <ng-template pTemplate="content">
              <span class="dragDrop"
                    *ngIf="showContentArea">{{ 'document.upload.dragDrop.label' | translate }}</span>
            </ng-template>
          </p-fileUpload>
        </div>
      </div>
      <div class="ui-grid-row input-container">
        <div class="ui-grid-col-2">
          <span>{{'document.upload.type' | translate}}</span>
        </div>
        <div class="ui-grid-col-7">
          <div class="ui-grid">
            <div class="ui-grid-col-5">
              <p-dropdown name="document-type" [options]="categories" [(ngModel)]="document.category"
                          placeholder="{{'document.upload.type.dropdown.option' | translate}}"
                          required></p-dropdown>
            </div>
            <div class="ui-grid-col-7">
              <div class="ui-grid">
                <div class="ui-grid-col-4 date">
                  <span>{{'document.upload.date' | translate}}</span>
                </div>
                <div class="ui-grid-col-8">
                  <p-calendar
                    dateFormat="dd.mm.yy" [(ngModel)]="document.date" [ngModelOptions]="{standalone: true}"></p-calendar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ui-grid-row input-container">
        <div class="ui-grid-col-2">
          <span>{{'document.upload.description' | translate}}</span>
        </div>
        <div class="ui-grid-col-7">
          <input name="description" type="text"
                 placeholder="{{'document.upload.description' | translate}}"
                 pInputText [(ngModel)]="document.description"
                 maxlength="255"
                 required
                 autocomplete="off"/>
        </div>
      </div>
      <div class="ui-grid-row input-container">
        <div class="ui-grid-col-2">
          <span>{{'document.upload.doctorFirstName' | translate}}</span>
        </div>
        <div class="ui-grid-col-7">
          <input name="doctor-first-name" type="text"
                 placeholder="{{'document.upload.doctorFirstName' | translate}}"
                 [ngModelOptions]="{standalone: true}"
                 pInputText [(ngModel)]="document.doctorFirstName"
                 maxlength="255"
                 autocomplete="off"/>
        </div>
      </div>
      <div class="ui-grid-row input-container">
        <div class="ui-grid-col-2">
          <span>{{'document.upload.doctorLastName' | translate}}</span>
        </div>
        <div class="ui-grid-col-7">
          <input name="doctor-last-name" type="text"
                 placeholder="{{'document.upload.doctorLastName' | translate}}"
                 [ngModelOptions]="{standalone: true}"
                 pInputText [(ngModel)]="document.doctorLastName"
                 maxlength="255"
                 autocomplete="off"/>
        </div>
      </div>
      <div class="ui-grid-row input-container">
        <div class="ui-grid-col-2">
          <span>{{'document.upload.comment' | translate}}</span>
        </div>
        <div class="ui-grid-col-7">
          <textarea pInputTextarea
                    rows="8"
                    pInputText [(ngModel)]="document.comment"
                    [ngModelOptions]="{standalone: true}"
                    maxlength="10000"
                    placeholder="{{'document.upload.comment' | translate}}"></textarea>
        </div>
      </div>
      <div class="ui-grid-row input-container">
        <div class="ui-grid-col-2"></div>
        <div class="ui-grid-col-7">
          <div class="save-buttons-panel">
            <p-button label="{{'document.upload.btn.cancel' | translate}}" icon="fa fa-close" iconPos="left"
                      type="submit" (click)="onCancel()"></p-button>
            <p-button label="{{'document.upload.btn.save' | translate}}" icon="fa fa-check" iconPos="left" type="submit"
                      [disabled]="saveButtonDisabled || !documentUploadRequestForm.form.valid"
                      (onClick)="onSaveDocument()"></p-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="ui-grid-col-3"></div>
</div>
</form>
