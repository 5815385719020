import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {DrsFeatureNodeDto} from '../shared/model/drs-feature-node-dto';
import {Observable} from 'rxjs';
import {DrsGraphOverviewImpl} from '../shared/model/drs-graph-overview';
import {DrsService} from '../shared/services/drs.service';

@Injectable()
export class SearchFeatureService {

  constructor(private http: HttpClient, private drsService: DrsService) {
  }

  autocompleteFeature(searchTerm: string): Observable<DrsFeatureNodeDto[]> {
    const params = new HttpParams()
      .set('searchTerm', searchTerm)
      .set('version', this.drsService.selectedVersion.toString());
    return this.http.get<DrsFeatureNodeDto[]>(`/api/drs/feature`, { params });
  }

  autocompleteNodeTemplate(query: string, searchType: string): Observable<DrsGraphOverviewImpl[]> {
    return this.http.get<DrsGraphOverviewImpl[]>(`${this.createNodeTemplateUrl(query, searchType)}`);
  }

  createNodeTemplateUrl(query: string, searchType: string): string {
    const version = this.drsService.selectedVersion;
    return `/api/drs/graph?searchTerm=description:*${query}*,type:${searchType},version:${version}`;
  }
}
