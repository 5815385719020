import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss'],
})
export class ImageUploaderComponent {
  public allowedFileType = 'image/x-png,image/gif,image/jpeg,application/pdf';

  image: File;
  @Output() imageChange: EventEmitter<File> = new EventEmitter<File>();
  @Input() imagePath: string;
  @Input() disabled: boolean;
  @Output() featureImgId: EventEmitter<number> = new EventEmitter<number>();
  @Output() imageUrlChange: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  isImage: boolean;
  PDF_MIME_TYPE = 'application/pdf';

  onFileUploaded(event) {
    if (event.target.files && event.target.files[0]) {
      this.isImage = event.target.files[0].type !== this.PDF_MIME_TYPE;

      this.image = event.target.files[0];
      this.imageChange.emit(this.image);
      const reader = new FileReader();

      reader.onload = (readerEvent: any) => {
        this.imagePath = readerEvent.target.result;
      };

      reader.readAsDataURL(this.image);

      return reader;
    }
  }

  onDeleteImage() {
    this.featureImgId.emit(null);
    this.image = null;
    this.fileInput.nativeElement.value = '';
    this.imagePath = '';
  }
}
