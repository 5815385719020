import * as moment from 'moment';

/**
 * Formats a string date to a date of the provided format.
 * @param dateString the string date.
 * @param format the format the date should be formatted to.
 */
export function formatStringToDate(dateString: string, format: string): string {
  if (!dateString) {
    return;
  }
  return moment(dateString).format(format);
}

/**
 * Formats a date to a string date of the provided format.
 *
 * @param date the date
 * @param format the format the date should be formatted to
 */
export function formatDateToString(date: Date, format: string): string {
  return moment(date).format(format);
}
/**
 * Gets the numeric value corresponding to the time for
 * the specified date according to universal time.
 * Used for sorting of the date in the table.
 * @param dateString the string date.
 */
export function getDateTimestamp(dateString: string) {
  const date = new Date(dateString);
  return date.getTime();
}
