<div class="ui-inputgroup relation">
  <p-dropdown *ngIf="condition.conditionTypes" [options]="condition.conditionTypes" [(ngModel)]="condition.selectedType"
              optionLabel="name" class="dropdown"
              (onChange)="onClassificationTypeChange($event)" [disabled]="disabled">
    <ng-template let-selectedItem pTemplate="selectedItem">
      <div class="condition-value-wrapper">
        <span class="condition-selected-value">{{selectedItem.label}}</span>
      </div>
    </ng-template>
  </p-dropdown>

  <span class="ui-inputgroup-addon separator">{{'drs.edit.node.condition.value.separator' | translate}}</span>

  <p-dropdown *ngIf="condition.conditionValues" [options]="condition.conditionValues"
              [(ngModel)]="condition.selectedValue"
              class="dropdown condition-value-dropdown"
              (onChange)="onClassificationValueChange($event)" [disabled]="disabled">
    <ng-template let-selectedItem pTemplate="selectedItem">
      <div class="condition-value-wrapper">
        <span *ngIf="condition.selectedType.name === 'Answer'" class="condition-id">
          {{condition.conditionValues.indexOf(selectedItem) + 1}}
        </span>
        <span class="condition-selected-value">{{selectedItem.label}}</span>
      </div>
    </ng-template>
    <ng-template let-item pTemplate="item">
      <div class="condition-value-wrapper">
        <span *ngIf="condition.selectedType.name === 'Answer'" class="condition-id">
          {{condition.conditionValues.indexOf(item) + 1}}
        </span>
        <div class="condition-value">{{item.label}}</div>
      </div>
    </ng-template>
  </p-dropdown>
</div>
