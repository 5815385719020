import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UiLabelDto } from '../shared/model/ui-label-dto';
import { TranslateService } from '@ngx-translate/core';
import { AuditRecordDto } from '../shared/model/audit-record-dto';

@Component({
  selector: 'app-revisions-modal',
  templateUrl: './revisions-modal.component.html',
  styleUrls: ['./revisions-modal.component.scss'],
})
export class RevisionsModalComponent implements OnInit {
  @Output() showRevision = new EventEmitter<AuditRecordDto[]>();

  title: string;
  revisions: AuditRecordDto[];
  showRevisionsModal: boolean;

  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    this.title = '';
    this.revisions = [];
    this.showRevisionsModal = false;
  }

  showRevisionModal() {
    this.showRevisionsModal = true;
  }

  setModalTitle(title: string) {
    this.title = title;
  }

  setRevisionData(auditRecords: AuditRecordDto[]) {
    this.revisions = auditRecords;
  }

  hideRevisionModal() {
    this.showRevisionsModal = false;
  }

  translateLabelDto(uiLabel: UiLabelDto): string {
    uiLabel.params = this.tryToTranslateTheFirstParam(uiLabel.params);
    return this.translateService.instant(uiLabel.key, uiLabel.params);
  }

  private tryToTranslateTheFirstParam(params: string[]): string[] {
    // first param could be a label
    if (params[0]) {
      params[0] = this.translateService.instant(params[0]);
    }
    return params;
  }
}
