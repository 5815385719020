/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./revisions-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "../../../../node_modules/primeng/components/table/table.ngfactory";
import * as i5 from "primeng/components/table/table";
import * as i6 from "primeng/components/common/shared";
import * as i7 from "../../../../node_modules/primeng/components/dialog/dialog.ngfactory";
import * as i8 from "primeng/components/dialog/dialog";
import * as i9 from "./revisions-modal.component";
var styles_RevisionsModalComponent = [i0.styles];
var RenderType_RevisionsModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RevisionsModalComponent, data: {} });
export { RenderType_RevisionsModalComponent as RenderType_RevisionsModalComponent };
function View_RevisionsModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "th", [["class", "revision-table-date-column"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "th", [["class", "revision-table-user-column"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 2, "th", [["class", "revision-table-change-column"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(10, 0, null, null, 2, "th", [["class", "revision-table-id-column"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("drs.revisions.table.header.date")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("drs.revisions.table.header.user")); _ck(_v, 5, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("drs.revisions.table.header.change")); _ck(_v, 8, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("drs.revisions.table.header.id")); _ck(_v, 11, 0, currVal_3); }); }
function View_RevisionsModalComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.translateLabelDto(_v.context.$implicit); _ck(_v, 2, 0, currVal_0); }); }
function View_RevisionsModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RevisionsModalComponent_4)), i1.ɵdid(7, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""]))], function (_ck, _v) { var currVal_2 = _v.context.$implicit.uiLabels; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.revisionDate; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.username; _ck(_v, 4, 0, currVal_1); var currVal_3 = _v.context.$implicit.id; _ck(_v, 9, 0, currVal_3); }); }
function View_RevisionsModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "p-table", [["sortField", "createdAt"], ["sortMode", "single"]], null, null, null, i4.View_Table_0, i4.RenderType_Table)), i1.ɵprd(512, null, i5.TableService, i5.TableService, []), i1.ɵdid(2, 5488640, null, 1, i5.Table, [i1.ElementRef, i1.NgZone, i5.TableService, i1.ChangeDetectorRef], { sortMode: [0, "sortMode"], value: [1, "value"], sortField: [2, "sortField"], sortOrder: [3, "sortOrder"] }, null), i1.ɵqud(603979776, 3, { templates: 1 }), (_l()(), i1.ɵand(0, null, null, 1, null, View_RevisionsModalComponent_2)), i1.ɵdid(5, 16384, [[3, 4]], 0, i6.PrimeTemplate, [i1.TemplateRef], { name: [0, "name"] }, null), (_l()(), i1.ɵand(0, null, null, 1, null, View_RevisionsModalComponent_3)), i1.ɵdid(7, 16384, [[3, 4]], 0, i6.PrimeTemplate, [i1.TemplateRef], { name: [0, "name"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "single"; var currVal_1 = _co.revisions; var currVal_2 = "createdAt"; var currVal_3 = (0 - 1); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = "header"; _ck(_v, 5, 0, currVal_4); var currVal_5 = "body"; _ck(_v, 7, 0, currVal_5); }, null); }
export function View_RevisionsModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "p-dialog", [], null, [[null, "visibleChange"], [null, "onHide"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.showRevisionsModal = $event) !== false);
        ad = (pd_0 && ad);
    } if (("onHide" === en)) {
        var pd_1 = (_co.hideRevisionModal() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i7.View_Dialog_0, i7.RenderType_Dialog)), i1.ɵdid(1, 180224, null, 2, i8.Dialog, [i1.ElementRef, i1.Renderer2, i1.NgZone], { visible: [0, "visible"], header: [1, "header"], draggable: [2, "draggable"], modal: [3, "modal"], dismissableMask: [4, "dismissableMask"], style: [5, "style"], baseZIndex: [6, "baseZIndex"] }, { onHide: "onHide", visibleChange: "visibleChange" }), i1.ɵqud(603979776, 1, { headerFacet: 1 }), i1.ɵqud(603979776, 2, { footerFacet: 1 }), i1.ɵpod(4, { "width": 0 }), (_l()(), i1.ɵand(16777216, null, 1, 1, null, View_RevisionsModalComponent_1)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showRevisionsModal; var currVal_1 = i1.ɵinlineInterpolate(1, "Revisions ", _co.title, ""); var currVal_2 = false; var currVal_3 = true; var currVal_4 = true; var currVal_5 = _ck(_v, 4, 0, "98%"); var currVal_6 = 10001; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = _co.revisions.length; _ck(_v, 6, 0, currVal_7); }, null); }
export function View_RevisionsModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-revisions-modal", [], null, null, null, View_RevisionsModalComponent_0, RenderType_RevisionsModalComponent)), i1.ɵdid(1, 114688, null, 0, i9.RevisionsModalComponent, [i2.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RevisionsModalComponentNgFactory = i1.ɵccf("app-revisions-modal", i9.RevisionsModalComponent, View_RevisionsModalComponent_Host_0, {}, { showRevision: "showRevision" }, []);
export { RevisionsModalComponentNgFactory as RevisionsModalComponentNgFactory };
