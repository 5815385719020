import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PatientDto } from '../../drs/shared/model/patients/patient-dto';

@Component({
  selector: 'app-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PatientListComponent implements OnInit {
  patients: PatientDto[] = [];

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.route.data.subscribe((data) => {
      this.patients = data.patients.patients;
    });
  }

  selectRow(patient: PatientDto) {
    this.router.navigate(['patientcockpit/' + patient.id]);
  }
}
