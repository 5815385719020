import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ConsultationWithConversation } from '../../drs/shared/model/chat/consultation';

@Component({
  selector: 'app-consultation-panel',
  templateUrl: './consultation-panel.component.html',
  styleUrls: ['./consultation-panel.component.scss']
})
export class ConsultationPanelComponent implements OnChanges {

  @Input() consultation: ConsultationWithConversation;
  @Input() freeChatCheckboxEnabled: boolean;

  @Output() freeChatChange = new EventEmitter<boolean>();
  @Output() closeConsultationPanel = new EventEmitter<boolean>();

  freeChat = false;

  hideConsultationPanel() {
    this.closeConsultationPanel.emit(false);
  }

  /**
   * Invoked when the state of the 'free chat' checkbox is changed.
   *
   * @param checked whether the free chat checkbox is checked
   */
  onFreeChatCheckChanged(checked: boolean) {
    this.freeChatChange.emit(checked);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['consultation']) {
      this.freeChat = this.isFreeChat();
    }
  }

  private isFreeChat(): boolean {
    if (this.consultation && this.consultation.conversation) {
      return this.consultation.conversation.free;
    }
    return false;
  }
}
