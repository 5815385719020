<p-messages [value]="localMessages$ | async"></p-messages>

<div class="mis-table-container">
  <p-table [value]="drsGraphs" [resizableColumns]="true">
    <ng-template pTemplate="caption">
      <div class="ui-helper-clearfix">
        <div class="current-version-text">
          {{'drs.version.clone.current.text' | translate}}
          {{currentVersion}}
        </div>
        <button *ngIf="isUserSuperAdmin"
                pButton
                title="Duplicate version"
                label="{{'drs.version.clone.placeholder' | translate}}"
                type="button"
                class="ui-button-raised ui-button-secondary"
                style="float:left"
                (click)="cloneVersion()"
                [disabled]="isEditorLocked$ | async"></button>
        <button pButton
                title="Create graph"
                type="button"
                icon="pi pi-plus"
                class="ui-button-raised ui-button-secondary"
                style="float:right"
                (click)="showDialogToAdd()"
                [disabled]="isEditorLocked$ | async"></button>
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th style="width:15%" [pSortableColumn]="'id'" pResizableColumn>
          Id
          <p-sortIcon [field]="'id'"></p-sortIcon>
        </th>
        <th style="width:55%" [pSortableColumn]="'label'">
          Name
          <p-sortIcon [field]="'label'"></p-sortIcon>
        </th>
        <th style="width:10%"></th>
        <th style="width:10%"></th>
        <th style="width:10%"></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-graph>
      <tr>
        <td>
          {{graph.id}}
        </td>
        <td>
          {{graph.label}}
        </td>
        <td>
          <button type="button"
                  pButton
                  icon="pi pi-folder-open"
                  class="ui-button-raised ui-button-secondary"
                  routerLink="/{{graph.fullIdentifier}}"></button>
        </td>
        <td>
          <button type="button"
                  pButton
                  icon="pi pi-pencil"
                  class="ui-button-raised ui-button-secondary"
                  (click)="onEditButtonClicked(graph)"
                  [disabled]="isEditorLocked$ | async"></button>
        </td>
        <td>
          <button type="button"
                  pButton
                  icon="pi pi-trash"
                  class="ui-button-raised ui-button-secondary"
                  (click)="confirmDeletion(graph)"
                  [disabled]="!isUserSuperAdmin || (isEditorLocked$ | async)"></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<p-confirmDialog header="{{'drs.graph.root.node.delete.confirm.header' | translate}}"
                 icon="pi pi-question-circle"
                 #cd>
  <p-footer>
    <button type="button" pButton icon="pi pi-times" label="{{'btn.no' | translate}}" (click)="cd.reject()"></button>
    <button type="button" pButton icon="pi pi-check" label="{{'btn.yes' | translate}}" (click)="cd.accept()"></button>
  </p-footer>
</p-confirmDialog>

<app-modal-dialog #treeDetailsModal
                  headerName="{{'drs.graph.root.node.details.modal.header' | translate}}"
                  (saveChange)="onSaveButtonClick($event)"></app-modal-dialog>
