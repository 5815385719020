/**
 * Returns empty string if the value is null, otherwise returns the value.
 *
 * @param value the string
 */
export function nullToEmpty(value: string): string {
  if (value == null) {
    return '';
  }
  return value;
}
