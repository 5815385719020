export enum VersionState {
  DEVELOPMENT = 'DEVELOPMENT',
  PRODUCTION = 'PRODUCTION',
  ARCHIVE = 'ARCHIVE'
}

/**
 * Represents the summary of a specific DRS version.
 */
export interface DrsVersionSummary {
  majorNumber: number;
  state: VersionState;
  locked: boolean;
}
