export interface DrsClassificationDto {
  id: number;
  type: string;
  typeId: number;
  classification: string;
}

export class DrsClassificationDtoImpl implements DrsClassificationDto {
  classification: string;
  id: number;
  type: string;
  typeId: number;
}
