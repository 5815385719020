import * as _ from 'lodash';
import {v4 as uuid} from 'uuid';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { Subscription ,  Observable } from 'rxjs';
import {DrsGraph} from './shared/model/drs-graph';
import {ConfirmationService, Message, SelectItem, TreeNode} from 'primeng/api';
import {DrsNodeDataDto, FeatureRepresentationType, FeatureType, NodeType} from './shared/model/drs-node-data-dto';
import {DrsNodeDto, DrsNodeDtoImpl} from './shared/model/drs-node-dto';
import {DrsAnswerDtoImpl} from './shared/model/drs-answer-dto';
import {DrsService} from './shared/services/drs.service';
import {DrsEdgeDto, DrsEdgeDtoImpl} from './shared/model/drs-edge-dto';
import {DrsAppDataDto} from './shared/model/drs-app-data';
import {SelectableConditionImpl} from './condition/selectable-condition';
import {TranslateService} from '@ngx-translate/core';
import {ConditionType, DrsConditionService} from './shared/services/drs.condition.service';
import {DrsFeatureNodeDto} from './shared/model/drs-feature-node-dto';
import {AppDataService} from '../core/app-data/app-data.service';
import {DrawingService} from './shared/services/drawing.service';
import {DrsPreviewDataDto} from './shared/model/drs-preview-data-dto';
import {DrsGraphOverviewDto, GraphTypeEnum} from './shared/model/drs-graph-overview';
import {DrsGraphResolverService} from './resolvers/drs-graph-resolver.service';
import {AuditingService} from './shared/services/auditing.service';
import {AuditRecordDto} from './shared/model/audit-record-dto';
import * as moment from 'moment';
import { DrsEditorModeService } from './shared/services/drs-editor-mode.service';
import { ApplicationMessage } from '../core/messages/application-message';
import { FullId } from './shared/model/full-id';
import { Location } from '@angular/common';
import { LoggingErrorsService } from '../logging-errors.service';
import {LoaderService} from '../shared/loader/loader.service';

const MAX_ZOOM_PERCENT = 140;
const MIN_ZOOM_PERCENT = 40;
const INIT_ZOOM_PERCENT = 100;

@Component({
  selector: 'app-drs',
  templateUrl: './drs.component.html',
  styleUrls: ['./drs.component.scss'],
  providers: [
    DrsEditorModeService
  ]
})
export class DrsComponent implements OnInit, OnDestroy {

  public drsGraph: DrsGraph;
  public drsTreeData: TreeNode[];
  public drsAppData: DrsAppDataDto;
  selectedNode: DrsNodeDto;
  editData: DrsNodeDataDto;
  editChildren: DrsNodeDto[];
  messages: Message[];
  isEditFeatureMode: boolean;
  imagePath: string;
  image: File;
  displayClickableMap = false;
  clickableMapInitialized = false;
  featureTypes: SelectItem[];
  shouldRelationBeOpen = false;
  clickedEmptyNode: DrsNodeDto;
  previewData: DrsPreviewDataDto;
  isPreviewSidebarOpen = false;
  zoomPercentage: number = INIT_ZOOM_PERCENT;
  featureType = FeatureType;
  showCutNodeTemplatePopup = false;
  showAnswerIsUsedInConditionsPopup = false;
  cutNodeTemplateName: string;
  showCopyNodeTemplatePopup = false;
  copyNodeTemplateName: string;
  graphTemplateSearchType = GraphTypeEnum.GRAPH_TEMPLATE;
  drsGraphSearchType = GraphTypeEnum.DRS_GRAPH;

  @ViewChild('clickableMap', { static: true }) clickableMap;
  @ViewChild('drsGraphContainer', { static: true }) drsGraphContainer;
  @ViewChild('revisionModal', { static: true }) revisionModal;
  @ViewChild('deleteConfirmationModal', { static: true }) deleteConfirmationModal;

  searchModeTypes: SelectItem[];

  isEditorLocked$: Observable<boolean>;

  localMessages$: Observable<ApplicationMessage[]>;

  private subscriptions = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private drsService: DrsService,
    private confirmationService: ConfirmationService,
    private translateService: TranslateService,
    private drsConditionService: DrsConditionService,
    private router: Router,
    private appDataService: AppDataService,
    private drawingService: DrawingService,
    private changeDetectorRef: ChangeDetectorRef,
    private graphResolverService: DrsGraphResolverService,
    private auditingService: AuditingService,
    private drsEditorModeService: DrsEditorModeService,
    private location: Location,
    private loggingErrorsService: LoggingErrorsService,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {

    this.isEditorLocked$ = this.drsEditorModeService.isEditorLocked$;
    this.localMessages$ = this.drsEditorModeService.messages$;

    this.handleDataReload();

    this.route.data.subscribe((data: DrsComponentResolveData) => this.handleRouteData(data));

    this.searchModeTypes = [
      {
        label: this.translateService.instant('drs.edit.node.searchMode.feature'),
        value: NodeType.FEATURE_NODE,
      },
      {
        label: this.translateService.instant('drs.edit.node.searchMode.graph'),
        value: NodeType.LINK_NODE,
      },
      {
        label: this.translateService.instant('drs.edit.node.searchMode.template'),
        value: GraphTypeEnum.GRAPH_TEMPLATE,
      },
    ];

    this.featureTypes = [
      {
        label: this.translateService.instant('drs.edit.radioButton.dropdown.label'),
        value: FeatureRepresentationType.RADIO_BUTTON,
      },
      {
        label: this.translateService.instant('drs.edit.clickableMap.dropdown.label'),
        value: FeatureRepresentationType.CLICKABLE_MAP,
      },
    ];
  }

  ngOnDestroy(): void {
    this.drsEditorModeService.unsubscribeFromEditorModeChanges();
    this.subscriptions.unsubscribe();
  }

  private handleRouteData(data: DrsComponentResolveData) {
    if (_.isNil(data.drsGraph)) {
      this.clearTree();
    } else {
      this.loggingErrorsService.clearAll();
      this.initTree(data);
    }
  }

  private clearTree() {
    this.drsGraph = {
      id: 0,
      label: '',
      rootNode: null,
      version: -1,
      fullIdentifier: ''
    };
    this.drsTreeData = [];
    this.clearSelection();
  }

  private initTree(data: DrsComponentResolveData) {

    this.graphResolverService.graphResolved.emit(data.drsGraph);
    this.drsGraph = data.drsGraph;
    this.drsTreeData = [data.drsGraph.rootNode];
    this.drsAppData = data.appData;

    this.handleGraphVersion(this.drsGraph.version);
  }

  changeIsPreviewSidebarOpen(event: boolean) {
    this.isPreviewSidebarOpen = event;
  }

  showPreviewSidebar() {
    this.isPreviewSidebarOpen = true;
  }

  hidePreviewSidebar() {
    this.isPreviewSidebarOpen = false;
  }

  showCutPopup() {
    this.showCutNodeTemplatePopup = true;
  }

  showCopyPopup() {
    this.showCopyNodeTemplatePopup = true;
  }

  cutNodeTemplate() {
    this.showCutNodeTemplatePopup = false;
    this.confirmationService.confirm({
      message: this.translateService.instant('drs.edit.cut.confirmation.message'),
      accept: () => {
        const drsGraphOverviewDto: DrsGraphOverviewDto = {
          label: this.cutNodeTemplateName,
          type: GraphTypeEnum.GRAPH_TEMPLATE,
          fullIdentifier: this.drsGraph.fullIdentifier
        };
        this.loaderService.keepLoadingWhenCallIsDone(true);
        this.drsService.cutNodeTemplate(this.drsGraph.fullIdentifier, this.selectedNode.id, drsGraphOverviewDto)
          .subscribe((data: FullId) => {
            this.cutNodeTemplateName = '';
            this.refreshGraph(data.fullIdentifier);
          });
      },
      reject: () => {
        this.cutNodeTemplateName = '';
      },
    });
  }

  copyNodeTemplate() {
    this.showCopyNodeTemplatePopup = false;
    const drsGraphOverviewDto: DrsGraphOverviewDto = {
      label: this.copyNodeTemplateName,
      type: GraphTypeEnum.GRAPH_TEMPLATE,
      fullIdentifier: this.drsGraph.fullIdentifier
    };
    this.drsService.copyNodeTemplate(this.drsGraph.fullIdentifier, this.selectedNode.id, drsGraphOverviewDto)
      .subscribe(data => this.copyNodeTemplateName = '');
  }

  zoomInDrsTree() {
    if (this.zoomPercentage < MAX_ZOOM_PERCENT) {
      this.zoomPercentage += 10;
      this.drsGraphContainer.nativeElement.style.zoom = this.zoomPercentage + '%';
    }

    if (this.zoomPercentage >= INIT_ZOOM_PERCENT) {
      this.drsGraphContainer.nativeElement.style.MozTransform = 'scale(' + this.zoomPercentage / 100 + ')';
    }
  }

  zoomOutDrsTree() {
    if (this.zoomPercentage > MIN_ZOOM_PERCENT) {
      this.zoomPercentage -= 10;
      this.drsGraphContainer.nativeElement.style.zoom = this.zoomPercentage + '%';
    }

    if (this.zoomPercentage >= INIT_ZOOM_PERCENT) {
      this.drsGraphContainer.nativeElement.style.MozTransform = 'scale(' + this.zoomPercentage / 100 + ')';
    }
  }

  isFeatureTypeClickableMap() {
    return this.editData.featureRepresentationType === FeatureRepresentationType.CLICKABLE_MAP;
  }

  onImageUpload(event) {
    this.image = event;
  }

  onDeleteImage(event) {
    this.editData.featureImageId = event;
    this.imagePath = '';
  }

  getFeatureDataForPreview(featureFullId: string) {
    this.drsService.getFeatureById(featureFullId).subscribe((data: DrsPreviewDataDto) => {
      this.previewData = data;
    });
  }

  onNodeSelect(event: NodeSelectedEvent) {
    if (event.node.type === NodeType.LINK_NODE) {
      this.router.navigateByUrl(`/${event.node.data.linkedGraphFullIdentifier}`);
      return;
    }

    let parentNode;

    if (this.isNodeEmpty(event.node)) {
      this.clickedEmptyNode = event.node;
      const getParentNode = tree => {
        _.each(tree.children, child => {
          if (_.isEqual(child, event.node)) {
            parentNode = tree;
            return;
          } else if (child.children.length > 0) {
            getParentNode(child);
          }
        });
      };
      getParentNode(this.drsTreeData[0]);
    }

    if (parentNode) {
      this.getFeatureDataForPreview(parentNode.data.featureFullIdentifier);
      this.selectedNode = parentNode;
      this.shouldRelationBeOpen = true;
      this.prepareNodeForEditing(parentNode);
    } else {
      this.getFeatureDataForPreview(event.node.data.featureFullIdentifier);
      this.selectedNode = event.node;
      this.shouldRelationBeOpen = false;
      this.prepareNodeForEditing(event.node);
    }
  }

  isNodeEmpty(node) {
    return !node.data.featureUuid;
  }

  isRootNodeSelected(): boolean {
    return this.drsGraph.rootNode === this.selectedNode;
  }

  isNodeFocused(node) {
    return this.shouldRelationBeOpen && this.isNodeEmpty(node) && node.nodeId === this.clickedEmptyNode.nodeId;
  }

  private prepareNodeForEditing(node: DrsNodeDto) {
    this.editData = _.cloneDeep(node.data);
    this.imagePath = '';
    this.image = null;
    if (this.editData.featureImageId) {
      this.imagePath = DrsService.FILE_PATH + this.editData.featureImageId;
    }
    this.editChildren = _.cloneDeep(node.children);
    this.prepareConditions();
  }

  private prepareConditions() {
    const classificationTypes = this.drsAppData.classificationTypes;
    this.editChildren.forEach(child =>
      this.drsConditionService.initializeNodeConditions(child, classificationTypes, this.editData.possibleAnswers),
    );
  }

  onAddCondition(child: DrsNodeDto) {
    const addNewCondition = new SelectableConditionImpl();
    addNewCondition.conditionTypes = this.drsConditionService.prepareConditionTypes(
      this.drsAppData.classificationTypes,
    );
    if (!child.conditions) {
      child.conditions = [];
    }
    child.conditions.push(addNewCondition);
  }

  onRemoveCondition(child: DrsNodeDto, index: number) {
    child.conditions.splice(index, 1);
  }

  onCancelClicked() {
    this.clearSelection();
  }

  onSaveNodeClicked() {
    this.saveNode();
  }

  saveNode() {
    this.editChildren.forEach(node => {
      if (node.type === GraphTypeEnum.GRAPH_TEMPLATE) {
        node.type = NodeType.FEATURE_NODE;
      }
    });
    this.setCurrentChildrenToGraph();

    this.loaderService.keepLoadingWhenCallIsDone(true);
    this.drsService
      .saveDrsNodeDownwards(this.drsGraph.fullIdentifier, this.drsGraph.rootNode)
      .subscribe((data: FullId) => this.refreshGraph(data.fullIdentifier));
    this.clearSelection();
  }

  onSaveFeatureClicked() {
    const updatedFeatureNode: DrsNodeDto = this.buildUpdatedFeatureNode();
    const formData: FormData = this.buildFormData(updatedFeatureNode);

    this.loaderService.keepLoadingWhenCallIsDone(true);
    this.drsService.updateFeature(this.previewData.fullIdentifier, formData)
      .subscribe((data: FullId) => {

        this.selectedNode = updatedFeatureNode;
        this.refreshGraph(this.drsGraph.fullIdentifier);
        this.exitModeEditFeature();
        this.getFeatureDataForPreview(data.fullIdentifier);
      });
  }

  private buildUpdatedFeatureNode(): DrsNodeDto {
    const newData: DrsNodeDto = {
      ...this.selectedNode,
      data: this.editData,
      label: this.editData.name,
      children: this.editChildren
    };
    this.saveConditions(newData);
    return newData;
  }

  private buildFormData(node: DrsNodeDto): FormData {
    const formData: FormData = new FormData();
    if (this.image) {
      formData.append('image', this.image, this.image.name);
    }
    formData.append(
      'drsNodeDto',
      new Blob([JSON.stringify(node)], {
        type: 'application/json',
      }),
    );
    return formData;
  }

  private saveConditions(node: DrsNodeDto) {
    if (node.children === undefined || node.children.length === 0) {
      return;
    }
    this.drsConditionService.mapConditionsToNode(node);
  }

  refreshGraph(graphId: string) {
    this.drsService.getDrsGraph(graphId).subscribe((value: DrsGraph) => {

      this.replaceLocation(graphId);

      this.drsGraph = value;
      this.drsTreeData = [value.rootNode];

      if (this.selectedNode) {
        this.selectedNode = this.drsService.findNodeInDrsGraph(this.selectedNode.nodeId, this.drsGraph);
        if (this.selectedNode) {
          this.prepareNodeForEditing(this.selectedNode);
        } else {
          this.clearCurrentDataSelection();
          this.clearSelection();
        }
      }
    });
  }

  private replaceLocation(fullId: string) {
    if (this.drsGraph.fullIdentifier !== fullId) {
      this.location.replaceState(`/${fullId}`);
    }
  }

  onNodeUnselect() {
    this.clearCurrentDataSelection();
  }

  onRemoveRelation(node: DrsNodeDto) {
    if (node.id === null) {
      this.featureNodeClear(node);
    } else {
      this.loaderService.keepLoadingWhenCallIsDone(true);
      this.drsService.deleteDrsNode(this.drsGraph.fullIdentifier, node)
        .subscribe((data: FullId) => this.refreshGraph(data.fullIdentifier));
    }
  }

  private clearSelection() {
    this.selectedNode = null;
    this.isPreviewSidebarOpen = false;
    this.clearCurrentDataSelection();
  }

  private clearCurrentDataSelection() {
    this.editData = null;
    this.editChildren = null;
  }

  onRemoveChildClicked(childToRemoveIndex) {
    if (this.selectedNode.children[childToRemoveIndex]) {
      // In case it's saved node, ask the user to confirm the deletion
      const conditionToBeRemovedId = this.selectedNode.children[childToRemoveIndex].data.edge.conditionId;
      this.drsService.getDrsGraphNamesByConditionId(conditionToBeRemovedId).subscribe(
        graphOverviewDtos => {
          let message = null;
          if (graphOverviewDtos.length > 1) {
            const graphNames = '"' + graphOverviewDtos.map(graph => graph.label).join('" , "') + '"';
            message = this.translateService.instant('drs.edit.relation.delete.confirm.withGraphNames', {0: graphNames});
          } else {
            message = this.translateService.instant('drs.edit.relation.delete.confirm.withoutGraphNames');
          }

          this.confirmationService.confirm({
            message: message,
            accept: () => {
              this.editChildren.splice(childToRemoveIndex, 1);
              this.setCurrentChildrenToGraph();
            },
          });
        },
        error => {
          throw new Error(error);
        },
      );
    } else {
      // In case it's new node, just remove it from the editing form (deleting is not needed since it's not saved yet)
      this.editChildren.splice(childToRemoveIndex, 1);
    }
  }

  private setCurrentChildrenToGraph() {
    this.selectedNode.children = this.editChildren;
  }

  onAddAnswerClicked() {
    const answer = new DrsAnswerDtoImpl();
    answer.uuid = uuid();
    answer.versionUuid = uuid();
    this.editData.possibleAnswers.push(answer);
    this.onAnswerModified();
  }

  onRemoveAnswerIfPossible(index: number) {
    if (this.isAnswerUsedInConditions(this.editData.possibleAnswers[index].id)) {
      this.showAnswerIsUsedInConditionsPopup = true;
    } else {
      this.deleteConfirmationModal.showModal(
        () => this.editData.possibleAnswers.splice(index, 1)
      );
    }
  }

  private isAnswerUsedInConditions(answerId: number) {
    return this.editChildren
      // get flatten list of all conditions used in this node
      .reduce((a, b) => a.concat(b.conditions), [])
      // filter conditions from type ANSWER with specific answerId
      .filter((condition) => (answerId === condition.selectedValue.id && condition.selectedValue.type === ConditionType.ANSWER))
      // check if there are that kind of conditions
      .length > 0;
  }

  closeAnswerIsUsedInConditionsPopup() {
    this.showAnswerIsUsedInConditionsPopup = false;
  }

  onAnswerModified() {
    this.drsConditionService.possibleAnswerModified();
  }

  onAddEdgeClicked() {
    const createdItem = new DrsNodeDtoImpl();
    const createdEdge = new DrsEdgeDtoImpl();
    createdEdge.priority = 0;
    createdEdge.answers = [];
    createdEdge.classifications = [];
    createdEdge.conditionUuid = uuid();
    createdEdge.conditionVersionUuid = uuid();
    createdItem.nodeId = uuid();
    createdItem.data.edge = createdEdge;
    this.editChildren.push(createdItem);
  }

  featureNodeSelected(featureNode: DrsFeatureNodeDto, child: DrsNodeDto) {
    child.data.featureUuid = featureNode.uuid;
    child.data.name = featureNode.name;
    child.children = [];
    child.data.linkedGraphId = null;
  }

  linkedGraphSelected(graph: DrsGraph, child: DrsNodeDto) {
    child.data.linkedGraphId = graph.id;
    child.data.name = graph.label;
    child.children = [];
    child.data.featureUuid = null;
  }

  nodeTemplateSelected(graph: DrsGraph, child: DrsNodeDto) {
    const lastEdge = child.data.edge;
    this.drsService.duplicateNodeTemplate(graph.fullIdentifier).subscribe(data => {
      _.assign(child, data);
      child.data.edge = lastEdge;
      child.type = GraphTypeEnum.GRAPH_TEMPLATE;
    });
  }

  featureNodeClear(child: DrsNodeDto) {
    child.data.featureType = null;
    child.data.featureUuid = null;
    child.data.name = null;
    child.data.linkedGraphId = null;
  }

  onCreateFeatureStatusClicked(child: DrsNodeDto) {
    this.createFeature(child, FeatureType.STATUS);
  }

  onCreateFeatureDiagnosisClicked(child: DrsNodeDto) {
    this.createFeature(child, FeatureType.DIAGNOSIS);
  }

  onCreateFeatureProcedereClicked(child: DrsNodeDto) {
    this.createFeature(child, FeatureType.PROCEDURE);
  }

  onCreateFeatureChatClicked(child: DrsNodeDto) {
    this.createFeature(child, FeatureType.CHAT);
  }

  private createFeature(child: DrsNodeDto, featureType: FeatureType) {
    const newFeatureNode = new DrsNodeDtoImpl();
    newFeatureNode.data.featureUuid = uuid();
    newFeatureNode.data.featureVersionUuid = uuid();
    newFeatureNode.data.featureType = featureType;
    newFeatureNode.data.featureRepresentationType = FeatureRepresentationType.RADIO_BUTTON;
    newFeatureNode.data.name = this.translateService.instant('drs.initialNode.name', {
      0: this.buildConditionString(child.data.edge),
    });
    newFeatureNode.type = NodeType.FEATURE_NODE;

    child.data.featureUuid = newFeatureNode.data.featureUuid;
    child.data.name = newFeatureNode.data.name;

    this.drsService.createFeature(newFeatureNode).subscribe(data => _.noop);
  }

  onEditFeatureClicked() {
    this.enterModeEditFeature();
  }

  onEditFeatureCancelClicked() {
    this.exitModeEditFeature();
  }

  private enterModeEditFeature() {
    this.isEditFeatureMode = true;
  }

  private exitModeEditFeature() {
    this.isEditFeatureMode = false;
  }

  onDuplicateFeatureClicked() {
    this.confirmationService.confirm({
      header: this.translateService.instant('drs.graph.duplicateFeature.confirm.header'),
      message: this.translateService.instant('drs.graph.duplicateFeature.confirm'),
      accept: () => {
        this.loaderService.keepLoadingWhenCallIsDone(true);
        this.drsService.duplicateFeature(this.selectedNode.data.featureFullIdentifier).subscribe(
          value => {
            this.featureNodeSelected(value, this.selectedNode);
            this.editChildren = [];
            this.saveNode();
            this.clearCurrentDataSelection();
          },
          err => console.log(err),
        );
      },
    });
  }

  isFeatureNode(child: DrsNodeDto) {
    return child.type === NodeType.FEATURE_NODE;
  }

  buildConditionString(edgeDto: DrsEdgeDto) {
    const answerStrings = edgeDto.answers.map(answer => answer.description);
    const classificationsStrings = edgeDto.classifications.map(
      classification => classification.type + ' ' + classification.classification,
    );
    return answerStrings.concat(classificationsStrings).join(', ');
  }

  showClickableMap(possibleAnswer, editData) {
    this.displayClickableMap = true;
    // added changeDetectorRef because angular doesn't find the change of the map's height and width and they stay 0
    this.changeDetectorRef.detectChanges();
    this.drawingService.initDrawingOnClickableMap(
      this.clickableMap.nativeElement,
      possibleAnswer,
      this.clickableMapInitialized,
      editData,
    );
    this.clickableMapInitialized = true;
  }

  saveDataFromClickableMap() {
    this.drawingService.saveAllRectangles();
    this.hideClickableMap();
  }

  openClickableMap(possibleAnswer, editData) {
    if (this.imagePath) {
      this.showClickableMap(possibleAnswer, editData);
    }
  }

  hideClickableMap() {
    this.displayClickableMap = false;
  }

  changeMapImageUrl(event) {
    this.imagePath = event;
  }

  showNodeRevisions() {
    this.revisionModal.showRevisionModal();
    this.revisionModal.setRevisionData([]);

    const revisionModelTitleMessageParams = {
      0: this.selectedNode.data.featureUuid,
      1: this.selectedNode.nodeId
    };

    const title = this.translateService.instant(
      'drs.revisions.table.header.node.uuid',
      revisionModelTitleMessageParams
    );

    this.revisionModal.setModalTitle(title);

    this.auditingService.getNodeRevisions(this.selectedNode.id).subscribe((data: AuditRecordDto[]) => {
      data.forEach(item => {
        item.createdAt = moment(item.revisionDate).valueOf();
        item.revisionDate = moment(item.revisionDate).format('HH:mm:ss DD-MM-YYYY');
      });
      this.revisionModal.setRevisionData(data);
    });
  }

  toggleClickableMapImage(event, displayMode) {
    event.target.style.display = displayMode;
  }

  /**
   * Sets the UI mode based on the graph DRS version. If a graph with an older version is loaded,
   * the UI is set to read-only mode, meaning the user can only view the data but not modify it.
   * Graphs from the current DRS development version are editable as usual.
   *
   * @param version the DRS version of the graph
   */
  private handleGraphVersion(version: number) {
    // unsubscribe is needed in case we go from a current version to an older one (the page is
    // not refreshed when a different graph is loaded)
    this.drsEditorModeService.unsubscribeFromEditorModeChanges();
    this.drsEditorModeService.isVersionReadOnly(version).subscribe((isReadOnly: boolean) => {
      if (isReadOnly) {
        this.drsEditorModeService.setReadOnlyMode();
      } else {
        this.drsEditorModeService.resetState();
        this.drsEditorModeService.subscribeForEditorModeChanges();
      }
    });
  }

  private handleDataReload() {
    const subscription = this.drsEditorModeService.reloadData$.subscribe(() => {
      this.refreshGraph(this.drsGraph.fullIdentifier);
    });
    this.subscriptions.add(subscription);
  }
}

export interface DrsComponentResolveData {
  drsGraph: DrsGraph;
  appData: DrsAppDataDto;
}

export interface NodeSelectedEvent {
  node: DrsNodeDto;
}
