

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AppDataDto} from '../app-data/app-data';

/**
 * Service for general app operations.
 */
@Injectable()
export class AppCoreService {

  public static REST_BASE_URL = '/api';
  private static APP_DATA_PATH = '/app-data';
  public static LOGIN_PAGE_PATH = '/authentication.html';
  private appInfoUrl: string;

  constructor(private http: HttpClient) {
    this.appInfoUrl = AppCoreService.REST_BASE_URL + AppCoreService.APP_DATA_PATH;
  }

  /**
   * Gets app info.
   *
   * @return {Observable<AppDataDto>}
   */
  getAppData(browserLanguage: string): Observable<AppDataDto> {
    return this.http.get<AppDataDto>(`${this.appInfoUrl}/`, {'params': {'language': browserLanguage}});
  }

  /**
   * Redirects to login page.
   */
  redirectToLoginPage() {
    window.open(AppCoreService.LOGIN_PAGE_PATH, '_self'); // TODO MIS001MISA-361 use router
  }
}
