/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./card-image-registration-detail.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./card-image-registration-detail.component";
var styles_CardImageRegistrationDetailComponent = [i0.styles];
var RenderType_CardImageRegistrationDetailComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_CardImageRegistrationDetailComponent, data: {} });
export { RenderType_CardImageRegistrationDetailComponent as RenderType_CardImageRegistrationDetailComponent };
export function View_CardImageRegistrationDetailComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "card-image-container-registration"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "card-image-left-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [["class", "card-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "card-image-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [], [[8, "src", 4], [8, "alt", 0]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "card-inputs-right-container"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cardTitle; _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", (_co.FILE_PATH + _co.cardImgId), ""); var currVal_2 = i1.ɵinlineInterpolate(1, "", _co.cardTitle, ""); _ck(_v, 5, 0, currVal_1, currVal_2); }); }
export function View_CardImageRegistrationDetailComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-card-image-registration-detail", [], null, null, null, View_CardImageRegistrationDetailComponent_0, RenderType_CardImageRegistrationDetailComponent)), i1.ɵdid(1, 49152, null, 0, i2.CardImageRegistrationDetailComponent, [], null, null)], null, null); }
var CardImageRegistrationDetailComponentNgFactory = i1.ɵccf("app-card-image-registration-detail", i2.CardImageRegistrationDetailComponent, View_CardImageRegistrationDetailComponent_Host_0, { cardTitle: "cardTitle", cardImgId: "cardImgId" }, {}, ["*"]);
export { CardImageRegistrationDetailComponentNgFactory as CardImageRegistrationDetailComponentNgFactory };
