import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { ProgressSpinnerModule } from 'primeng/primeng';

import { BypassSecurityPipe } from '../bypass-security.pipe';
import { LoaderComponent } from './loader/loader.component';
import { LoaderService } from './loader/loader.service';
import { LoaderInterceptor } from './loader/loader.interceptor';
import { DeleteConfirmationModalComponent } from './delete-confirmation-modal/delete-confirmation-modal.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

@NgModule({
  imports: [
    TranslateModule,
    CommonModule,
    ProgressSpinnerModule,
    ConfirmDialogModule,
  ],
  declarations: [
    BypassSecurityPipe,
    LoaderComponent,
    DeleteConfirmationModalComponent,
  ],
  providers: [
    LoaderService,
    { provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    }
  ],
  exports: [
    TranslateModule,
    BypassSecurityPipe,
    LoaderComponent,
    DeleteConfirmationModalComponent,
  ]
})
export class SharedModule {
}
