import {DrsAnswerDto} from './drs-answer-dto';
import {DrsClassificationDto} from './drs-classification-dto';

export interface DrsEdgeDto {
  answers: DrsAnswerDto[];
  classifications: DrsClassificationDto[];
  id: number;
  priority: number;
  conditionId: number;
  conditionUuid: string;
  conditionVersionUuid: string;
}

export class DrsEdgeDtoImpl implements DrsEdgeDto {
  answers: DrsAnswerDto[];
  id: number;
  priority: number;
  classifications: DrsClassificationDto[];
  conditionId: number;
  conditionUuid: string;
  conditionVersionUuid: string;
}
