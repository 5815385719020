import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppCoreService } from '../../../core/services/app-core.service';
import { AppDataService } from '../../../core/app-data/app-data.service';
import * as _ from 'lodash';
import { Predicates } from '../../../core/util/observables';
import { filter } from 'rxjs/operators';
import { LoaderService } from '../../../shared/loader/loader.service';
var httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
    }),
};
/**
 * Service for drs graph operations.
 */
var DrsService = /** @class */ (function () {
    function DrsService(http, appDataService, loaderService) {
        this.http = http;
        this.appDataService = appDataService;
        this.loaderService = loaderService;
        this.NODE_PATH_SEGMENT = 'node';
        this.FEATURE_PATH_SEGMENT = 'feature';
        this.DUPLICATE_ROOT_NODE_PATH_SEGMENT = 'duplicate-root-node';
        this.CLONE_PATH_SEGMENT = 'clone';
        this.FILE_PATH_SEGMENT = 'file';
        this.VERSIONS_PATH = 'version';
        this.ROOT_NODE_PATH = 'rootNode';
        this.SEARCH_PATH_SEGMENT = 'search';
        this.CUT_PATH = 'cut';
        this.COPY_PATH = 'copy';
        this.drsBaseRestUrl = AppCoreService.REST_BASE_URL + DrsService.DRS_PATH;
        this.graphRestUrl = this.drsBaseRestUrl + '/' + DrsService.GRAPH_PATH;
    }
    /**
     * Get аll the versions
     *
     */
    DrsService.prototype.getVersions = function () {
        return this.http.get(this.drsBaseRestUrl + "/" + this.VERSIONS_PATH);
    };
    Object.defineProperty(DrsService.prototype, "selectedVersion", {
        get: function () {
            return this.appDataService.appData.defaultDrsVersion;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DrsService.prototype, "appInfoBaseRestUrl", {
        get: function () {
            return this.drsBaseRestUrl + '/' + this.selectedVersion + DrsService.APP_DATA_PATH;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Gets drs graphs.
     *
     * @param {string} type the id of the graph
     * @return {Observable<DrsGraph[]>}
     */
    DrsService.prototype.getDrsGraphs = function (type) {
        var params = new HttpParams().set('searchTerm=', "type:" + type + ",version:" + this.selectedVersion.toString());
        return this.http.get("" + this.graphRestUrl, { params: params });
    };
    /**
     * Gets drs graph names by condition id.
     *
     * @param {number} conditionId the id of the condition
     * @return {Observable<DrsGraphOverviewDto[]>}
     */
    DrsService.prototype.getDrsGraphNamesByConditionId = function (conditionId) {
        var params = new HttpParams().set('conditionId', conditionId.toString());
        return this.http.get(this.drsBaseRestUrl + "/" + DrsService.GRAPH_PATH, { params: params });
    };
    /**
     * Gets drs graph by id.
     *
     * @param {string} drsGraphId the id of the graph
     * @return {Observable<Order>}
     */
    DrsService.prototype.getDrsGraph = function (drsGraphId) {
        var url = this.graphRestUrl + "/" + drsGraphId;
        this.loaderService.setUrl(url);
        return this.http.get(url).pipe(filter(Predicates.noErrors));
    };
    /**
     * Duplicate node templates
     *
     * @param {number} graphFullId the full identifier of the graph
     * @return {DrsNodeDto}
     */
    DrsService.prototype.duplicateNodeTemplate = function (graphFullId) {
        return this.http.post(this.graphRestUrl + "/" + graphFullId + "/" + this.DUPLICATE_ROOT_NODE_PATH_SEGMENT, {});
    };
    /**
     * Deletes drs graph by id.
     *
     * @param graphFullId the full id of the graph
     * @return {Observable<any>}
     */
    DrsService.prototype.deleteDrsGraph = function (graphFullId) {
        return this.http.delete(this.graphRestUrl + "/" + graphFullId).pipe(filter(Predicates.noErrors));
    };
    /**
     * Create new graph.
     * @param newGraph the data for the new graph
     */
    DrsService.prototype.createDrsGraph = function (newGraph) {
        return this.http.post(this.drsBaseRestUrl + "/" + DrsService.GRAPH_PATH, newGraph);
    };
    /**
     * Edit existing graph.
     * @param {DrsGraph} drsGraphOverview
     */
    DrsService.prototype.updateDrsGraph = function (drsGraphOverview) {
        var body = {
            label: drsGraphOverview.label,
            type: drsGraphOverview.type
        };
        return this.http.put(this.graphRestUrl + "/" + drsGraphOverview.fullIdentifier, body).pipe(filter(Predicates.noErrors));
    };
    /**
     * Saves drs node and all nodes downward (save the tree below this node).
     *
     * @param graphFullId the id of the graph
     * @param drsNodeDto dto to be saved
     */
    DrsService.prototype.saveDrsNodeDownwards = function (graphFullId, drsNodeDto) {
        var url = this.graphRestUrl + "/" + graphFullId + "/" + this.NODE_PATH_SEGMENT;
        this.loaderService.setUrl(url);
        return this.http.post(url, drsNodeDto, httpOptions).pipe(filter(Predicates.noErrors));
    };
    /**
     * Deletes a node by that node's id
     *
     * @param graphFullId the full id of the related graph
     * @param drsNodeDto dto to be deleted
     */
    DrsService.prototype.deleteDrsNode = function (graphFullId, drsNodeDto) {
        var url = this.drsBaseRestUrl + "/graph/" + graphFullId + "/node/" + drsNodeDto.id;
        this.loaderService.setUrl(url);
        return this.http.delete(url).pipe(filter(Predicates.noErrors));
    };
    /**
     * Saves a drs feature.
     *
     * @param featureFullId the full id of the target feature
     * @param {FormData} formData node which contains the feature to be saved and the image
     */
    DrsService.prototype.updateFeature = function (featureFullId, formData) {
        var url = this.drsBaseRestUrl + "/" + this.FEATURE_PATH_SEGMENT + "/" + featureFullId;
        this.loaderService.setUrl(url);
        return this.http.put(url, formData).pipe(filter(Predicates.noErrors));
    };
    /**
     * Makes a call to the backend to cut the node matching the given id.
     *
     * @param graphFullId the full identifier of the graph where copy is done
     * @param nodeId the id of the node to cut
     * @param drsGraphOverviewDto the graph overview
     */
    DrsService.prototype.cutNodeTemplate = function (graphFullId, nodeId, drsGraphOverviewDto) {
        var url = this.drsBaseRestUrl + "/graph/" + graphFullId + "/node";
        var params = new HttpParams().set('cutFrom', nodeId.toString());
        this.loaderService.setUrl(url);
        return this.http.post(url, drsGraphOverviewDto, { params: params }).pipe(filter(Predicates.noErrors));
    };
    /**
     * Makes a call to the backend to copy the node matching the given id.
     *
     * @param graphFullId the full identifier of the graph where copy is done
     * @param nodeId the id of the node to be copied
     * @param drsGraphOverviewDto the graph overview
     */
    DrsService.prototype.copyNodeTemplate = function (graphFullId, nodeId, drsGraphOverviewDto) {
        var params = new HttpParams().set('copyFrom', nodeId.toString());
        return this.http.post(this.drsBaseRestUrl + "/graph/" + graphFullId + "/node", drsGraphOverviewDto, { params: params });
    };
    /**
     * Create a drs feature.
     *
     * @param {DrsNodeDto} drsNodeDto node which contains the feature to be saved
     */
    DrsService.prototype.createFeature = function (drsNodeDto) {
        var body = {
            drsVersion: this.selectedVersion,
            node: drsNodeDto
        };
        return this.http.post(this.drsBaseRestUrl + "/" + this.FEATURE_PATH_SEGMENT, body, httpOptions);
    };
    /**
     * Duplicate drs version.
     */
    DrsService.prototype.cloneVersion = function () {
        return this.http.post(this.drsBaseRestUrl + "/" + this.selectedVersion + "/" + this.CLONE_PATH_SEGMENT, httpOptions);
    };
    /**
     * Upload image.
     */
    DrsService.prototype.uploadImage = function (data) {
        return this.http.post(this.drsBaseRestUrl + "/" + this.FILE_PATH_SEGMENT, data);
    };
    /**
     * Get feature by id.
     */
    DrsService.prototype.getFeatureById = function (featureFullId) {
        return this.http.get(this.drsBaseRestUrl + "/" + this.FEATURE_PATH_SEGMENT + "/" + featureFullId);
    };
    /**
     * Gets app info.
     *
     * @return {Observable<DrsAppDataDto>}
     */
    DrsService.prototype.getDrsAppData = function () {
        return this.http.get("" + this.appInfoBaseRestUrl);
    };
    /**
     * Create feature duplicate.
     *
     * @param featureId the feature id
     * @return {Observable<DrsFeatureNodeDto>}
     */
    DrsService.prototype.duplicateFeature = function (featureId) {
        var url = this.drsBaseRestUrl + "/" + this.FEATURE_PATH_SEGMENT + "?duplicateFrom=" + featureId;
        this.loaderService.setUrl(url);
        return this.http.post(url, null, httpOptions);
    };
    DrsService.prototype.findNodeInDrsGraph = function (nodeId, drsGraph) {
        // node stack
        var stack = [];
        // keeps the visited nodeIds
        var visited = [];
        if (drsGraph.rootNode.nodeId === nodeId) {
            // the node is found
            return drsGraph.rootNode;
        }
        stack.push(drsGraph.rootNode);
        while (stack && stack.length !== 0) {
            var topNode = stack[stack.length - 1];
            var unvisitedChildNode = this.getUnvisitedChildNode(topNode, visited);
            if (unvisitedChildNode !== undefined) {
                if (unvisitedChildNode.nodeId === nodeId) {
                    // the node is found
                    return unvisitedChildNode;
                }
                stack.push(unvisitedChildNode);
                visited.push(unvisitedChildNode.nodeId);
            }
            else {
                stack.pop();
            }
        }
        return null;
    };
    DrsService.prototype.getUnvisitedChildNode = function (node, visited) {
        var e_1, _a;
        try {
            for (var _b = tslib_1.__values(node.children), _c = _b.next(); !_c.done; _c = _b.next()) {
                var childNode = _c.value;
                if (!_.includes(visited, childNode.nodeId)) {
                    return childNode;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return undefined;
    };
    DrsService.DRS_PATH = '/drs';
    DrsService.APP_DATA_PATH = '/app-data';
    DrsService.GRAPH_PATH = 'graph';
    DrsService.FILE_PATH = AppCoreService.REST_BASE_URL + DrsService.DRS_PATH + '/file/';
    return DrsService;
}());
export { DrsService };
