<div class="health-insurance-table-container">
  <p-table [value]="healthInsuranceCards" class="health-insurance-table"
           [responsive]="true" sortField="status"
           [customSort]="true"
           (sortFunction)="sortHICTable($event)"
           [paginator]="true"
           [rows]="20"
           [alwaysShowPaginator]="true">

    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="dateTimestamp">
          {{'health.insurance.table.date.header' | translate}}
          <p-sortIcon field="dateTimestamp"></p-sortIcon>
        </th>
        <th pSortableColumn="status">
          {{'health.insurance.table.status.header' | translate}}
          <p-sortIcon field="status"></p-sortIcon>
        </th>
        <th pSortableColumn="firstName">
          {{'health.insurance.table.firstName.header' | translate}}
          <p-sortIcon field="firstName"></p-sortIcon>
        </th>
        <th pSortableColumn="lastName">
          {{'health.insurance.table.lastName.header' | translate}}
          <p-sortIcon field="lastName"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-hic>
      <tr (click)="onRowSelected(hic)">
        <td>{{hic.createdOn}}</td>
        <td>{{hic.status}}</td>
        <td>{{hic.firstName}}</td>
        <td>{{hic.lastName}}</td>
      </tr>
    </ng-template>
  </p-table>
</div>
