import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserIdentificationService } from '../drs/shared/services/user-identification-service';
import { SingleRegistrationDataDto } from '../drs/shared/model/registrations/single-registration-data-dto';

/**
 * Resolves the registration object.
 */
@Injectable()
export class RegistrationResolverService implements Resolve<any> {

  constructor(private userIdentificationService: UserIdentificationService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<SingleRegistrationDataDto> | Promise<SingleRegistrationDataDto> {
    return this.userIdentificationService.getRegistrationById(route.params.id);
  }
}
