<div class="tab-menu-mobile-chat">
  <ul class="tab-list-chat">
    <li class="tab-item" (click)="activateWaitingRoomTab()" [ngClass]="{'tab-item-active': waitingRoomTabActive}">
      <a href="#" class="tab-item-text"> {{'waitingRoom.tab.label' | translate}} </a>
    </li>
    <li class="tab-item" (click)="activateChatTab()" [ngClass]="{'tab-item-active': chatTabActive}">
      <a href="#" class="tab-item-text"> {{'chat.tab.label' | translate}} </a>
    </li>
  </ul>
</div>
<app-waiting-room (selectedPatient)="onSelectPatient($event)"
                  (finishedConversation)="onFinishConversation($event)"
                  [conversationFinishedSuccessfully]="conversationFinishedSuccessfully"
                  [messageReceived]="messageReceived"
                  (restartChatPanel)="restartChatPanel()"
                  [activeClass]="waitingRoomTabActive"
                  (setNewPatient)="setNewPatient($event)"></app-waiting-room>

<div class="chat-container">
  <div class="chat-inner-warapper">
    <div style="display: flex;">
      <app-chat-header-timer [patient]="currentPatient"></app-chat-header-timer>
      <button pButton type="button" class="ui-button-primary consultation-btn" (click)="showConsultationPanel(true)"
              icon="pi pi-comments"></button>
    </div>
    <div class="chat" #chatWindow>
      <ul>
        <li *ngFor="let msg of allMessages"
            [ngClass]="{'left-message': currentUser && msg.createdBy === currentUser._id,
            'image-message': msg.attachment}">
        <span class="username-message" *ngIf="currentUser && msg.createdBy === currentUser._id">{{ currentUserName || ''}}</span>
        <span class="timestamp-message">{{msg.createdAt}}</span>
          {{msg.message}}
          <img src="{{msg.attachment.url}}" *ngIf="msg.attachment" alt="{{msg.attachment.name}}" />
        </li>
      </ul>
    </div>
    <div class="chat-input-controls-container" *ngIf="!chatInputHidden">
      <div class="chat-input-controls">
        <p-fileUpload #chatImageUpload
                      [disabled]="chatInputsDisabled"
                      mode="basic"
                      name="message-image"
                      accept=".png,.jpeg,.jpg,.gif,.bmp,.heif,.heic,.heif-sequence,.heic-sequence"
                      invalidFileTypeMessageSummary="{{'document.upload.toastMessage.invalidType.summary' | translate}}"
                      [maxFileSize]="maxUploadFileSizeBytes"
                      customUpload="true"
                      (uploadHandler)="onSendImage($event)"
                      auto="true">
        </p-fileUpload>
        <input id="message-input" [(ngModel)]="message" type="text" pInputText [disabled]="chatInputsDisabled"
               (keyup)="onMessageBoxKeyUp($event)" placeholder="{{'chat.textField.placeholder' | translate}}">
        <button style="padding: 5px" type="button" pButton label="{{'btn.chat.send' | translate}}"
                [disabled]="chatInputsDisabled" (click)="sendMessage()"></button>
      </div>
    </div>
  </div>

  <app-consultation-panel
    class="consultation-wrapper"
    [ngClass]="{'show': isConsultationPanelVisible}"
    [consultation]="currentConsultation"
    [freeChatCheckboxEnabled]="canActivateFreeChat"
    (closeConsultationPanel)="showConsultationPanel($event)"
    (freeChatChange)="onFreeChatCheckChanged($event)">
  </app-consultation-panel>
</div>

