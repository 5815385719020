import {Component, OnInit} from '@angular/core';
import {HealthInsuranceCardService} from '../drs/shared/services/health-insurance-card-service';
import {Router} from '@angular/router';
import {HealthInsuranceCardOverview} from '../drs/shared/model/health-insurance-card/health-insurance-card-overview';
import {HealthInsuranceCardStatus} from '../drs/shared/model/health-insurance-card/health-insurance-card-status';
import {sortTable} from '../utils/sort';
import {formatStringToDate, getDateTimestamp} from '../utils/date';

@Component({
  selector: 'app-health-insurance-card-overview',
  templateUrl: './health-insurance-card-overview.component.html',
  styleUrls: ['./health-insurance-card-overview.component.scss']
})
export class HealthInsuranceCardOverviewComponent implements OnInit {

  healthInsuranceCards: HealthInsuranceCardOverview[];

  private formatString = 'HH:mm DD-MM-YYYY';

  constructor(private healthInsuranceCardService: HealthInsuranceCardService, private router: Router) {}

  ngOnInit(): void {
    this.healthInsuranceCardService.getAllCards().subscribe(response => {
      response.cards.forEach(card => {
        card.dateTimestamp = getDateTimestamp(card.createdOn);
        card.createdOn = formatStringToDate(card.createdOn, this.formatString);

        return card;
      });

      this.healthInsuranceCards = response.cards;
    });
  }

  onRowSelected(data: HealthInsuranceCardOverview) {
    this.router.navigate(['health-insurance-card/' + data.id]);
  }

  sortHICTable(event) {
    const statusSortOrder =
      [HealthInsuranceCardStatus.REQUESTED, HealthInsuranceCardStatus.ACCEPTED, HealthInsuranceCardStatus.REJECTED];

    sortTable(event, statusSortOrder);
  }
}
