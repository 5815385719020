<div class="registrations-table-container">
  <p-table [value]="registrationsData" class="registrations-table"
           [responsive]="true" sortField="status"
           [customSort]="true"
           (sortFunction)="sortRegistrationsTable($event)"
           [paginator]="true"
           [rows]="20"
           [alwaysShowPaginator]="false">

    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="dateTimestamp">
          {{'registrations.table.date.header' | translate}}
          <p-sortIcon field="dateTimestamp"></p-sortIcon>
        </th>
        <th pSortableColumn="status">
          {{'registrations.table.status.header' | translate}}
          <p-sortIcon field="status"></p-sortIcon>
        </th>
        <th pSortableColumn="firstName">
          {{'registrations.table.firstName.header' | translate}}
          <p-sortIcon field="firstName"></p-sortIcon>
        </th>
        <th pSortableColumn="lastName">
          {{'registrations.table.lastName.header' | translate}}
          <p-sortIcon field="lastName"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-registration>
      <tr (click)="onRowSelected(registration)">
        <td>{{registration.createdAt}}</td>
        <td>{{registration.status}}</td>
        <td>{{registration.firstName}}</td>
        <td>{{registration.lastName}}</td>
      </tr>
    </ng-template>
  </p-table>
</div>
