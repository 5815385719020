import * as _ from 'lodash';
import { Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { SkygearService } from '../../skygear.service';
import { ChatService } from '../../drs/shared/services/chat.service';
import { Conversation, ConversationState } from '../../drs/shared/model/chat/conversation';
import { Patient } from './patient';
import { SkygearConversation, SkygearHelper, SkygearMessage } from '../../drs/shared/model/chat/skygear-model';
import * as moment from 'moment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-waiting-room',
  templateUrl: './waiting-room.component.html',
  styleUrls: ['./waiting-room.component.scss'],
})
export class WaitingRoomComponent implements OnInit, OnDestroy {

  @Output() selectedPatient = new EventEmitter<Patient>();
  @Output() finishedConversation = new EventEmitter<Patient>();
  @Output() setNewPatient = new EventEmitter<Patient>();
  @Output() restartChatPanel = new EventEmitter<void>();

  @Input() messageReceived: EventEmitter<SkygearMessage>;
  @Input() activeClass: boolean;
  @Input() conversationFinishedSuccessfully;

  currentPatient: Patient;
  currentUserId: string;

  patientsWaiting: Patient[]; // this is list of filtered patients that are only in the selected tab -> filterPatients()
  allPatientsWaiting: Patient[]; // this is list of all patients that are listed in all 3 tabs
  refreshConversationsInterval = 10000;
  interval: any;
  private lastResponseOfConversationDtos: Conversation[] = [];
  isInitialRequest = true;

  buttonFilters;
  FILTER_OPEN = [ConversationState.WAITING_FOR_DOCTOR];
  FILTER_MINE = [
    ConversationState.ONGOING,
    ConversationState.WAITING_FOR_PATIENT,
    ConversationState.FINISHED_BY_PATIENT,
  ];
  FILTER_COMPLETED = [
    ConversationState.DONE,
    ConversationState.DECLINED
  ];
  ACTIVE_FILTER_CLASS = 'active-filter';
  currentPatientFilter: ConversationState[];
  OPEN_BUTTON_INDEX = 0;
  MINE_BUTTON_INDEX = 1;

  countOpenConversations: number;
  countMineConversations: number;
  countCompletedConversations: number;

  @ViewChild('filterTopBar', { static: true }) filterTopBar;

  private subscriptions: Subscription;

  constructor(private skygearService: SkygearService, private chatService: ChatService,  private ngZone: NgZone) {}

  ngOnInit() {
    this.subscriptions = this.skygearService.skygearUserLoggedIn.subscribe(() => {
      this.currentUserId = this.skygearService.getCurrentUser()._id;

      // Check for new chats immediately and than at intervals
      this.getAllConversations();
      this.interval = setInterval(() => {
        this.getAllConversations();
      }, this.refreshConversationsInterval);
    });

    if (this.messageReceived) {
      const messageReceivedSubscription = this.messageReceived.subscribe(message => this.onMessageReceived(message));
      this.subscriptions.add(messageReceivedSubscription);
    }

    const conversationFinishedSubscription = this.conversationFinishedSuccessfully.subscribe(() => {
      this.getAllConversations(
        () => this.toggleFilter(this.buttonFilters[this.OPEN_BUTTON_INDEX], this.FILTER_OPEN));
    });
    this.subscriptions.add(conversationFinishedSubscription);

    this.buttonFilters = this.filterTopBar.nativeElement.querySelectorAll('.filter-buttons');
  }

  ngOnDestroy() {
    clearInterval(this.interval);
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  getAllConversations(then?: () => void) {
    if (!this.currentUserId) {
      return;
    }
    // Make the code with asynchronous tasks reenter the Angular zone.
    // Executes it synchronously within the Angular zone and returns value returned by the subscription.
    this.ngZone.run( () => {
      this.chatService.getConversations(this.currentUserId).subscribe(conversationDtos => {
        if (this.somethingChangedSinceLastRefresh(conversationDtos)) {
          console.log('[WaitingRoom] Conversations state changed, re-adding all patients');
          this.addPatientFromConversation(conversationDtos);
          this.lastResponseOfConversationDtos = conversationDtos;
        }
        if (then) {
          then();
        }
      }, err => {
        console.error('[WaitingRoom] Failed to retrieve conversations for user', this.currentUserId, err);
      });
    });
  }

  private somethingChangedSinceLastRefresh(conversationDtos: Conversation[]): boolean {
    if (conversationDtos.length === 0 || conversationDtos.length !== this.lastResponseOfConversationDtos.length) {
      return true;
    }
    return !_.isEmpty(_.differenceWith(conversationDtos.sort(), this.lastResponseOfConversationDtos.sort(), _.isEqual));
  }

  private addPatientFromConversation(conversations: Conversation[]): void {
    this.allPatientsWaiting = [];
    conversations.forEach(conversation => {
      if (conversation.patientChatUserId === this.currentUserId) {
        return;
      }

      const patient = new Patient();
      patient.name = conversation.patientUsername;
      patient.misantoConversation = conversation;
      patient.waitingTime = this.getWaitingTime(patient.misantoConversation.createdAt);
      this.allPatientsWaiting.push(patient);
    });
    this.setCurrentPatient();

    this.allPatientsWaiting = _.orderBy(
      this.allPatientsWaiting,
      ['misantoConversation.conversationState', 'misantoConversation.createdAt'],
      ['desc', 'asc'],
    );
    this.filterPatients();
    this.setCountsOfFilteredPatients();

    if (this.isInitialRequest) {
      this.toggleFilter(this.buttonFilters[this.OPEN_BUTTON_INDEX], this.FILTER_OPEN);
      this.loadUnreadCounts(this.patientsWaiting);
    }
  }

  private loadUnreadCounts(patients: Patient[]) {
    this.isInitialRequest = false;
    for (const patient of patients) {
      if (patient.isInStateOngoing()) {
        this.skygearService.getConversation(patient.misantoConversation.chatServerConversationId)
          .then((skygearConversation: SkygearConversation) => {
            patient.unreadMessageCount = skygearConversation.unread_count;
          })
          .catch(err => {
            console.error('[WaitingRoom] Failed to get conversation',
              patient.misantoConversation.chatServerConversationId, err);
          });
      }
    }
  }

  private getWaitingTime(timestamp: string): string {
    return moment(timestamp).fromNow();
  }

  onSelectPatient(selectedPatient: Patient) {
    selectedPatient.unreadMessageCount = 0;
    if (selectedPatient.isInStateWaiting()) {
      selectedPatient.misantoConversation.conversationState = ConversationState.WAITING_FOR_PATIENT;
      this.toggleFilter(this.buttonFilters[this.MINE_BUTTON_INDEX], this.FILTER_MINE);
      this.setCountsOfFilteredPatients();
    }
    this.currentPatient = selectedPatient;
    this.selectedPatient.emit(selectedPatient);
  }

  private onMessageReceived(message: SkygearMessage) {
    if (message.record_type === 'message' && message.event_type === 'create') {
      const targetPatient = this.allPatientsWaiting.find(
        patient =>
          patient.misantoConversation.chatServerConversationId === SkygearHelper.getCoreId(message.record.conversation),
      );
      if (targetPatient) {
        targetPatient.unreadMessageCount++;
      }
    }
  }

  onFinishConversation(patient: Patient) {
    this.finishedConversation.emit(patient);
  }

  toggleFilter(clickedFilter, filterType) {
    console.log('[WaitingRoom] toggleFilter', filterType[0]);

    this.currentPatient = null;
    this.restartChatPanel.emit();

    if (!this.patientsWaiting || !this.allPatientsWaiting || this.allPatientsWaiting.length === 0) {
      return;
    }
    this.currentPatientFilter = filterType;

    // change active filter's background color
    this.buttonFilters.forEach(button => {
      if (clickedFilter !== button) {
        button.classList.remove(this.ACTIVE_FILTER_CLASS);
      }
    });
    clickedFilter.classList.add(this.ACTIVE_FILTER_CLASS);

    this.filterPatients();
  }

  filterPatients() {
    this.patientsWaiting = this.allPatientsWaiting.filter(patient => {
      return patient.isInGivenState(this.currentPatientFilter);
    });

    if (this.currentPatientFilter === this.FILTER_COMPLETED || this.currentPatientFilter === this.FILTER_MINE) {
      this.patientsWaiting = _.orderBy(this.patientsWaiting, 'misantoConversation.createdAt', 'desc');
    }
  }

  setCurrentPatient() {
    if (!this.currentPatient) {
      return;
    }

    this.currentPatient = this.allPatientsWaiting.find(patient => {
      return patient.misantoConversation.id === this.currentPatient.misantoConversation.id;
    });

    if (!this.currentPatient) {
      return;
    }

    /* Solved the edge case with backend still returning patient with state WAITING_FOR_DOCTOR
     (Reason: The claim request and the polling request happen at the same time) */
    if (this.currentPatient.isInStateWaitingForDoctor()) {
      this.currentPatient.misantoConversation.conversationState = ConversationState.WAITING_FOR_PATIENT;
    }
    this.setNewPatient.emit(this.currentPatient);
  }

  setCountsOfFilteredPatients() {
    if (this.allPatientsWaiting) {
      this.countOpenConversations = this.allPatientsWaiting.filter(patient => {
        return patient.isInGivenState(this.FILTER_OPEN);
      }).length;

      this.countCompletedConversations = this.allPatientsWaiting.filter(patient => {
        return patient.isInGivenState(this.FILTER_COMPLETED);
      }).length;

      this.countMineConversations = this.allPatientsWaiting.filter(patient => {
        return patient.isInGivenState(this.FILTER_MINE);
      }).length;
    }
  }
}
