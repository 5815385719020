import { Conversation, ConversationState } from '../../drs/shared/model/chat/conversation';

/**
 * A temporary model of the patient for this component.
 */
export class Patient {
  public name: string;
  public misantoConversation: Conversation;
  public waitingTime: string;
  public image: string;
  public unreadMessageCount = 0;

  isInStateWaiting() {
    return this.misantoConversation.conversationState === ConversationState.WAITING_FOR_DOCTOR;
  }

  isInStateOngoing() {
    return this.misantoConversation.conversationState === ConversationState.ONGOING;
  }

  isInStateFinishedByPatient() {
    return this.misantoConversation.conversationState === ConversationState.FINISHED_BY_PATIENT;
  }

  isInStateDone() {
    return this.misantoConversation.conversationState === ConversationState.DONE;
  }

  isInStateWaitingForPatient() {
    return this.misantoConversation.conversationState === ConversationState.WAITING_FOR_PATIENT;
  }

  isInStateWaitingForDoctor() {
    return this.misantoConversation.conversationState === ConversationState.WAITING_FOR_DOCTOR;
  }

  isInStateDeclined() {
    return this.misantoConversation.conversationState === ConversationState.DECLINED;
  }

  isInGivenState(states: ConversationState[]): boolean {
    if (!states) {
      return;
    }

    let patientIsInCurrentState = false;
    states.forEach(stateItem => {
      if (this.misantoConversation.conversationState === stateItem) {
        patientIsInCurrentState = true;
      }
    });
    return patientIsInCurrentState;
  }
}
