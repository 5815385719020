import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {PatientDetailsDto} from '../model/patients/patient-details-dto';
import {HttpClient} from '@angular/common/http';
import {distinctUntilChanged, map} from 'rxjs/operators';


/**
 * Model that is used as a wrapper for the patient details.
 */
export interface PatientDetailsWrapper {
  id: number;
  patientDetails: PatientDetailsDto;
}

/**
 * A service for getting patient details.
 */
@Injectable()
export class PatientDetailsHeaderStore {

  constructor(private http: HttpClient) {
  }

  private readonly patientWrapper = new BehaviorSubject<PatientDetailsWrapper>({
    id: null,
    patientDetails: null
  });

  patient = this.patientWrapper.asObservable().pipe(
    map(patientWrapper => patientWrapper.patientDetails),
    distinctUntilChanged());

  /**
   * Assigning a value to the patient wrapper.
   */
  public showPatientDetails(id: number): void {

    if (this.patientWrapper.getValue().id !== id) {
      this.http.get<PatientDetailsDto>(`/api/patient/${id}`).subscribe(data => {

        const value = {
          id: id,
          patientDetails: data
        };

        this.patientWrapper.next(value);
      });
    }
  }

  public getPatientId(): number {
    if (this.patientWrapper.getValue().id !== null) {
      return this.patientWrapper.getValue().id;
    }
    return null;
  }
}
