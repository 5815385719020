import {DocumentCategoryEnum, DocumentStatusEnum} from '../../drs/shared/model/patients/document-detail-dto';

/**
 * Represents a binding model for the document upload form.
 */
export class DocumentUploadFormModel {
  status: DocumentStatusEnum;
  category: DocumentCategoryEnum;
  date: Date;
  doctorFirstName: string;
  doctorLastName: string;
  comment: string;
  description: string;
}
