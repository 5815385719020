/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./health-insurance-card-image-details.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./health-insurance-card-image-details.component";
var styles_HealthInsuranceCardImageDetailsComponent = [i0.styles];
var RenderType_HealthInsuranceCardImageDetailsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HealthInsuranceCardImageDetailsComponent, data: {} });
export { RenderType_HealthInsuranceCardImageDetailsComponent as RenderType_HealthInsuranceCardImageDetailsComponent };
export function View_HealthInsuranceCardImageDetailsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "health-insurance-card-image-container-registration"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "p", [["class", "card-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "card-image-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("healthInsuranceCard.back.header.label")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.createImageUrl(), ""); _ck(_v, 5, 0, currVal_1); }); }
export function View_HealthInsuranceCardImageDetailsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-health-insurance-card-image", [], null, null, null, View_HealthInsuranceCardImageDetailsComponent_0, RenderType_HealthInsuranceCardImageDetailsComponent)), i1.ɵdid(1, 49152, null, 0, i3.HealthInsuranceCardImageDetailsComponent, [], null, null)], null, null); }
var HealthInsuranceCardImageDetailsComponentNgFactory = i1.ɵccf("app-health-insurance-card-image", i3.HealthInsuranceCardImageDetailsComponent, View_HealthInsuranceCardImageDetailsComponent_Host_0, { cardImgId: "cardImgId" }, {}, []);
export { HealthInsuranceCardImageDetailsComponentNgFactory as HealthInsuranceCardImageDetailsComponentNgFactory };
