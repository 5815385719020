<span class="ui-float-label input-box ui-fluid">
  <label for="condition-image-input">{{'drs.feature.add.image.label' | translate}}</label>
  <div class="wrapper-img-button">
    <input [disabled]="disabled" (change)="onFileUploaded($event);" id="condition-image-input"
           type="file" #fileInput pInputText accept="{{allowedFileType}}"/>
    <button  pButton type="button" class="ui-button-raised ui-button-primary red-btn" (click)="onDeleteImage()"
             [disabled]="disabled" icon="pi pi-trash" title="{{'btn.deleteImage' | translate}}"></button>
  </div>
  <span class="condition-image-preview">
    <!--isImage is only used when the user uploads an image, otherwise the image/pdf is shown in an <object> by default-->
    <img [hidden]="!imagePath" *ngIf="isImage" [src]="imagePath" alt="{{'drs.img.preview.alt' | translate}}" />
    <object [hidden]="!imagePath" *ngIf="!isImage" class="pdf-viewer" [data]="imagePath | bypassSecurity" type="image/png">
      <p>{{'apip.header.pdf.viewer.error.message' | translate}}</p>
      <a href="{{imagePath}}">{{'apip.header.pdf.viewer.error.link.label' | translate}}</a>
    </object>
  </span>
</span>
