import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'bypassSecurity',
})
/**
 * Bypass security and trust the given value to be a safe resource URL, i.e. a location that may
 * be used to load executable code from, like '<object data>', '<script src>' or '<iframe src>'.
 *
 * **WARNING:** calling this method with untrusted user data exposes the application to XSS
 * security risks!
 */
export class BypassSecurityPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(url): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
