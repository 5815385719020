import { Component, Input } from '@angular/core';
import { UserDetailsDto } from '../../../drs/shared/model/chat/user-details-dto';

@Component({
  selector: 'app-chat-user-details',
  templateUrl: './chat-user-details.component.html',
  styleUrls: ['./chat-user-details.component.scss']
})

export class ChatUserDetailsComponent {
  @Input() patientDetails?: UserDetailsDto;
}
