export interface Condition {
  name: string;
  type: string;
  id: number;
  uuid?: string;
}

export class DrsConditionImpl implements Condition {

  public name: string;
  public type: string;
  public id: number;
  public uuid?: string;

  public constructor(id: number, name: string, type: string ) {
    this.id = id;
    this.name = name;
    this.type = type;
  }
}
