import * as _ from 'lodash';
import {EventEmitter, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {DrsService} from '../shared/services/drs.service';
import {DrsGraph} from '../shared/model/drs-graph';

/**
 * Resolves the drs graph object.
 */
@Injectable()
export class DrsGraphResolverService implements Resolve<DrsGraph> {
  graphResolved: EventEmitter<DrsGraph> = new EventEmitter<DrsGraph>();

  constructor(private drsService: DrsService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<DrsGraph> | Promise<DrsGraph> | DrsGraph {
    const graphId = route.paramMap.get('id');
    return this.drsService.getDrsGraph(graphId);
  }
}
