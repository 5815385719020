import * as tslib_1 from "tslib";
import { TranslateService } from '@ngx-translate/core';
import { AppDataService } from './app-data/app-data.service';
import { AppCoreService } from './services/app-core.service';
import * as moment from 'moment';
/**
 * Service to bootstrap the application.
 */
var BootstrapService = /** @class */ (function () {
    function BootstrapService(appDataService, appCoreService, translateService) {
        this.appDataService = appDataService;
        this.appCoreService = appCoreService;
        this.translateService = translateService;
    }
    /**
     * Bootstraps the application with needed server data.
     */
    BootstrapService.prototype.bootstrapApp = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var browserLanguage, appData;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        browserLanguage = this.translateService.getBrowserLang();
                        return [4 /*yield*/, this.loadAppData(browserLanguage)];
                    case 1:
                        appData = _a.sent();
                        this.appDataService.appData = appData;
                        this.initializeTranslateService(appData, browserLanguage);
                        this.initializeLibraries(browserLanguage);
                        return [2 /*return*/];
                }
            });
        });
    };
    BootstrapService.prototype.loadAppData = function (browserLanguage) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.appCoreService.getAppData(browserLanguage).toPromise()];
            });
        });
    };
    BootstrapService.prototype.initializeTranslateService = function (appData, browserLanguage) {
        this.translateService.use(browserLanguage);
        this.translateService.setTranslation(browserLanguage, this.prepareTranslations(appData.texts));
    };
    BootstrapService.prototype.initializeLibraries = function (browserLanguage) {
        moment.locale(browserLanguage);
    };
    /**
     * Prepares translations to be used on the UI with the translate module, eg. replaces {0} with {{0}}.
     * This method can be removed as soon as NCA Trema uses {{0}} instead of {0}
     *
     * @param { [key: string]: string } translations the translations
     * @return { [key: string]: string } prepared translations
     */
    BootstrapService.prototype.prepareTranslations = function (translations) {
        Object.keys(translations).forEach(function (key) {
            var value = translations[key];
            translations[key] = value.replace(/({)(\d)(})/g, '$1{$2}$3');
        });
        return translations;
    };
    return BootstrapService;
}());
export { BootstrapService };
