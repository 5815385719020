/**
 * Defines the backend API paths.
 */
export const backendPaths = {
  apiBase: '/api',
  drs: {
    base: '/drs',
  },
  patientCockpit: {
    patientDocumentFile: '/api/patient/{patientId}/document-file/'
  }
};
