import * as _ from 'lodash';
import {
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import * as moment from 'moment';
import {ChatService} from '../drs/shared/services/chat.service';
import {SkygearService} from '../skygear.service';
import {
  SkygearConversation,
  SkygearHelper,
  SkygearMessage,
  SkygearMessageRecord,
  SkygearUser,
} from '../drs/shared/model/chat/skygear-model';
import { Patient } from './waiting-room/patient';
import { ConsultationWithConversation } from '../drs/shared/model/chat/consultation';
import { FinishChatDto } from '../drs/shared/model/chat/finish-chat-dto';
import { TranslateService } from '@ngx-translate/core';
import { MisantoSecurityService } from '../misanto-security.service';
import { ChatMessage, ChatMessageAttachment, ChatMessageType } from './model/chat-message';
import {
  ConversationMessage,
  ConversationMessageType,
  ConversationState
} from '../drs/shared/model/chat/conversation';
import { Subscription } from 'rxjs';

const END_CHAT_MESSAGE = 'End chat';
const END_CHAT_TYPE = 'end-chat';
const ENTER_KEY_CODE = 13;

@Component({
  selector: 'app-live-chat',
  templateUrl: './live-chat.component.html',
  styleUrls: ['./live-chat.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LiveChatComponent implements OnInit, OnDestroy {

  @Output() conversationFinishedSuccessfully = new EventEmitter();

  currentUser;
  currentUserName: string;

  currentConversation: SkygearConversation;
  currentConsultation: ConsultationWithConversation;
  currentPatient: Patient;

  message = '';
  allMessages: ChatMessage[] = [];
  messageReceived = new EventEmitter<SkygearMessage>();

  CHAT_INITIAL_MESSAGE: string = this.translateService.instant('chat.initial.message.text');
  CHAT_SECOND_MESSAGE: string = this.translateService.instant('chat.second.message.text');
  USER_DOES_NOT_EXIST_CODE = 110;
  PUSH_NOTIFICATION_SETUP;

  maxUploadFileSizeBytes = 10000000;

  isConsultationPanelVisible = false;
  chatInputsDisabled = true;
  chatInputHidden = false;
  canActivateFreeChat = false;
  chatTabActive = false;
  waitingRoomTabActive = true;

  private subscriptions = new Subscription();

  @ViewChild('chatImageUpload', { static: false }) chatImageUpload;
  @ViewChild('chatWindow', { static: true }) private chatWindow: ElementRef;

  constructor(
    private skygearService: SkygearService,
    private misantoChatService: ChatService,
    private translateService: TranslateService,
    private misantoSecurityService: MisantoSecurityService,
  ) {}

  ngOnInit() {
    this.skygearService
      .initSkygear()
      .then(() => {
        const userDetailsSub = this.misantoSecurityService.userDetails.subscribe(userDetails => {
          this.currentUserName = userDetails.fullName;
          this.loginToChatServer(userDetails);
        });
        this.subscriptions.add(userDetailsSub);
      })
      .catch(err => {
        throw new Error('Error on init chat:' + err);
      });

    this.PUSH_NOTIFICATION_SETUP = {
      'apns': {
        'aps': {
          'alert': {
            'title': this.translateService.instant('drs.pushNotification.title'),
            'body': this.translateService.instant('drs.pushNotification.text')
          }
        },
        'from': 'skygear',
        'operation': 'notification',
      },
      'fcm': {
        'notification': {
          'title': this.translateService.instant('drs.pushNotification.title'),
          'body': this.translateService.instant('drs.pushNotification.text'),
          'click_action': 'NOTIFICATION_RECEIVED'
        }
      }
    };
  }

  private loginToChatServer(userDetails) {
    this.login(userDetails.skygearUsername, userDetails.skygearPassword)
      .then(() => {
        this.setCurrentUser();
      })
      .catch(err => {
        // if the error code is 110 that means there is no such user, and a new user should be registered
        if (err.error.code === this.USER_DOES_NOT_EXIST_CODE) {
          this.register(userDetails.skygearUsername, userDetails.skygearPassword)
            .then(user => {
              this.login(user.username, userDetails.skygearPassword).then(() => {
                this.setCurrentUser();
              });
            });
        } else {
          console.error('Failed to login to skygear', err);
        }
      });
  }

  setCurrentUser() {
    this.currentUser = this.skygearService.getCurrentUser();
    if (this.currentUser) {
      this.subscribeForMessages();
    }
  }

  private subscribeForMessages() {
    this.skygearService.subscribe(msgData => {
      this.newMessageHandler(msgData);
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.skygearService.unsubscribe(this.newMessageHandler);
  }

  newMessageHandler(msgData: SkygearMessage) {
    if (this.msgBelongsNotToCurrentConversation(msgData)) {
      this.messageReceived.emit(msgData);
      return;
    }

    if (
      msgData.record_type === 'message' &&
      msgData.event_type === 'create' &&
      !this.isEndChatMessage(msgData.record)
    ) {
      this.allMessages.push(this.mapSkyGearMessageToInternalMessage(msgData.record));
    }
  }

  private isEndChatMessage(record: SkygearMessageRecord): boolean {
    if (!record.metadata) {
      return false;
    }
    return record.metadata.type === END_CHAT_TYPE;
  }

  private msgBelongsNotToCurrentConversation(msgData: SkygearMessage) {
    if (!this.currentConversation) {
      return true;
    }

    if (!msgData.record.conversation) {
      return false;
    }
    return SkygearHelper.getCoreId(msgData.record.conversation) !== this.currentConversation.id.split('/')[1];
  }

  login(username, password) {
    if (username && password) {
      return this.skygearService.loginSkygear(username, password);
    }
  }

  register(username, password) {
    if (username && password) {
      return this.skygearService.registerSkyger(username, password);
    }
  }

  sendMessage() {
    if (this.currentConversation && this.message) {
      const messageText = this.message;
      this.message = '';
      this.skygearService.createMessage(this.currentConversation, messageText).then(data => {
        this.sendPushNotification([this.currentConsultation.conversation.patientChatUserId]);
      }).catch(err => {
        throw new Error('Error on send message:' + err);
      });
    }
  }

  createConversation(selectedPatient: Patient): Promise<void> {
    console.log('[LiveChat] Creating new chat record in skygear', selectedPatient.misantoConversation);

    const doctor: SkygearUser = this.currentUser;

    if (!selectedPatient.misantoConversation.patientChatUserId) {
      return;
    }
    return this.skygearService
      .createConversation([doctor._id, selectedPatient.misantoConversation.patientChatUserId])
      .then(newConversation => {
        console.log('[LiveChat] Successfully created new chat in skygear', newConversation);

        selectedPatient.misantoConversation.chatServerConversationId = newConversation._id;
        selectedPatient.misantoConversation.doctorChatUserId = this.currentUser._id;

        this.misantoChatService
          .startConversation(selectedPatient.misantoConversation)
          .subscribe(() => {}, err => console.error('Failed to start conversation on misanto backend', err));

        this.sendInitialMessages(newConversation);
      });
  }

  sendInitialMessages(conversation: SkygearConversation) {
    console.log('Sending initial message', conversation._id);
    const message = this.CHAT_INITIAL_MESSAGE + ' ' + this.CHAT_SECOND_MESSAGE;

    this.skygearService.createMessage(conversation, message)
      .then(() => { this.sendPushNotification([this.currentConsultation.conversation.patientChatUserId]); })
      .catch(err => {
        console.error('Failed to send initial messages for conversation', conversation._id, err);
      });
  }

  restartChatPanel() {
    this.currentConsultation = null;
    this.currentConversation = null;
    this.currentPatient = null;

    this.allMessages = [];
    this.message = '';

    this.canActivateFreeChat = false;
    this.chatInputsDisabled = true;
    this.showConsultationPanel(false);
  }

  setNewPatient(selectedPatient: Patient) {
    console.log('[LiveChat] setNewPatient', selectedPatient);

    this.currentPatient = selectedPatient;
    this.chatInputHidden = this.checkIfPatientConversationIsFinished(selectedPatient);
    this.enableOrDisableChatInputs();
    if (selectedPatient.isInStateFinishedByPatient()) {
      this.onFinishConversation(selectedPatient);
    }
  }

  onSelectPatient(selectedPatient: Patient) {
    console.log('[LiveChat] onSelectPatient', selectedPatient);

    this.restartChatPanel();

    this.currentPatient = selectedPatient;

    this.misantoChatService.getConsultationById(selectedPatient.misantoConversation.consultationId).toPromise()
      .then((consultation: ConsultationWithConversation) => {
        this.currentConsultation = consultation;
        return consultation;
      })
      .then(consultation => this.showConversationFor(consultation, selectedPatient))
      .then(() => {
        const conversationState = this.currentConsultation.conversation.conversationState;
        this.canActivateFreeChat = !(conversationState === ConversationState.DONE || conversationState === ConversationState.DECLINED);
        this.chatInputHidden = this.checkIfPatientConversationIsFinished(this.currentPatient);
        this.enableOrDisableChatInputs();
      })
      .catch(err => {
        console.error('Failed to display patient data', selectedPatient, err);
        this.restartChatPanel();
      });
  }

  private showConversationFor(consultation: ConsultationWithConversation, selectedPatient: Patient): Promise<any> {
    console.log('[LiveChat] Loading messages for misanto conversation', consultation.conversation.id,
      consultation.conversation.conversationState);

    const conversationState = consultation.conversation.conversationState;
    if (conversationState === ConversationState.DONE || conversationState === ConversationState.DECLINED) {
      // If the consultation is finished, the conversation should be fetched from misanto DB
      this.allMessages = consultation.conversation.messages
        .map(message => this.mapToInternalMessage(message, consultation.conversation.id));

      return Promise.resolve();

    } else {
      // If the consultation is not finished, the conversation should be fetched from skygear DB
      if (_.isEmpty(selectedPatient.misantoConversation.chatServerConversationId)) {
        return this.createConversation(selectedPatient)
          .then(() => this.loadConversation(selectedPatient.misantoConversation.chatServerConversationId));
      } else {
        return this.loadConversation(selectedPatient.misantoConversation.chatServerConversationId);
      }
    }
  }

  checkIfPatientConversationIsFinished(patient: Patient): boolean {
    return patient.isInStateDone() || patient.isInStateFinishedByPatient();
  }

  private loadConversation(conversationId) {
    console.log('[LiveChat] Loading chat from skygear with id:', conversationId);
    return this.skygearService
      .getConversation(conversationId)
      .then(conversation => this.loadMessagesFromConversation(conversation));
  }

  private loadMessagesFromConversation(conversation: SkygearConversation) {
    console.log('[LiveChat] Loading messages from skygear for chat:', conversation._id);

    this.allMessages = [];
    this.currentConversation = conversation;

    return this.skygearService
      .getConversationMessages(conversation, null, null, '_created_at')
      .then((messages: SkygearMessageRecord[]) => {
        messages = messages.filter(message => {
          return !this.isEndChatMessage(message);
        });

        messages.reverse();
        const unreadMessagesCount = this.currentConversation.unread_count;
        let unreadMessages = null;
        if (unreadMessagesCount > 0) {
          unreadMessages = messages.slice(messages.length - unreadMessagesCount, messages.length);
          this.skygearService.markMessagesAsRead(unreadMessages);
        }

        this.allMessages = this.mapSkygearMessages(messages);
      });
  }

  activateChatTab() {
    this.waitingRoomTabActive = false;
    this.chatTabActive = true;
  }

  activateWaitingRoomTab() {
    this.waitingRoomTabActive = true;
    this.chatTabActive = false;
  }

  private mapToInternalMessage(message: ConversationMessage, conversationId: number): ChatMessage {
    let chatMessageAttachment: ChatMessageAttachment;

    if (message.type === ConversationMessageType.IMAGE) {

      const imageUrl = this.misantoChatService.buildImageUrl(conversationId, Number(message.value.imageId));
      chatMessageAttachment = {
        name: 'name',
        url: imageUrl,
        contentType: ''
      };
    }

    return {
      message: message.value.message,
      createdAt: this.reformatDateString(message.value.createdAt),
      createdBy: message.value.senderSkygearUserId,
      type: message.type === ConversationMessageType.TEXT ? ChatMessageType.TEXT : ChatMessageType.ATTACHMENT,
      attachment: chatMessageAttachment
    };
  }

  private mapSkygearMessages(messages: SkygearMessageRecord[]) {
    const chatMessages: ChatMessage[] = [];

    messages.forEach(msg => {
      const chatMessage = this.mapSkyGearMessageToInternalMessage(msg);
      chatMessages.push(chatMessage);

    });
    return chatMessages;
  }

  private mapSkyGearMessageToInternalMessage(msg: SkygearMessageRecord): ChatMessage {
    const hasAttachment = !_.isNil(msg.attachment);
    const msgType = hasAttachment ? ChatMessageType.ATTACHMENT : ChatMessageType.TEXT;
    let chatMessageAttachment: ChatMessageAttachment;
    if (hasAttachment) {
      chatMessageAttachment = {
        url: msg.attachment.url,
        name: msg.attachment.name,
        contentType: msg.attachment.contentType
      };
    }

    return {
      message: msg.body,
      type: msgType,
      createdAt: this.formatMessageTimestamp(msg.createdAt),
      createdBy: msg.createdBy,
      attachment: chatMessageAttachment
    };
  }

  formatMessageTimestamp(dateObject: Date): string {
    return moment(dateObject).format('DD.MM.YYYY HH:mm:ss');
  }

  reformatDateString(dateString: string): string {
    const date = new Date(dateString);
    return this.formatMessageTimestamp(date);
  }

  onFinishConversation(patient: Patient) {
    console.log('[LiveChat] onFinishConversation', patient, this.currentConversation);
    if (!this.currentConversation) {
      return;
    }

    this.skygearService.getConversationMessages(this.currentConversation, null, null, null)
      .then(messages => this.saveConversationToMisantoBackend(patient, messages))
      .then(() => this.skygearService.createMessage(this.currentConversation, END_CHAT_MESSAGE, { type: END_CHAT_TYPE }))
      .then(() => this.skygearService.deleteConversation(this.currentConversation))
      .catch(err => {
        console.error('[LiveChat] Failed to finish conversation', this.currentConversation, err);
        this.restartChatPanel();
      })
      .then(() => {
        console.log(`[LiveChat] Conversation '${patient.misantoConversation.id}' finished successfully`);
        this.restartChatPanel();
        this.conversationFinishedSuccessfully.emit();
      });
  }

  private saveConversationToMisantoBackend(patient: Patient, messages) {
    console.log('[LiveChat] saveConversationToMisantoBackend', patient.misantoConversation.id);
    const finishedChat: FinishChatDto = {
      conversationId: patient.misantoConversation.id,
      messages: [],
    };
    finishedChat.messages = messages.map(message => ({
        message: message.body || '',
        skygearUserId: message.createdBy,
        createdAt: message.createdAt.toISOString(),
        type: message.attachment ? 'IMAGE' : 'TEXT',
        imageUrl: message.attachment ? message.attachment.url : '',
      }));

    return this.misantoChatService.finishConversation(finishedChat).toPromise();
  }

  onMessageBoxKeyUp(event: any) {
    if (event && event.keyCode === ENTER_KEY_CODE) {
      this.sendMessage();
    }
  }

  sendPushNotification(skygearUserIds: string[]) {
    if (skygearUserIds && skygearUserIds.length !== 0) {
      try {
        this.skygearService.skygear.push.sendToUser(
          skygearUserIds,
          this.PUSH_NOTIFICATION_SETUP
        );
      } catch (e) {
        console.error('Failed to send push notification', skygearUserIds, e);
      }
    }
  }

  onSendImage(event) {
    if (!this.currentConversation || !event.files[0]) {
      return;
    }

    this.skygearService.createMessage(this.currentConversation, null, null, event.files[0]).then(data => {
      this.chatImageUpload.clear();
      this.sendPushNotification([this.currentConsultation.conversation.patientChatUserId]);
    }).catch(err => {
      throw new Error('Error on send message:' + err);
    });
  }

  showConsultationPanel(value: boolean) {
    this.isConsultationPanelVisible = value;
  }

  /*
    Check if there is a conversation, then check if there is a patient,
    and lastly if that patient is in WAITING_FOR_PATIENT state or DECLINED state
  */
  enableOrDisableChatInputs() {
    if (!this.currentConversation && !this.currentPatient) {
      this.chatInputsDisabled = true;
      return;
    }
    this.chatInputsDisabled = this.currentPatient.isInStateWaitingForPatient() || this.currentPatient.isInStateDeclined();
  }

  /**
   * Handles the "check changed" event on the free chat checkbox.
   *
   * @param checked true if chat is for free, false otherwise
   */
  onFreeChatCheckChanged(checked: boolean) {
    const conversationId = this.currentPatient.misantoConversation.id;
    this.misantoChatService.updateConversationCost(conversationId, checked).subscribe();
  }
}
