import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SearchFeatureService } from './search-feature-service';
import { DrsFeatureNodeDto } from '../shared/model/drs-feature-node-dto';
import { DrsGraphOverviewImpl } from '../shared/model/drs-graph-overview';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-search-feature',
  templateUrl: './search-feature.component.html',
  styleUrls: ['./search-feature.component.scss'],
})
export class SearchFeatureComponent implements AfterViewInit {
  @Input() featureNode: DrsFeatureNodeDto;
  @Input() results: DrsFeatureNodeDto[];
  @Input() searchUrl: string;
  @Input() placeholder: string;
  @Input() labelField: string;
  @Input() noFeatureSelection: boolean;
  @Input() isFocused: boolean;
  @Input() typeOfSearchIsFeature: boolean;
  @Input() searchType: string;
  @Output() featureNodeSelected = new EventEmitter<DrsFeatureNodeDto>();
  @Output() featureNodeClear = new EventEmitter<DrsFeatureNodeDto>();
  @ViewChild('inputElement', { static: true }) inputElement;
  nodeTemplateResults: DrsGraphOverviewImpl[];

  constructor(
    private searchService: SearchFeatureService,
    private confirmationService: ConfirmationService,
    private translateService: TranslateService,
  ) {}

  ngAfterViewInit() {
    if (this.isFocused) {
      setTimeout(() => {
        this.inputElement.inputEL.nativeElement.scrollIntoView();
        this.inputElement.inputEL.nativeElement.focus();
      }, 0);
    }
  }

  search(event) {
    if (this.typeOfSearchIsFeature) {
      this.searchService
        .autocompleteFeature(event.query)
        .subscribe((featureNodes: DrsFeatureNodeDto[]) => {
          this.results = featureNodes;
        });
    } else {
      this.searchService
        .autocompleteNodeTemplate(event.query, this.searchType)
        .subscribe((nodeTemplates: DrsGraphOverviewImpl[]) => {
          this.nodeTemplateResults = nodeTemplates;
        });
    }
  }

  public onFeatureSelect() {
    this.featureNodeSelected.emit(this.featureNode);
  }

  public onClearFeature() {
    if (this.featureNode !== null) {
      this.featureNode = null;
      this.featureNodeClear.emit();
    }
  }

  public onConfirmClearFeature() {
    this.confirmationService.confirm({
      message: this.translateService.instant('drs.graph.delete.relation.confirm'),
      accept: () => {
        this.onClearFeature();
      }
    });
  }
}
