import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { LoggingErrorsService } from '../../logging-errors.service';

@Component({
  selector: 'app-error-popup',
  templateUrl: './error-popup.component.html',
  styleUrls: ['./error-popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ErrorPopupComponent implements OnInit {
  errors = [];

  constructor(private loggingErrorService: LoggingErrorsService, private ref: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.loggingErrorService.getNewError().subscribe((errorMsgs) => {
      this.errors = errorMsgs;
      this.ref.detectChanges();
    });
  }
}
