/**
 * User role DTO.
 */
export interface DrsUserRoleDto {
  name: DrsUserRoleName;
}

export class DrsUserRoleDtoImpl implements DrsUserRoleDto {
  name: DrsUserRoleName;

  constructor(name: DrsUserRoleName) {
    this.name = name;
  }
}

export enum DrsUserRoleName {
  AUTH_SUPER_ADMIN = 'AUTH_SUPER_ADMIN',
  AUTH_USER = 'AUTH_USER',
  AUTH_ADMIN = 'AUTH_ADMIN',
}
