import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {DocumentDetailDto} from '../model/patients/document-detail-dto';

/**
 * A service for document operations.
 */
@Injectable()
export class DocumentUploadService {
  private DOCUMENT_PATH = '/api/patient';

  constructor(private http: HttpClient) {}

  /**
   * Adds document for the given patient.
   *
   * @param id the id the of the patient
   * @param formData the document information
   */
  addPatientDocument(id: number, formData: FormData): Observable<DocumentDetailDto> {

    const httpOptions = {
      headers: new HttpHeaders({
        'enctype': 'multipart/form-data; boundary=request-boundary',
        'Accept': 'application/json'
      })
    };
    return this.http.post<DocumentDetailDto>(`${this.DOCUMENT_PATH}/${id}/document`, formData,  httpOptions);
}
}
