import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule, HttpClientXsrfModule} from '@angular/common/http';
import {TranslateModule} from '@ngx-translate/core';
import {BootstrapService} from './bootstrap.service';
import {AppCoreService} from './services/app-core.service';
import {AppDataService} from './app-data/app-data.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'CSRF-TOKEN',
      headerName: 'X-CSRF-Token'
    }),
    TranslateModule.forRoot()
  ],
  declarations: [],
  providers: [
    BootstrapService,
    AppCoreService,
    AppDataService
  ]
})

export class CoreModule {
}
