import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ChatService } from '../drs/shared/services/chat.service';
import { MisantoSecurityService } from '../misanto-security.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../core/user.service';
import { AppContainerComponent } from './app-container.component';
import { DrsGraphResolverService } from '../drs/resolvers/drs-graph-resolver.service';
import { Router } from '@angular/router';
import { DrsGraph } from '../drs/shared/model/drs-graph';
import * as _ from 'lodash';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
})
export class AppTopBarComponent implements OnInit {
  username: string;
  userActive = true;
  activeStatus: string = this.translateService.instant('drs.topbar.status.active');
  inactiveStatus: string = this.translateService.instant('drs.topbar.status.inactive');
  graph: DrsGraph;
  userInfoIsVisible = false;

  items: MenuItem[];

  constructor(
    public container: AppContainerComponent,
    private _location: Location,
    private misantoChatService: ChatService,
    private misantoSecurityService: MisantoSecurityService,
    private translateService: TranslateService,
    private userService: UserService,
    private graphResolverService: DrsGraphResolverService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.misantoSecurityService.userDetails.subscribe(data => {
      this.username = data.fullName;
      this.changeUserStatus();
    });

    this.graphResolverService.graphResolved.subscribe(data => {
      this.graph = data || {};
    });

    // detect tab closing or browser closing or navigating to another URL and make the user inactive
    window.addEventListener('beforeunload', (e) => {
      this.userActive = false;
      this.changeUserStatus();
    }, false);

    this.items = [{
      items: [
        {label: 'Account', command: (click) => this.accountClicked()},
        {label: 'Abmelden', command: (click) => this.logoutClicked()}
      ]
    }];
  }

  backClicked() {
    this._location.back();
  }

  logoutClicked() {
    window.open('/authentication.html#/logout', '_self'); // TODO MIS001MISA-361 use router
    this.userActive = false;
    this.changeUserStatus();
  }

  accountClicked() {
    window.open('/accountmanagement.html', '_self');
  }

  isRootPage(): boolean {
    return this._location.isCurrentPathEqualTo('/');
  }

  isGraphPage(): boolean {
    if (_.isEmpty(this.graph)) {
      return false;
    }
    return this.router.url.indexOf(this.graph.fullIdentifier.toString()) > -1;
  }

  changeUserStatus() {
    this.userService.setUserStatus(this.userActive).subscribe(() => {
    });
  }

  showUserInfo() {
    this.userInfoIsVisible = true;
  }

  hideUserInfo(event: Event) {
    event.stopImmediatePropagation();
    this.userInfoIsVisible = false;
  }
}
