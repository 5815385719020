<div class="card-image-container-registration">
  <div class="card-image-left-container">
    <p class="card-title">{{cardTitle}}</p>
    <div class="card-image-container">
      <img src="{{FILE_PATH + cardImgId}}" alt="{{cardTitle}}" />
    </div>
  </div>
  <div class="card-inputs-right-container">
      <ng-content></ng-content>
  </div>
</div>
