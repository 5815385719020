import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';


import { AppCoreService } from '../../../core/services/app-core.service';
import { AppDataService } from '../../../core/app-data/app-data.service';
import { Conversation } from '../model/chat/conversation';
import { ConsultationWithConversation } from '../model/chat/consultation';
import { FinishChatDto } from '../model/chat/finish-chat-dto';
import { filter } from 'rxjs/operators';
import { Predicates } from '../../../core/util/observables';

/**
 * Service for chat.
 */
@Injectable()
export class ChatService {

  private static CHAT_PATH = '/chat';
  private static readonly CHAT_API_BASE_URL = `${AppCoreService.REST_BASE_URL}${ChatService.CHAT_PATH}`;

  private CONVERSATION_PATH_SEGMENT = 'conversation';
  private CONSULTATION_PATH_SEGMENT = 'consultation';
  private drsBaseRestUrl: string;

  constructor(private http: HttpClient, private appDataService: AppDataService) {
    this.drsBaseRestUrl = AppCoreService.REST_BASE_URL + ChatService.CHAT_PATH;
  }

  /**
   * Get conversation for given chat user.
   *
   */
  getConversations(chatUserId: string): Observable<Conversation[]> {
    const params = new HttpParams().set('chatUser', chatUserId);
    return this.http.get<Conversation[]>(
      `${this.drsBaseRestUrl}/${this.CONVERSATION_PATH_SEGMENT}/search`,
      { params },
    );
  }

  /**
   * Update conversation.
   *
   */
  startConversation(conversation: Conversation): Observable<Conversation> {
    return this.http.put<Conversation>(
      `${this.drsBaseRestUrl}/${this.CONVERSATION_PATH_SEGMENT}`,
      conversation,
    );
  }

  /**
   * Get the consultation for the current conversation.
   *
   */
  getConsultationById(consultationId: number): Observable<ConsultationWithConversation> {
    return this.http.get<ConsultationWithConversation>(
      `${AppCoreService.REST_BASE_URL}${ChatService.CHAT_PATH}/${this.CONSULTATION_PATH_SEGMENT}/${consultationId}`,
    );
  }

  /**
   * Get conversation for the given consultation.
   *
   * @param consultationId the consultation id.
   */
  getConversationByConsultation(consultationId: number): Observable<Conversation> {
    return this.http.get<Conversation>(`${AppCoreService.REST_BASE_URL}${ChatService.CHAT_PATH}/${this.CONSULTATION_PATH_SEGMENT}/
${consultationId}/${this.CONVERSATION_PATH_SEGMENT}`);
  }

  /**
   * Finish conversation.
   *
   */
  finishConversation(endChatDto: FinishChatDto) {
    const url = `${AppCoreService.REST_BASE_URL}${ChatService.CHAT_PATH}/${this.CONVERSATION_PATH_SEGMENT}/finish`;
    return this.http.post(url, endChatDto).pipe(
      filter(Predicates.noErrors)
    );
  }

  /**
   * Updates the free status of the conversation with the given id.
   *
   * @param conversationId the id of the conversation
   * @param isFree whether the conversation is free of charge
   */
  updateConversationCost(conversationId: number, isFree: boolean): Observable<any> {
    const body = {
      conversationId,
      isFree
    };
    return this.http.put(`${ChatService.CHAT_API_BASE_URL}/${this.CONVERSATION_PATH_SEGMENT}/cost`, body);
  }

  /**
   * Creates the full URL of the image matching the given imageId.
   *
   * @param conversationId the id of the conversation
   * @param imageId the id of the image
   */
  buildImageUrl(conversationId: number, imageId: number): string {
    return `${ChatService.CHAT_API_BASE_URL}/${this.CONVERSATION_PATH_SEGMENT}/${conversationId}/file/${imageId}`;
  }
}
