import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { DrsService } from '../shared/services/drs.service';
import { DrsGraphOverviewDto, GraphTypeEnum } from '../shared/model/drs-graph-overview';
import * as _ from 'lodash';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { Observable ,  Subscription } from 'rxjs';

import { DrsEditorModeService } from '../shared/services/drs-editor-mode.service';
import { ApplicationMessage } from '../../core/messages/application-message';

@Component({
  selector: 'app-node-templates-editor',
  templateUrl: './node-templates-editor.component.html',
  styleUrls: ['./node-templates-editor.component.scss'],
  providers: [
    DrsEditorModeService
  ]
})
export class NodeTemplatesEditorComponent implements OnInit, OnDestroy {
  drsNodeTemplates: DrsGraphOverviewDto[];
  graph: DrsGraphOverviewDto = {
    label: '',
    type: GraphTypeEnum.GRAPH_TEMPLATE
  };

  isEditorLocked$: Observable<boolean>;

  localMessages$: Observable<ApplicationMessage[]>;

  private subscriptions = new Subscription();

  @ViewChild('nodeTemplateDetailsModal', { static: true }) nodeTemplateDetailsModal;

  constructor(
    private drsService: DrsService,
    private confirmationService: ConfirmationService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private drsEditorModeService: DrsEditorModeService
  ) {}

  ngOnInit() {
    this.isEditorLocked$ = this.drsEditorModeService.isEditorLocked$;
    this.localMessages$ = this.drsEditorModeService.messages$;
    this.drsEditorModeService.subscribeForEditorModeChanges();

    this.route.data.subscribe((data) => {
      this.drsNodeTemplates = _.sortBy(data.drsGraphs, graph => graph.label);
    });
    this.handleDataReload();
  }

  ngOnDestroy(): void {
    this.drsEditorModeService.unsubscribeFromEditorModeChanges();
    this.subscriptions.unsubscribe();
  }

  onEditNodeTemplateName(graph) {
    this.graph = _.cloneDeep(graph);

    this.nodeTemplateDetailsModal.setTreeDetailsData(this.graph);
    this.nodeTemplateDetailsModal.setTreeDetailsModal();
  }

  onDeleteNodeTemplate(graph) {
    this.confirmationService.confirm({
      message: this.translateService.instant('drs.graph.root.node.delete.confirm'),
      accept: () => {
        this.drsService.deleteDrsGraph(graph.fullIdentifier).subscribe(() => {
            const index = this.drsNodeTemplates.indexOf(graph);
            if (index > -1) {
              this.drsNodeTemplates.splice(index, 1);
            }
          }, () => {
            throw new Error(this.translateService.instant('drs.graph.root.node.delete.error', graph.id));
          },
        );
      },
    });
  }

  onSaveButtonClick(event) {
    this.graph = _.cloneDeep(event);

    this.drsService.updateDrsGraph(this.graph).subscribe((graphOverview: DrsGraphOverviewDto) => {
        const index = this.findIndexOfNodeTemplateById(this.graph.id);
        this.drsNodeTemplates.splice(index, 1, graphOverview);
      },
      () => {
        throw new Error(this.translateService.instant('drs.graph.root.node.edit.error'));
      });
  }

  findIndexOfNodeTemplateById(id: number): number {
    return this.drsNodeTemplates.indexOf(this.drsNodeTemplates.find(item => {
      return item.id === id;
    }));
  }

  private handleDataReload() {
    const subscription = this.drsEditorModeService.reloadData$.subscribe(() => {
      this.drsService.getDrsGraphs('GRAPH_TEMPLATE').subscribe((nodeTemplates: DrsGraphOverviewDto[]) => {
        this.drsNodeTemplates = nodeTemplates;
      });
    });
    this.subscriptions.add(subscription);
  }
}
