<div class="layout-topbar mis-topbar">
  <div class="content-left">
    <a href="#" class="menu-btn mis-topbar-button"
       (click)="container.onMenuButtonClick($event)"><i class="pi pi-bars"></i></a>
    <img class="topbar-logo" src="assets/images/misanto-logo.png" alt="{{'drs.logo.alt' | translate}}"/>
  </div>
  <div class="content-center" *ngIf="isGraphPage()">
    <p class="topbar-graph-title">{{graph.label}}</p>
  </div>
  <div class="content-right">
    <div class="tool-bar">
      <i class="topbar-back-button pi pi-arrow-left" (click)="backClicked()" *ngIf="!isRootPage()"></i>
    </div>

    <div class="user-profile" (click)="showUserInfo()">
      <div class="name-info" [ngClass]="{'active': userInfoIsVisible}">

        <p class="user-name" (click)="menu.toggle($event)">
          {{username}}
          <i class="pi pi-chevron-down"></i>
        </p>
        <p-menu #menu [popup]="true" [model]="items"></p-menu>

        <div class="user-status-label">
          <label class="switch">
            <input type="checkbox" (change)="changeUserStatus()" [(ngModel)]="userActive">
            <span class="slider round"></span>
          </label>
          <p class="status-title">{{userActive ? activeStatus : inactiveStatus}}</p>
        </div>

      </div>
      <div class="profile-picture">
        <img src="assets/images/default-chat-user.jpg"/>
        <span class="status" [ngClass]="userActive ? '' : 'inactive'"></span>
      </div>
      <div class="user-info-overlay" (click)="hideUserInfo($event)" [ngClass]="{'active': userInfoIsVisible}"></div>
    </div>
  </div>
</div>
