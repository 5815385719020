/**
 * DrsGraphOverviewDto.
 */
export interface DrsGraphOverviewDto {
  id?: number;
  label: string;
  type?: GraphTypeEnum;
  fullIdentifier?: string;
}

// TODO dristov 2019-10-02: [no-issue] This is unnecessary. Should be removed.
export class DrsGraphOverviewImpl implements DrsGraphOverviewDto {
  id?: number;
  label: string;
  type?: GraphTypeEnum;
  fullIdentifier?: string;
}

export enum GraphTypeEnum {
  DRS_GRAPH = 'DRS_GRAPH',
  GRAPH_TEMPLATE = 'GRAPH_TEMPLATE'
}

/**
 * The data needed to create a new graph.
 */
export interface CreateGraph {
  label: string;
  type: GraphTypeEnum;
  drsVersion: number;
}
