import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {formatStringToDate} from '../../utils/date';
import {DocumentStatusEnum} from '../../drs/shared/model/patients/document-detail-dto';
import {PatientDocumentDto} from '../../drs/shared/model/patients/patient-document-dto';
import {
  PatientConsultationDto,
  PatientConsultationsResponse
} from '../../drs/shared/model/patients/patient-consultation-dto';
import {PatientsService} from '../../patients.service';
import {flatMap, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {PatientDetailsHeaderStore} from '../../drs/shared/services/patient-details-header-store.service';
import {DateFormat} from '../../utils/date-format';

@Component({
  selector: 'app-patient-detail',
  templateUrl: './patient-detail.component.html',
  styleUrls: ['./patient-detail.component.scss'],
})
export class PatientDetailComponent implements OnInit {
  consultations: PatientConsultationDto[] = [];
  activeDocuments: PatientDocumentDto[] = [];
  archivedDocuments: PatientDocumentDto[] = [];

  selectedPatientId: number;

  constructor(private route: ActivatedRoute,
              private patientService: PatientsService,
              private patientDetailsHeaderStore: PatientDetailsHeaderStore,
              private location: Location
  ) {}

  ngOnInit() {
    this.loadPatientDetails();
    this.loadPatientDocuments();
    this.loadPatientConsultations();
  }

  reformatConsultationDate(date: string): string {
    return formatStringToDate(date, DateFormat.MISANTO_DATETIME_DEFAULT);
  }

  private loadPatientDetails() {
    const id = +this.route.snapshot.params['id'];
    this.patientDetailsHeaderStore.showPatientDetails(id);
    this.selectedPatientId = +this.route.snapshot.params['id'];
  }

  private loadPatientConsultations() {
    this.getPatientIdFromPath().pipe(
      flatMap((patientId: number) =>
        this.patientService.getPatientConsultations(patientId)
      ))
      .subscribe((response: PatientConsultationsResponse) => {
        this.consultations = response.consultations;
      });
  }

  private loadPatientDocuments() {
    this.getPatientIdFromPath().pipe(
      flatMap((patientId: number) =>
        this.patientService.getPatientDocuments(patientId)
      ))
      .subscribe(response => {
        this.activeDocuments = response.documents
          .filter(document => DocumentStatusEnum.MARKED_AS_DELETED !== document.status);
        this.archivedDocuments = response.documents
          .filter(document => DocumentStatusEnum.MARKED_AS_DELETED === document.status);
      });
  }

  private getPatientIdFromPath(): Observable<number> {
    return this.route.params.pipe(
      map(params => +params['id'])
    );
  }

  goBack() {
    this.location.back();
  }
}
