import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CustomErrorsHandler } from '../services/errors-handler';

@Injectable()
export class ErrorHandlerHttpInterceptor implements HttpInterceptor {
  constructor(private errorHandler: CustomErrorsHandler) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map(event => {
        if (event instanceof HttpResponse && event.body && event.body.error) {
          this.errorHandler.handleErrorResponse(JSON.stringify(event.body.error));
        }
        return event;
      })
    );
  }
}
