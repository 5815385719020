import { Injectable } from '@angular/core';
import { AppDataDto } from './app-data';
import { Observable ,  Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

/**
 * Service which provides the app data object.
 */
@Injectable()
export class AppDataService {

  public appData: AppDataDto;

  private versionChangeSource = new Subject<number>();

  /**
   * Emits the new default DRS version whenever it is changed.
   */
  public readonly versionChanged$: Observable<number> = this.versionChangeSource.asObservable()
    .pipe(
      distinctUntilChanged()
    );

  public getDefaultDrsVersion(): number {
    return this.appData.defaultDrsVersion;
  }

  public setDefaultDrsVersion(version: number) {
    this.appData.defaultDrsVersion = version;
    this.versionChangeSource.next(version);
  }
}
