import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class LoggingErrorsService {

  private newError = new ReplaySubject<any>();

  logError(error) {
    this.newError.next(error);
  }

  getNewError() {
    return this.newError;
  }

  clearAll() {
    this.newError.next([]);
  }
}
