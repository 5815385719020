import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DrsComponent } from './drs.component';
import { AppDataResolverService } from './resolvers/app-data-resolver.service';
import { DrsGraphResolverService } from './resolvers/drs-graph-resolver.service';
import { DrsGraphsResolverService } from './resolvers/drs-graphs-resolver.service';
import { GraphManagerComponent } from './graph-manager/graph-manager.component';
import { LiveChatComponent } from '../live-chat/live-chat.component';
import { AuthGuardService } from '../core/authGuard.service';
import { NodeTemplatesEditorComponent } from './node-templates-editor/node-templates-editor.component';
import { DrsGraphTemplatesResolverService } from './resolvers/drs-graph-templates-resolver.service';
import { AppContainerComponent } from '../app-container/app-container.component';
import { RegistrationsTableComponent } from '../registrations-table/registrations-table.component';
import { RegistrationDetailsComponent } from '../registration-details/registration-details.component';
import { RegistrationResolverService } from '../resolvers/registration-resolver.service';
import { PatientListComponent } from '../patient-cockpit/patient-list/patient-list.component';
import { PatientDetailComponent } from '../patient-cockpit/patient-detail/patient-detail.component';
import { PatientsResolverService } from '../resolvers/patients-resolver.service';
import { DocumentDetailComponent } from '../document/document-detail/document-detail.component';
import { DocumentResolverService } from './resolvers/document-resolver.service';
import { ConsultationComponent } from '../consultation/consultation.component';
import {HealthInsuranceCardOverviewComponent} from '../health-insurance-card-overview/health-insurance-card-overview.component';
import {HealthInsuranceCardDetailsComponent} from '../health-insurance-card-details/health-insurance-card-details.component';
import {DocumentUploadComponent} from '../patient-cockpit/document-upload/document-upload.component';

const drsRoutes: Routes = [
  {
    path: '',
    canActivate: [AuthGuardService],
    component: AppContainerComponent,
    children: [
      {
        path: '',
        canActivate: [AuthGuardService],
        component: LiveChatComponent
      },
      {
        path: 'editor',
        component: GraphManagerComponent,
        canActivate: [AuthGuardService],
        resolve: {
          drsGraphs: DrsGraphsResolverService,
        },
      },
      {
        path: 'node-templates',
        canActivate: [AuthGuardService],
        component: NodeTemplatesEditorComponent,
        resolve: {
          drsGraphs: DrsGraphTemplatesResolverService,
        },
      },
      {
        path: 'registrations',
        canActivate: [AuthGuardService],
        component: RegistrationsTableComponent,
      },
      {
        path: 'registration/:id',
        component: RegistrationDetailsComponent,
        canActivate: [AuthGuardService],
        resolve: {
          registration: RegistrationResolverService,
        },
      },
      {
        path: 'health-insurance-card',
        component: HealthInsuranceCardOverviewComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'patientcockpit',
        canActivate: [AuthGuardService],
        component: PatientListComponent,
        resolve: {
          patients: PatientsResolverService,
        },
      },
      {
        path: 'patientcockpit/:id',
        canActivate: [AuthGuardService],
        component: PatientDetailComponent
      },
      {
        path: 'patient/:patientId/consultation/:consultationId',
        canActivate: [AuthGuardService],
        component: ConsultationComponent,
      },
      {
        path: 'consultation/:id/conversation',
        component: ConsultationComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'patientcockpit/:patientId/document/:documentId',
        canActivate: [AuthGuardService],
        component: DocumentDetailComponent,
        resolve: {
          document: DocumentResolverService,
        },
      },
      {
        path: ':id',
        component: DrsComponent,
        canActivate: [AuthGuardService],
        resolve: {
          drsGraph: DrsGraphResolverService,
          appData: AppDataResolverService,
        },
      },
      {
        path: 'health-insurance-card/:id',
        canActivate: [AuthGuardService],
        component: HealthInsuranceCardDetailsComponent
      },
      {
        path: 'patient/:id/document-upload',
        canActivate: [AuthGuardService],
        component: DocumentUploadComponent
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(drsRoutes)],
  exports: [RouterModule],
  providers: [
    DrsGraphResolverService,
    DrsGraphsResolverService,
    AppDataResolverService,
    DrsGraphTemplatesResolverService,
    RegistrationResolverService,
    PatientsResolverService,
    DocumentResolverService,
  ],
})
export class DrsRoutingModule {}
