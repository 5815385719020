import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { DrsService } from '../shared/services/drs.service';
import { DrsGraphOverviewDto } from '../shared/model/drs-graph-overview';

/**
 * Resolves the drs graph object.
 */
@Injectable()
export class DrsGraphTemplatesResolverService implements Resolve<DrsGraphOverviewDto[]> {
  private DRS_GRAPH_TEMPLATE_TYPE = 'GRAPH_TEMPLATE';

  constructor(private drsService: DrsService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : Observable<DrsGraphOverviewDto[]> | Promise<DrsGraphOverviewDto[]> | DrsGraphOverviewDto[] {
    return this.drsService.getDrsGraphs(this.DRS_GRAPH_TEMPLATE_TYPE);
  }
}
