/**
 * Model that represents a conversation.
 */
export interface Conversation {
  id: number;
  patientChatUserId: string;
  patientUsername: string;
  doctorChatUserId: string;
  doctorFirstName: string;
  doctorLastName: string;
  conversationState: ConversationState;
  chatServerConversationId: string;
  createdAt: string;
  consultationId: number;
  chatStartedAt: string;
  chatFinishedAt: string;
  messages: ConversationMessage[];
  free: boolean;
}

export enum ConversationState {
  ONGOING = 'ONGOING',
  FINISHED_BY_PATIENT = 'FINISHED_BY_PATIENT',
  DONE = 'DONE',
  WAITING_FOR_PATIENT = 'WAITING_FOR_PATIENT',
  WAITING_FOR_DOCTOR = 'WAITING_FOR_DOCTOR',
  DECLINED = 'DECLINED'
}

export interface ConversationMessage {
  type: ConversationMessageType;
  value: ConversationMessageValue;
}

export enum ConversationMessageType {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE'
}

export interface ConversationMessageValue {
  createdAt: string;
  message: string;
  senderSkygearUserId: string;
  imageId: string;
  imageUrl: string;
}
