export interface DocumentDetailDto {
  id: number;
  category: DocumentCategoryEnum;
  comment: string;
  date: string;
  description: string;
  doctorFirstName: string;
  doctorLastName: string;
  status: DocumentStatusEnum;
  documentFileId: string;
  uploadedBy: DocumentModeratorEnum;
  deletedBy: DocumentModeratorEnum;
}

export interface UpdateDocumentStatusRequest {
  status: DocumentStatusEnum;
}

export enum DocumentCategoryEnum {
  MEDICAL_LETTER_AND_DIAGNOSIS = 'MEDICAL_LETTER_AND_DIAGNOSIS',
  SICK_RECORD = 'SICK_RECORD',
  LABORATORY_RESULT = 'LABORATORY_RESULT',
  CT_AND_MRT = 'CT_AND_MRT',
  ECG = 'ECG',
  X_RAY_IMAGE = 'X_RAY_IMAGE',
  RECIPE = 'RECIPE',
  OTHER = 'OTHER',
  INVOICE = 'INVOICE'
}

export enum DocumentStatusEnum {
  REQUESTED = 'REQUESTED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  MARKED_AS_DELETED = 'MARKED_AS_DELETED'
}

export enum DocumentModeratorEnum {
  PATIENT = 'PATIENT',
  MISANTO = 'MISANTO'
}
