<div class="patient-basic-info" *ngIf="(patient$ | async) as patient">
  <div class="ui-grid-row first-row">
    <div class="ui-grid-col-6">
      <a class="ui-grid-col-2 patient-main" [routerLink]="routeToPatient()">
        <span>{{ 'patient.cockpit.table.header.patientID' | translate }}</span>
        <br>
        <p>{{ patient.patientId }}</p>
      </a>
      <div class="ui-grid-col-2">
        <span>{{ 'patient.cockpit.table.header.firstName' | translate }}</span>
        <p>{{ patient.firstName }}</p>
      </div>
      <div class="ui-grid-col-2">
        <span>{{ 'patient.cockpit.details.label.gender' | translate }}</span>
        <p>{{ patient.gender }}</p>
      </div>
      <div class="ui-grid-col-2">
        <span>{{ 'patient.cockpit.details.label.dateOfBirth' | translate }}</span>
        <p>{{ reformatPatientDateOfBirth(patient.dateOfBirth) }}</p>
      </div>
      <div class="ui-grid-col-2">
        <span>{{ 'patient.cockpit.details.label.address' | translate }}</span>
        <p> {{ formatAddress(patient) }} </p>
      </div>
    </div>
    <div class="ui-grid-col-6 id-icon">
      <div>
        <a *ngIf="patient.userIdentificationId" routerLink="/registration/{{patient.userIdentificationId}}" class="basic-link id-link">ID</a>
      </div>
    </div>
  </div>
  <div class="ui-grid-row">
    <div class="ui-grid-col-6">
      <div class="ui-grid-col-2">
        <a [href]="emailAddress(patient)"> <i class="pi pi-envelope"></i> </a>
      </div>
      <div class="ui-grid-col-2">
        <span>{{ 'patient.cockpit.table.header.lastName' | translate }}</span>
        <p>{{ patient.lastName }}</p>
      </div>
      <div class="ui-grid-col-2">
        <span>{{ 'patient.cockpit.details.label.weight' | translate }}</span>
        <p>{{ patient.weight }}</p>
      </div>
      <div class="ui-grid-col-2">
        <span>{{ 'patient.cockpit.details.label.height' | translate }}</span>
        <p>{{ patient.height }}</p>
      </div>
      <div class="ui-grid-col-2">
        <span>{{ 'patient.cockpit.details.label.healthInsuranceCardNumber' | translate }}</span>
        <p>{{ patient.healthInsuranceCardNumber }}</p>
      </div>
    </div>
  </div>
</div>
