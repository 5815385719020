import {Component, OnInit, ViewChild} from '@angular/core';
import {MessageService, SelectItem} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {DocumentCategoryEnum} from '../../drs/shared/model/patients/document-detail-dto';
import {AddPatientDocumentRequest} from '../../drs/shared/model/document-upload/add-patient-document-request';
import {DocumentUploadService} from '../../drs/shared/services/document-upload-service';
import {ActivatedRoute, Router} from '@angular/router';
import {DocumentUploadFormModel} from './document-upload-form-model';
import {Location} from '@angular/common';
import {FileUpload} from 'primeng/fileupload';
import {toBlob} from '../../utils/files';
import {formatDateToString} from '../../utils/date';
import {FileUploadOnSelect} from '../../shared/types/prime-ng-types';
import {MimeTypes} from '../../drs/shared/model/file/mime-types';
import {PatientDetailsHeaderStore} from '../../drs/shared/services/patient-details-header-store.service';

@Component({
  selector: 'app-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.scss'],
  providers: [MessageService]
})
export class DocumentUploadComponent implements OnInit {

  document = new DocumentUploadFormModel();

  selectedFile: File;
  categories: SelectItem[];

  showContentArea = true;
  chooseButtonDisabled = false;
  saveButtonDisabled = true;

  patientId: number;

  @ViewChild(FileUpload, { static: true }) fileUpload: FileUpload;

  constructor(
    private translateService: TranslateService,
    private documentUploadService: DocumentUploadService,
    private messageService: MessageService,
    private patientDetailsHeaderStore: PatientDetailsHeaderStore,
    private location: Location,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.categories = this.createCategoriesCollection();
    const id = +this.route.snapshot.params['id'];
    this.patientDetailsHeaderStore.showPatientDetails(id);
  }

  onSaveDocument() {
    const documentData: AddPatientDocumentRequest = {
      category: this.document.category,
      date: formatDateToString(this.document.date, 'YYYY-MM-DD'),
      doctorFirstName: this.document.doctorFirstName,
      doctorLastName: this.document.doctorLastName,
      comment: this.document.comment,
      description: this.document.description
    };

    const formData = this.createFormData(documentData);

    this.patientId = this.route.snapshot.params['id'];
    this.documentUploadService.addPatientDocument(this.patientId, formData).subscribe(() => {
      this.router.navigate(['patientcockpit', this.patientId]);
    });
  }

  onCancel() {
    this.location.back();
  }

  createFormData(documentData: AddPatientDocumentRequest): FormData {

    const documentBlob = toBlob(documentData, MimeTypes.APPLICATION_JSON);

    const formData = new FormData();
    formData.append('documentFile', this.selectedFile, this.selectedFile.name);
    formData.append('documentData', documentBlob);

    return formData;
  }

  createCategoriesCollection() {
    const categories: SelectItem[] = [];
    Object.keys(DocumentCategoryEnum).map(category => {
      categories.push({label: this.translateService.instant('api.document.category.' + category), value: category});
    });
    return categories;
  }

  onFileSelected(event: FileUploadOnSelect) {
    const selectedFile = event.files[0].type;
    if (selectedFile !== MimeTypes.APPLICATION_PDF) {
      this.fileUpload.clear();
      this.setButtonsState();
      this.createErrorMessageForInvalidType();
    } else {
      this.showContentArea = false;
      if (event.files.length > 0) {
        this.selectedFile = event.files[0];
        this.chooseButtonDisabled = true;
        this.saveButtonDisabled = false;
      }
    }
  }

  onFileRemove() {
   this.setButtonsState();
  }

  onClear() {
    this.setButtonsState();
  }

  createErrorMessageForInvalidType() {
    this.messageService.add({
      severity: 'error',
      summary: this.translateService.instant('document.upload.toastMessage.invalidType.summary'),
      detail: this.translateService.instant('document.upload.toastMessage.invalidType.detail')
    });
  }

  setButtonsState() {
    this.showContentArea = true;
    this.chooseButtonDisabled = false;
    this.saveButtonDisabled = true;
  }
}
