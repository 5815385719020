import { TreeNode } from 'primeng/api';
import { DrsNodeDataDto, DrsNodeDataDtoImpl } from './drs-node-data-dto';
import { SelectableCondition } from '../../condition/selectable-condition';

export const CSS_NODE_TYPE_PREFIX = 'mis-node-type-';

/**
 * DrsNodeDto.
 */
export interface DrsNodeDto extends TreeNode {
  nodeId?: string;
  data?: DrsNodeDataDto;
  children?: DrsNodeDto[];
  conditions?: SelectableCondition[];
  id: number;
}

export class DrsNodeDtoImpl implements DrsNodeDto {
  expanded?: boolean;
  data: DrsNodeDataDto;
  children?: DrsNodeDto[];
  label?: string;
  type?: any;
  nodeId?: string;
  conditions?: SelectableCondition[];
  id: number;

  constructor() {
    this.expanded = true;
    this.children = [];
    this.data = new DrsNodeDataDtoImpl();
    DrsNodeDtoImpl.updateStyleClass(this);
  }

  public static updateStyleClass(node: DrsNodeDto) {
    node.styleClass = CSS_NODE_TYPE_PREFIX + node.type;
  }
}
