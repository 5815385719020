<p-sidebar (onHide)="onPopupClose()" [(visible)]="isPreviewSidebarOpen" [modal]="false"
           class="preview-sidebar" [style]="{width: '450px'}">
  <ng-container *ngIf="previewData">
    <p class="preview-question">{{previewData.question}}</p>
    <button class="preview-show-help-text-button" *ngIf="previewData.helpText" (click)="onShowHelpText()">
      {{'drs.btn.preview.showMore.label' | translate}}
    </button>
    <div class="preview-answers-container">
      <div class="preview-clickable-map-image" *ngIf="previewData.imageId">
        <object class="pdf-viewer" *ngIf="imageUrl" [data]="imageUrl | bypassSecurity" type="image/png">
          <p>{{'apip.header.pdf.viewer.error.message' | translate}}</p>
          <a href="{{imageUrl}}">{{'apip.header.pdf.viewer.error.link.label' | translate}}</a>
        </object>
      </div>
      <div class="preview-answers">
        <ul>
          <li *ngFor="let answer of previewData.possibleAnswers">{{answer}}</li>
        </ul>
      </div>
    </div>
  </ng-container>
</p-sidebar>

<p-dialog header="{{'drs.preview.helpText.popup.title' | translate}}"
          [(visible)]="isPreviewSidebarOpen && displayHelpText"
          [style]='{minWidth: 350}'>
  <p *ngIf="previewData && previewData.helpText">{{previewData.helpText}}</p>
</p-dialog>
