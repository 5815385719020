<app-patient-header></app-patient-header>
<div class="consultation-details-wrapper" style="border: 2px; display: flex; flex-direction: row">
  <div class="conversation-history" style="border: 2px; flex: 2; align-self: flex-start">
    <h3 class="consultation-history-title">{{'consultation.detail.conversation.label' | translate}}</h3>
    <div *ngIf="conversation" class="chat">
      <ul>
        <li *ngFor="let msg of conversation.messages"
            [ngClass]="{'left-message': msg.value.senderSkygearUserId === conversation.doctorChatUserId, 'image-message': msg.value.imageUrl}">
           <span class="username-message"
                 *ngIf="msg.value.senderSkygearUserId === conversation.doctorChatUserId; else ifNot">
            {{ conversation.doctorFirstName }} {{ conversation.doctorLastName }}
          </span>
          <ng-template #ifNot>
            <span class="username-message" *ngIf="consultation">
              {{ consultation.patientFirstName }} {{ consultation.patientLastName }}
            </span>
          </ng-template>
          <span class="timestamp-message">{{msg.value.createdAt | date:'d.M.yyyy, H:mm:ss'}}</span>
          {{msg.value.message}}
          <img *ngIf="msg.value.imageUrl" src="{{msg.value.imageUrl}}" />
        </li>
      </ul>
    </div>
  </div>

  <div class="consultation-history" style="border: 2px; flex: 1;">
    <h3 class="consultation-history-title">{{'consultation.detail.consultation.label' | translate}}</h3>
    <div class="consultation-panel-container">
      <ul class="consultation-list" *ngIf="consultation">
        <ng-container *ngFor="let question of consultation.questions">
          <li class="question-list-element">
            {{question?.question}}
          </li>
          <li class="answer-list-element">
            {{question?.answer}}
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</div>
