import { EventEmitter, Injectable } from '@angular/core';
import * as skygearAPI from 'skygear';
import * as skygearChat from 'skygear-chat';
import { AppDataService } from './core/app-data/app-data.service';
import { SkygearConversation, SkygearMessageRecord } from './drs/shared/model/chat/skygear-model';

@Injectable()
export class SkygearService {
  skygearChatPlugin = skygearChat;
  User = skygearAPI.Record.extend('user');
  skygear = skygearAPI;
  isConfigured = false;
  skygearUserLoggedIn = new EventEmitter<any>();

  constructor(private appDataService: AppDataService) {}

  subscribe(messageHandler: Function) {
    this.skygearChatPlugin.subscribe(messageHandler);
  }

  unsubscribe(messageHandler: Function) {
    this.skygearChatPlugin.unsubscribe(messageHandler);
  }

  loginSkygear(username, pw) {
    return this.logoutSkygear().then(() => {
      return this.skygear.auth.loginWithUsername(username, pw).then(
        result => {
          this.skygearUserLoggedIn.emit();
          return result;
        }
      );
    });
  }

  logoutSkygear() {
    return this.skygear.auth.logout();
  }

  registerSkyger(username, pw) {
    return this.skygear.auth.signupWithUsername(username, pw).then(
      result => result
    );
  }

  createConversation(participants) {
    const conversationOptions = {
      distinctByParticipants: false,
    };

    return this.getUsersById(participants).then(users => {
      return skygearChat.createConversation(users, 'MiSANTO', null, conversationOptions);
    });
  }

  getConversationMessages(currentConversation, limit: number, beforeTime: Date, order: string) {
    return this.skygearChatPlugin.getMessages(currentConversation, limit, beforeTime, order);
  }

  getUsersById(userIds) {
    const userQuery = new this.skygear.Query(this.User);
    userQuery.contains('_id', userIds);
    return this.skygear.publicDB.query(userQuery).then(result => {
      return Array.from(result);
    });
  }

  initSkygear() {
    if (this.isConfigured) {
      return Promise.resolve(this.skygear);
    }
    const promise = this.skygear.config(this.createSkygearCredentials());
    promise.then(() => (this.isConfigured = true));
    return promise;
  }

  private createSkygearCredentials(): Object {
    return {
      endPoint: this.appDataService.appData.chatServerUrl,
      apiKey: this.appDataService.appData.chatServerKey
    };
  }

  createMessage(conversation, content, metadata = null, asset = null): Promise<SkygearMessageRecord> {
    return this.skygearChatPlugin.createMessage(conversation, content, metadata, asset);
  }

  getCurrentUser() {
    return this.skygear.auth.currentUser;
  }

  markMessagesAsRead(messages) {
    return this.skygearChatPlugin.markAsRead(messages);
  }

  getConversation(conversationId) {
    return this.skygearChatPlugin.getConversation(conversationId);
  }

  /**
   * Deletes the given conversation from skygear. This action can be executed only by an admin user.
   *
   * @param conversation the conversation record to be deleted
   */
  deleteConversation(conversation: SkygearConversation) {
    console.log('[Skygear] deleteConversation', conversation._id);
    return this.skygearChatPlugin.deleteConversation(conversation);
  }
}
