import { Injectable } from '@angular/core';

const RECTANGLE_CLASS = 'rectangle';
const CROSSHAIR_POINTER_CLASS = 'drawing-rectangle';
const CLOSE_BUTTON_CLASS = 'close-button-clickable-map-rectangle';
const DECIMAL_PLACES_FOR_PERCENTAGE = 5;
const PERCENTAGE_MULTIPLIER = 100;

@Injectable()
export class DrawingService {
  mapContainer;
  allBoxes: DrsClickableMapRectangleDimensions[] = [];
  currentPossibleAnswer;

  constructor() {}

  initDrawingOnClickableMap(mapContainer, possibleAnswer, alreadyInitilized, featureData) {
    this.currentPossibleAnswer = possibleAnswer;
    this.removeAllRectangles(mapContainer);

    if (possibleAnswer.clickableAreas) {
      possibleAnswer.clickableAreas.forEach(area => {
        const rect = this.drawRectangle();
        this.setRectangleDimensions(rect, area.x, area.y, area.width, area.height);
        mapContainer.appendChild(rect);
      });
    }

    this.mapContainer = mapContainer;
    featureData.aspectRatio = Number((this.mapContainer.offsetWidth / this.mapContainer.offsetHeight)
      .toFixed(DECIMAL_PLACES_FOR_PERCENTAGE));

    let mouse = {
      x: 0,
      y: 0,
      startX: 0,
      startY: 0
    };
    let element = null;

    if (!alreadyInitilized) {
      const mouseMoveHandler = (e) => {
        mouse = {...this.getMousePosition(mapContainer, e), startX: mouse.startX, startY: mouse.startY};
        if (element !== null) {
          element.style.width = Math.abs(mouse.x - mouse.startX) + 'px';
          element.style.height = Math.abs(mouse.y - mouse.startY) + 'px';
          element.style.left = (mouse.x - mouse.startX < 0) ? mouse.x + 'px' : mouse.startX + 'px';
          element.style.top = (mouse.y - mouse.startY < 0) ? mouse.y + 'px' : mouse.startY + 'px';
        }
      };

      // Removing the event listener to avoid many of the same event listener to stack on the clickable map
      mapContainer.removeEventListener('mousemove', mouseMoveHandler, false);
      mapContainer.addEventListener('mousemove', mouseMoveHandler, false);

      const clickHandler = (e) => {
        if (element !== null) {
          element = null;
          mapContainer.classList.remove(CROSSHAIR_POINTER_CLASS);
        } else if (e.target.tagName === 'IMG') {
          mouse.startX = mouse.x;
          mouse.startY = mouse.y;
          element = this.drawRectangle();

          element.className = RECTANGLE_CLASS;
          element.style.left = mouse.x + 'px';
          element.style.top = mouse.y + 'px';

          mapContainer.appendChild(element);
          mapContainer.classList.add(CROSSHAIR_POINTER_CLASS);
        }
      };

      mapContainer.removeEventListener('click', clickHandler, false);
      mapContainer.addEventListener('click', clickHandler, false);
    }
  }

  saveAllRectangles() {
    const boxes = this.mapContainer.querySelectorAll('.rectangle');
    this.allBoxes.length = 0;
    for (let index = 0; index < boxes.length; index++) {
      const rectangleInformation: DrsClickableMapRectangleDimensions = this.getDimensionsInPercentages(this.mapContainer, boxes[index]);
      this.allBoxes.push(rectangleInformation);
    }
    this.currentPossibleAnswer.clickableAreas = this.allBoxes.slice();
  }

  getMousePosition(mapContainer, evt): DrsClickableMapRectanglePosition {
    const rect = mapContainer.getBoundingClientRect();
    return {
      x: evt.clientX - rect.left,
      y: evt.clientY - rect.top
    };
  }

  removeAllRectangles(mapContainer) {
    const oldRectangles = mapContainer.querySelectorAll('.rectangle');
    oldRectangles.forEach((oldRect) => {
      oldRect.remove();
    });
  }

  drawRectangle() {
    const element = document.createElement('div');
    element.classList.add(RECTANGLE_CLASS);

    const closeButton = document.createElement('button');
    closeButton.classList.add(CLOSE_BUTTON_CLASS);
    closeButton.innerHTML = 'X';

    element.appendChild(closeButton);

    ((rectangle) => {
      closeButton.addEventListener('click', () => {
        rectangle.parentNode.removeChild(rectangle);
      });
    })(element);
    return element;
  }

  getDimensionsInPercentages(mapContainer, rectangle): DrsClickableMapRectangleDimensions {
    const dimensions: DrsClickableMapRectangleDimensions = {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    };
    dimensions.x = Number((rectangle.offsetLeft / mapContainer.offsetWidth).toFixed(DECIMAL_PLACES_FOR_PERCENTAGE));
    dimensions.y = Number((rectangle.offsetTop / mapContainer.offsetHeight).toFixed(DECIMAL_PLACES_FOR_PERCENTAGE));
    dimensions.width = Number((rectangle.offsetWidth / mapContainer.offsetWidth).toFixed(DECIMAL_PLACES_FOR_PERCENTAGE));
    dimensions.height = Number((rectangle.offsetHeight / mapContainer.offsetHeight).toFixed(DECIMAL_PLACES_FOR_PERCENTAGE));

    return dimensions;
  }

  setRectangleDimensions(rectangle, x, y, width, height) {
    rectangle.style.top = y * PERCENTAGE_MULTIPLIER + '%';
    rectangle.style.left = x * PERCENTAGE_MULTIPLIER + '%';
    rectangle.style.width = width * PERCENTAGE_MULTIPLIER + '%';
    rectangle.style.height = height * PERCENTAGE_MULTIPLIER + '%';
  }
}

export interface DrsClickableMapRectanglePosition {
  x: number;
  y: number;
}

export interface DrsClickableMapRectangleDimensions {
  x: number;
  y: number;
  width: number;
  height: number;
}

