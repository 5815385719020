<h3 class="header-patients">Patients</h3>
<div class="patients-table">
  <div class="patients-table-filters">
  </div>
  <p-table #ptable [value]="patients" [globalFilterFields]="['patientId', 'firstName', 'lastName']"
           [paginator]="true" [rows]="10" [alwaysShowPaginator]="false">
    <ng-template pTemplate="caption">
      <div>
        <i class="pi pi-search"></i>
        <input type="text" pInputText size="50" placeholder="{{'patient.cockpit.table.filter.placeholder' | translate}}"
               (input)="ptable.filterGlobal($event.target.value, 'contains')">
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th>{{'patient.cockpit.table.header.patientID' | translate}}</th>
        <th>{{'patient.cockpit.table.header.lastName' | translate}}</th>
        <th>{{'patient.cockpit.table.header.firstName' | translate}}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-patient>
      <tr (click)="selectRow(patient)">
        <td>{{patient.patientId}}</td>
        <td>{{patient.lastName}}</td>
        <td>{{patient.firstName}}</td>
      </tr>
    </ng-template>
  </p-table>
</div>
