import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { DrsUserDetailsDto } from '../drs/shared/model/drs-user-details-dto';

/**
 * User Service for authenticated users.
 */
@Injectable()
export class UserService {

  private API_DRS_PATH = '/api/drs';
  private USER_PATH = 'user';

  /**
   * State holder for the active status of the authenticated user.
   */
  private userActiveStatusHolder = new BehaviorSubject<boolean>(false);

  /**
   * Returns the latest state for the active status of the authenticated user.
   */
  public readonly userActive$ = this.userActiveStatusHolder.asObservable();

  constructor(private http: HttpClient) {}

  /**
   * Check if the user is authenticated.
   * @returns {Observable<Object>}
   */
  public isValid() {
    return this.http.get('/auth/authentication/api/public/authentication');
  }

  /**
   * Get user roles.
   * @returns {Observable<DrsUserDetailsDto>}
   */
  public getUserDetails(): Observable<DrsUserDetailsDto> {
    return this.http.get<DrsUserDetailsDto>('/api/drs/currentUser');
  }

  /**
   * Set user status
   * @returns {Observable<boolean>}
   */
  public setUserStatus(active: boolean): Observable<any> {
    this.userActiveStatusHolder.next(active);
    return this.http.put<any>(`${this.API_DRS_PATH}/${this.USER_PATH}`, { available: active });
  }
}
