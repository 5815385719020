import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { PatientsService } from '../../patients.service';
import { DocumentDetailDto } from '../shared/model/patients/document-detail-dto';

/**
 * Resolves the app data.
 */
@Injectable()
export class DocumentResolverService implements Resolve<any> {

  constructor(private patientsService: PatientsService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<DocumentDetailDto> | Promise<DocumentDetailDto> {
    return this.patientsService.getDocumentById(route.params.patientId, route.params.documentId);
  }
}
