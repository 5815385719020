import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  RegistrationsTableRowDataDto,
  RegistrationStatusEnum
} from '../drs/shared/model/registrations/registrations-table-row-data-dto';
import { UserIdentificationService } from '../drs/shared/services/user-identification-service';
import { Router } from '@angular/router';
import {sortTable} from '../utils/sort';
import {formatStringToDate, getDateTimestamp} from '../utils/date';

@Component({
  selector: 'app-registrations-table',
  templateUrl: './registrations-table.component.html',
  styleUrls: ['./registrations-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RegistrationsTableComponent implements OnInit {

  registrationsData: RegistrationsTableRowDataDto[];

  private formatString = 'HH:mm DD-MM-YYYY';

  constructor(private userIdentificationService: UserIdentificationService, private router: Router) {}

  ngOnInit() {
    this.userIdentificationService.getAllRegistrations().subscribe(response => {
      response.cards.forEach(card => {
        card.dateTimestamp = getDateTimestamp(card.createdAt);
        card.createdAt = formatStringToDate(card.createdAt, this.formatString);

        return card;
      });

      this.registrationsData = response.cards;
    });
  }

  onRowSelected(data: RegistrationsTableRowDataDto) {
    this.router.navigate(['registration/' + data.id]);
  }

  sortRegistrationsTable(event) {
    const statusSortOrder =
      [RegistrationStatusEnum.REQUESTED, RegistrationStatusEnum.ACCEPTED, RegistrationStatusEnum.DENIED];

    sortTable(event, statusSortOrder);
  }
}
