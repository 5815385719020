import {Component, OnDestroy} from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent,
} from '@angular/router';

import { Subscription } from 'rxjs';

import { LoaderService } from './shared/loader/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  private readonly subscription: Subscription;

  constructor(private loaderService: LoaderService, private router: Router) {
    this.subscription = this.router.events.subscribe((event: RouterEvent) => {
      switch (true) {
        case event instanceof NavigationStart: { this.loaderService.startRouting(); break; }
        case event instanceof NavigationEnd: { this.loaderService.stopRouting(); break; }
        case event instanceof NavigationError: { this.loaderService.stopRouting(); break; }
        case event instanceof NavigationCancel: { this.loaderService.stopRouting(); break; }
        default: break;
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
