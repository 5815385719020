import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {UserIdentificationService} from '../../drs/shared/services/user-identification-service';

@Component({
  selector: 'app-avatar-registration-detail',
  templateUrl: './avatar-registration-detail.component.html',
  styleUrls: ['./avatar-registration-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AvatarRegistrationDetailComponent {
  @Input() avatarId: string;
  @Input() checkboxStatusObject;
  @Input() previewModeOn;
  @Output() evaluateRegistration: EventEmitter<any> = new EventEmitter<any>();
  FILE_PATH = UserIdentificationService.USER_IDENTIFICATION_FILE_PATH;

  accept() {
    this.checkboxStatusObject.declineRequest = false;
    this.evaluateRegistration.emit(this.checkboxStatusObject);
  }

  decline() {
    this.checkboxStatusObject.acceptRequest = false;
    this.evaluateRegistration.emit(this.checkboxStatusObject);
  }
}
