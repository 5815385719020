import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DrsComponent } from './drs.component';
import { DrsRoutingModule } from './drs-routing.module';
import { DrsService } from './shared/services/drs.service';
import {
  AccordionModule,
  AutoCompleteModule,
  CheckboxModule,
  ConfirmDialogModule,
  DialogModule,
  DropdownModule,
  MultiSelectModule,
  OrganizationChartModule,
  SelectButtonModule,
  SidebarModule,
} from 'primeng/primeng';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { GrowlModule } from 'primeng/growl';
import { PanelModule } from 'primeng/panel';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { SharedModule } from '../shared/shared.module';
import { ConditionComponent } from './condition/condition.component';
import { DrsConditionService } from './shared/services/drs.condition.service';
import { GraphManagerComponent } from './graph-manager/graph-manager.component';
import { SearchFeatureComponent } from './search-feature/search-feature.component';
import { SearchFeatureService } from './search-feature/search-feature-service';
import { ModalDialogComponent } from './modal-dialog/modal-dialog.component';
import { LiveChatComponent } from '../live-chat/live-chat.component';
import { WaitingRoomComponent } from '../live-chat/waiting-room/waiting-room.component';
import { PatientCardComponent } from '../live-chat/waiting-room/patient-card/patient-card.component';
import { ImageUploaderComponent } from './image-uploader/image-uploader.component';
import { ChatService } from './shared/services/chat.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandlerHttpInterceptor } from './shared/interceptors/error.interceptor';
import { CustomErrorsHandler } from './shared/services/errors-handler';
import { ConsultationPanelComponent } from '../live-chat/consultation-panel/consultation-panel.component';
import { ChatHeaderTimerComponent } from '../live-chat/chat-header-timer/chat-header-timer.component';
import { DrawingService } from './shared/services/drawing.service';
import { ChatUserDetailsComponent } from '../live-chat/consultation-panel/chat-user-details/chat-user-details.component';
import { PreviewPanelComponent } from './preview-panel/preview-panel.component';
import { FileUploadModule } from 'primeng/fileupload';
import { NodeTemplatesEditorComponent } from './node-templates-editor/node-templates-editor.component';
import { RevisionsModalComponent } from './revisions-modal/revisions-modal.component';
import { TableModule } from 'primeng/table';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    DrsRoutingModule,
    FormsModule,
    InputTextModule,
    DropdownModule,
    OrganizationChartModule,
    GrowlModule,
    PanelModule,
    ButtonModule,
    NoopAnimationsModule,
    MultiSelectModule,
    AccordionModule,
    ConfirmDialogModule,
    SidebarModule,
    AutoCompleteModule,
    SelectButtonModule,
    DialogModule,
    FileUploadModule,
    TableModule,
    CheckboxModule
  ],
  providers: [
    DrsService,
    ChatService,
    ConfirmationService,
    DrsConditionService,
    SearchFeatureService,
    DrawingService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerHttpInterceptor,
      multi: true,
    },
    CustomErrorsHandler,
  ],
  declarations: [
    DrsComponent,
    ConditionComponent,
    GraphManagerComponent,
    SearchFeatureComponent,
    ModalDialogComponent,
    ImageUploaderComponent,
    LiveChatComponent,
    WaitingRoomComponent,
    PatientCardComponent,
    ConsultationPanelComponent,
    ChatUserDetailsComponent,
    PreviewPanelComponent,
    NodeTemplatesEditorComponent,
    ChatHeaderTimerComponent,
    RevisionsModalComponent
  ],
})
export class DrsModule {}
