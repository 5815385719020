<div class="ui-fluid">
    <div class="ui-inputgroup">
      <p-autoComplete [(ngModel)]="featureNode"
                      (onSelect)="onFeatureSelect()"
                      (onClear)="onClearFeature()"
                      [suggestions]="typeOfSearchIsFeature ? results : nodeTemplateResults"
                      (completeMethod)="search($event)"
                      field="{{labelField}}"
                      placeholder="{{placeholder}}"
                      #inputElement>
      </p-autoComplete>
      <button pButton
              icon="pi pi-times"
              class="search-cancel"
              type="button"
              [disabled]="noFeatureSelection"
              (click)="onConfirmClearFeature()"></button>
    </div>
</div>

