/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./live-chat.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../node_modules/primeng/components/fileupload/fileupload.ngfactory";
import * as i4 from "primeng/components/fileupload/fileupload";
import * as i5 from "@angular/platform-browser";
import * as i6 from "@angular/common/http";
import * as i7 from "@ngx-translate/core";
import * as i8 from "@angular/forms";
import * as i9 from "primeng/components/inputtext/inputtext";
import * as i10 from "primeng/components/button/button";
import * as i11 from "./waiting-room/waiting-room.component.ngfactory";
import * as i12 from "./waiting-room/waiting-room.component";
import * as i13 from "../skygear.service";
import * as i14 from "../drs/shared/services/chat.service";
import * as i15 from "./chat-header-timer/chat-header-timer.component.ngfactory";
import * as i16 from "./chat-header-timer/chat-header-timer.component";
import * as i17 from "./consultation-panel/consultation-panel.component.ngfactory";
import * as i18 from "./consultation-panel/consultation-panel.component";
import * as i19 from "./live-chat.component";
import * as i20 from "../misanto-security.service";
var styles_LiveChatComponent = [i0.styles];
var RenderType_LiveChatComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_LiveChatComponent, data: {} });
export { RenderType_LiveChatComponent as RenderType_LiveChatComponent };
function View_LiveChatComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "username-message"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.currentUserName || ""); _ck(_v, 1, 0, currVal_0); }); }
function View_LiveChatComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [], [[8, "src", 4], [8, "alt", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.attachment.url, ""); var currVal_1 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.attachment.name, ""); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_LiveChatComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "li", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "left-message": 0, "image-message": 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LiveChatComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "timestamp-message"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵted(8, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LiveChatComponent_3)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, (_co.currentUser && (_v.context.$implicit.createdBy === _co.currentUser._id)), _v.context.$implicit.attachment); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.currentUser && (_v.context.$implicit.createdBy === _co.currentUser._id)); _ck(_v, 5, 0, currVal_1); var currVal_4 = _v.context.$implicit.attachment; _ck(_v, 10, 0, currVal_4); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.createdAt; _ck(_v, 7, 0, currVal_2); var currVal_3 = _v.context.$implicit.message; _ck(_v, 8, 0, currVal_3); }); }
function View_LiveChatComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "chat-input-controls-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 15, "div", [["class", "chat-input-controls"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "p-fileUpload", [["accept", ".png,.jpeg,.jpg,.gif,.bmp,.heif,.heic,.heif-sequence,.heic-sequence"], ["auto", "true"], ["customUpload", "true"], ["mode", "basic"], ["name", "message-image"]], null, [[null, "uploadHandler"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("uploadHandler" === en)) {
        var pd_0 = (_co.onSendImage($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_FileUpload_0, i3.RenderType_FileUpload)), i1.ɵdid(3, 5423104, [[1, 4], ["chatImageUpload", 4]], 1, i4.FileUpload, [i1.ElementRef, i5.DomSanitizer, i1.NgZone, i6.HttpClient], { name: [0, "name"], accept: [1, "accept"], disabled: [2, "disabled"], auto: [3, "auto"], maxFileSize: [4, "maxFileSize"], invalidFileTypeMessageSummary: [5, "invalidFileTypeMessageSummary"], mode: [6, "mode"], customUpload: [7, "customUpload"] }, { uploadHandler: "uploadHandler" }), i1.ɵqud(603979776, 3, { templates: 1 }), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 7, "input", [["id", "message-input"], ["pInputText", ""], ["type", "text"]], [[8, "placeholder", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null], [2, "ui-inputtext", null], [2, "ui-corner-all", null], [2, "ui-state-default", null], [2, "ui-widget", null], [2, "ui-state-filled", null]], [[null, "ngModelChange"], [null, "keyup"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 7)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 7)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("input" === en)) {
        var pd_4 = (i1.ɵnov(_v, 12).onInput($event) !== false);
        ad = (pd_4 && ad);
    } if (("ngModelChange" === en)) {
        var pd_5 = ((_co.message = $event) !== false);
        ad = (pd_5 && ad);
    } if (("keyup" === en)) {
        var pd_6 = (_co.onMessageBoxKeyUp($event) !== false);
        ad = (pd_6 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 16384, null, 0, i8.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i8.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i8.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i8.DefaultValueAccessor]), i1.ɵdid(9, 671744, null, 0, i8.NgModel, [[8, null], [8, null], [8, null], [6, i8.NG_VALUE_ACCESSOR]], { isDisabled: [0, "isDisabled"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i8.NgControl, null, [i8.NgModel]), i1.ɵdid(11, 16384, null, 0, i8.NgControlStatus, [[4, i8.NgControl]], null, null), i1.ɵdid(12, 278528, null, 0, i9.InputText, [i1.ElementRef, [2, i8.NgModel]], null, null), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(14, 0, null, null, 2, "button", [["pButton", ""], ["style", "padding: 5px"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sendMessage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 4341760, null, 0, i10.ButtonDirective, [i1.ElementRef], { label: [0, "label"] }, null), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "message-image"; var currVal_1 = ".png,.jpeg,.jpg,.gif,.bmp,.heif,.heic,.heif-sequence,.heic-sequence"; var currVal_2 = _co.chatInputsDisabled; var currVal_3 = "true"; var currVal_4 = _co.maxUploadFileSizeBytes; var currVal_5 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 3, 5, i1.ɵnov(_v, 5).transform("document.upload.toastMessage.invalidType.summary")), ""); var currVal_6 = "basic"; var currVal_7 = "true"; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_21 = _co.chatInputsDisabled; var currVal_22 = _co.message; _ck(_v, 9, 0, currVal_21, currVal_22); _ck(_v, 12, 0); var currVal_24 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 15, 0, i1.ɵnov(_v, 16).transform("btn.chat.send")), ""); _ck(_v, 15, 0, currVal_24); }, function (_ck, _v) { var _co = _v.component; var currVal_8 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 13).transform("chat.textField.placeholder")), ""); var currVal_9 = i1.ɵnov(_v, 11).ngClassUntouched; var currVal_10 = i1.ɵnov(_v, 11).ngClassTouched; var currVal_11 = i1.ɵnov(_v, 11).ngClassPristine; var currVal_12 = i1.ɵnov(_v, 11).ngClassDirty; var currVal_13 = i1.ɵnov(_v, 11).ngClassValid; var currVal_14 = i1.ɵnov(_v, 11).ngClassInvalid; var currVal_15 = i1.ɵnov(_v, 11).ngClassPending; var currVal_16 = true; var currVal_17 = true; var currVal_18 = true; var currVal_19 = true; var currVal_20 = i1.ɵnov(_v, 12).filled; _ck(_v, 6, 1, [currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17, currVal_18, currVal_19, currVal_20]); var currVal_23 = _co.chatInputsDisabled; _ck(_v, 14, 0, currVal_23); }); }
export function View_LiveChatComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { chatImageUpload: 0 }), i1.ɵqud(402653184, 2, { chatWindow: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 15, "div", [["class", "tab-menu-mobile-chat"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 14, "ul", [["class", "tab-list-chat"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 6, "li", [["class", "tab-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activateWaitingRoomTab() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(6, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(7, { "tab-item-active": 0 }), (_l()(), i1.ɵeld(8, 0, null, null, 2, "a", [["class", "tab-item-text"], ["href", "#"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, null, null, 6, "li", [["class", "tab-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activateChatTab() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(13, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(14, { "tab-item-active": 0 }), (_l()(), i1.ɵeld(15, 0, null, null, 2, "a", [["class", "tab-item-text"], ["href", "#"]], null, null, null, null, null)), (_l()(), i1.ɵted(16, null, [" ", " "])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(18, 0, null, null, 1, "app-waiting-room", [], null, [[null, "selectedPatient"], [null, "finishedConversation"], [null, "restartChatPanel"], [null, "setNewPatient"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedPatient" === en)) {
        var pd_0 = (_co.onSelectPatient($event) !== false);
        ad = (pd_0 && ad);
    } if (("finishedConversation" === en)) {
        var pd_1 = (_co.onFinishConversation($event) !== false);
        ad = (pd_1 && ad);
    } if (("restartChatPanel" === en)) {
        var pd_2 = (_co.restartChatPanel() !== false);
        ad = (pd_2 && ad);
    } if (("setNewPatient" === en)) {
        var pd_3 = (_co.setNewPatient($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i11.View_WaitingRoomComponent_0, i11.RenderType_WaitingRoomComponent)), i1.ɵdid(19, 245760, null, 0, i12.WaitingRoomComponent, [i13.SkygearService, i14.ChatService, i1.NgZone], { messageReceived: [0, "messageReceived"], activeClass: [1, "activeClass"], conversationFinishedSuccessfully: [2, "conversationFinishedSuccessfully"] }, { selectedPatient: "selectedPatient", finishedConversation: "finishedConversation", setNewPatient: "setNewPatient", restartChatPanel: "restartChatPanel" }), (_l()(), i1.ɵeld(20, 0, null, null, 17, "div", [["class", "chat-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 11, "div", [["class", "chat-inner-warapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 4, "div", [["style", "display: flex;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 1, "app-chat-header-timer", [], null, null, null, i15.View_ChatHeaderTimerComponent_0, i15.RenderType_ChatHeaderTimerComponent)), i1.ɵdid(24, 573440, null, 0, i16.ChatHeaderTimerComponent, [i7.TranslateService], { patient: [0, "patient"] }, null), (_l()(), i1.ɵeld(25, 0, null, null, 1, "button", [["class", "ui-button-primary consultation-btn"], ["icon", "pi pi-comments"], ["pButton", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showConsultationPanel(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(26, 4341760, null, 0, i10.ButtonDirective, [i1.ElementRef], { icon: [0, "icon"] }, null), (_l()(), i1.ɵeld(27, 0, [[2, 0], ["chatWindow", 1]], null, 3, "div", [["class", "chat"]], null, null, null, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LiveChatComponent_1)), i1.ɵdid(30, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LiveChatComponent_4)), i1.ɵdid(32, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(33, 0, null, null, 4, "app-consultation-panel", [["class", "consultation-wrapper"]], null, [[null, "closeConsultationPanel"], [null, "freeChatChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closeConsultationPanel" === en)) {
        var pd_0 = (_co.showConsultationPanel($event) !== false);
        ad = (pd_0 && ad);
    } if (("freeChatChange" === en)) {
        var pd_1 = (_co.onFreeChatCheckChanged($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i17.View_ConsultationPanelComponent_0, i17.RenderType_ConsultationPanelComponent)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(35, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(36, { "show": 0 }), i1.ɵdid(37, 573440, null, 0, i18.ConsultationPanelComponent, [], { consultation: [0, "consultation"], freeChatCheckboxEnabled: [1, "freeChatCheckboxEnabled"] }, { freeChatChange: "freeChatChange", closeConsultationPanel: "closeConsultationPanel" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "tab-item"; var currVal_1 = _ck(_v, 7, 0, _co.waitingRoomTabActive); _ck(_v, 6, 0, currVal_0, currVal_1); var currVal_3 = "tab-item"; var currVal_4 = _ck(_v, 14, 0, _co.chatTabActive); _ck(_v, 13, 0, currVal_3, currVal_4); var currVal_6 = _co.messageReceived; var currVal_7 = _co.waitingRoomTabActive; var currVal_8 = _co.conversationFinishedSuccessfully; _ck(_v, 19, 0, currVal_6, currVal_7, currVal_8); var currVal_9 = _co.currentPatient; _ck(_v, 24, 0, currVal_9); var currVal_10 = "pi pi-comments"; _ck(_v, 26, 0, currVal_10); var currVal_11 = _co.allMessages; _ck(_v, 30, 0, currVal_11); var currVal_12 = !_co.chatInputHidden; _ck(_v, 32, 0, currVal_12); var currVal_13 = "consultation-wrapper"; var currVal_14 = _ck(_v, 36, 0, _co.isConsultationPanelVisible); _ck(_v, 35, 0, currVal_13, currVal_14); var currVal_15 = _co.currentConsultation; var currVal_16 = _co.canActivateFreeChat; _ck(_v, 37, 0, currVal_15, currVal_16); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("waitingRoom.tab.label")); _ck(_v, 9, 0, currVal_2); var currVal_5 = i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 17).transform("chat.tab.label")); _ck(_v, 16, 0, currVal_5); }); }
export function View_LiveChatComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-live-chat", [], null, null, null, View_LiveChatComponent_0, RenderType_LiveChatComponent)), i1.ɵdid(1, 245760, null, 0, i19.LiveChatComponent, [i13.SkygearService, i14.ChatService, i7.TranslateService, i20.MisantoSecurityService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LiveChatComponentNgFactory = i1.ɵccf("app-live-chat", i19.LiveChatComponent, View_LiveChatComponent_Host_0, {}, { conversationFinishedSuccessfully: "conversationFinishedSuccessfully" }, []);
export { LiveChatComponentNgFactory as LiveChatComponentNgFactory };
