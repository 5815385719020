import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Patient } from '../patient';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../core/user.service';

@Component({
  selector: 'app-patient-card',
  templateUrl: './patient-card.component.html',
  styleUrls: ['./patient-card.component.scss'],
})
export class PatientCardComponent {
  @Input() patient: Patient;
  @Input() isSelected: boolean;
  @Output() selectedPatient = new EventEmitter<Patient>();
  @Output() finishedConversation = new EventEmitter<Patient>();
  finishButtonDisabled = false;

  canClaimChat$ = this.userService.userActive$;

  constructor(private translateService: TranslateService, private userService: UserService) {}

  joinConversation(patient: Patient) {
    console.log('[PatientCard] Open conversation', patient.misantoConversation);
    this.onSelectPatient(patient);
  }

  onSelectPatient(patient: Patient) {
    this.patient.unreadMessageCount = 0;
    this.selectedPatient.emit(patient);
  }

  getWaitingText(patient: Patient) {
    return this.translateService.instant('chat.waiting.since.text', {
      0: patient.waitingTime,
    });
  }

  finishConversation() {
    console.log('[PatientCard] End conversation');
    this.finishedConversation.emit(this.patient);
    this.finishButtonDisabled = true;
  }

  isClaimButtonVisible(): boolean {
    return !this.isSelected && this.patient.isInStateWaitingForDoctor();
  }

  isOpenButtonVisible(): boolean {
    if (this.patient.isInStateWaitingForDoctor()) {
      return false;
    }
    if (this.isInOneOfTheOngoingStates()) {
      return !this.isSelected;
    }
    return true;
  }

  isFinishButtonVisible(): boolean {
    return this.isSelected && this.isInOneOfTheOngoingStates();
  }

  private isInOneOfTheOngoingStates() {
    return this.patient.isInStateWaitingForPatient() || this.patient.isInStateOngoing() || this.patient.isInStateFinishedByPatient();
  }
}
