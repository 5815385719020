import { isNil } from 'lodash';

/**
 * Provides common predicates for use in the filter operator inside an observable stream.
 */
export const Predicates = {
  noErrors: <T>(value: T, index: number): boolean => {
    if (isNil(value)) {
      return true;
    }
    return !value.hasOwnProperty('error');
  }
};
