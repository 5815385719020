import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {HealthInsuranceCardOverviewResponse} from '../model/health-insurance-card/health-insurance-card-overview';
import {HealthInsuranceCardDetails} from '../model/health-insurance-card/health-insurance-card-details';
import {HealthInsuranceCardUpdateRequest} from '../model/health-insurance-card/health-insurance-card-update-request';

/**
 * A service for health insurance card operations.
 */
@Injectable()
export class HealthInsuranceCardService {

  public static HEALTH_INSURANCE_CARD_IMAGE_PATH = '/api/health-insurance/card-image/';

  private HEALTH_INSURANCE_CARD_PATH = '/api/health-insurance/card';

  constructor(private http: HttpClient) {}

  /**
   * Gets all of the health insurance cards.
   */
  getAllCards(): Observable<HealthInsuranceCardOverviewResponse> {
    return this.http.get<HealthInsuranceCardOverviewResponse>(this.HEALTH_INSURANCE_CARD_PATH);
  }

  /**
   * Finds a health insurance card by id.
   *
   * @param id the id of the health insurance card.
   */
  getHealthInsuranceCard(id: number): Observable<HealthInsuranceCardDetails> {
    return this.http.get<HealthInsuranceCardDetails>(`${this.HEALTH_INSURANCE_CARD_PATH}/${id}`);
  }

  /**
   * Updates the status of the health insurance card.
   *
   * @param id the id of the health insurance card
   * @param cardStatusUpdate the updated status
   */
  updateCardStatus(id: number, cardStatusUpdate: HealthInsuranceCardUpdateRequest):
    Observable<HealthInsuranceCardUpdateRequest> {

    return this.http.patch<HealthInsuranceCardUpdateRequest>(`${this.HEALTH_INSURANCE_CARD_PATH}/${id}`,
      cardStatusUpdate);
  }
}
