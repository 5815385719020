import { Component, Input, OnChanges } from '@angular/core';
import { Patient } from '../waiting-room/patient';
import { TranslateService } from '@ngx-translate/core';
import { Conversation } from '../../drs/shared/model/chat/conversation';

@Component({
  selector: 'app-chat-header-timer',
  templateUrl: './chat-header-timer.component.html',
  styleUrls: ['./chat-header-timer.component.scss']
})
export class ChatHeaderTimerComponent implements OnChanges {
  millisecondsInOneSecond = 1000;
  chatTimeLimitInMinutes = 19; // the timer in the UI appears in red as soon as this time limit is reached
  customerWaitingTimeLabel = '';
  customerWaitingTime = '';
  timerInterval;
  timerIsAboveTimeLimit: boolean;
  @Input() patient: Patient;

  constructor(private translateService: TranslateService) {}

  ngOnChanges() {
    if (this.patient) {
      this.changeHeaderText(this.patient);
    } else {
      clearInterval(this.timerInterval);
      this.customerWaitingTimeLabel = '';
      this.customerWaitingTime = '';
    }
  }

  changeHeaderText(patient: Patient) {
    clearInterval(this.timerInterval);
    this.customerWaitingTime = '';
    const patientName: string = patient.name ? patient.name : '';
    const textInterpolateParams: Object = {
      0: patientName
    };
    if (patient.isInStateWaitingForPatient()) {
      this.customerWaitingTimeLabel = this.translateService.instant('chat.header.timer.label.patient.waiting', textInterpolateParams);
      this.customerWaitingTime = '00:00';
    } else if (patient.isInStateOngoing()) {
      this.customerWaitingTimeLabel = this.translateService.instant('chat.header.timer.label.patient.chatting', textInterpolateParams);
      if (patient.misantoConversation.chatStartedAt) {
        this.timerInterval = setInterval(() => {
          this.customerWaitingTime = this.startCounter(patient.misantoConversation);
        }, this.millisecondsInOneSecond);
      }
    } else if (patient.isInStateDeclined()) {
      this.customerWaitingTimeLabel = this.translateService.instant('chat.header.timer.label.patient.declined', textInterpolateParams);
    } else if (patient.isInStateFinishedByPatient() || patient.isInStateDone()) {
      if (patient.isInStateDone()) {
        this.customerWaitingTimeLabel = this.translateService.instant('chat.header.timer.label.finished');
      } else {
        this.customerWaitingTimeLabel = this.translateService.instant('chat.header.timer.label.patient.finished', textInterpolateParams);
      }
      const difference =
        this.getDifferenceBetweenTwoDates(patient.misantoConversation.chatStartedAt, patient.misantoConversation.chatFinishedAt);
      this.customerWaitingTime = difference ? this.padWithZero(difference[0]) + ':' + this.padWithZero(difference[1]) : '';
    }
  }

  startCounter(conversation: Conversation) {
    const diff = this.getDifferenceBetweenTwoDates(conversation.chatStartedAt, Date.now());
    let minutes: any = diff[0];
    let seconds: any = diff[1];

    this.timerIsAboveTimeLimit = minutes > this.chatTimeLimitInMinutes;
    minutes = this.padWithZero(minutes);
    seconds = this.padWithZero(seconds);
    return minutes + ':' + seconds;
  }

  getDifferenceBetweenTwoDates(firstDate, secondDate): number[] {
    if (!firstDate || !secondDate) {
      return;
    }
    const end = new Date(firstDate).getTime();
    const start = new Date(secondDate).getTime();

    const diff = Math.abs(end - start) / 1000;

    const minutes: any = Math.floor(diff / 60);
    const seconds: any = Math.floor(diff % 60);

    return [minutes, seconds];
  }

  padWithZero(number) {
    return number < 10 ? '0' + number : number;
  }
}
