import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserService } from './user.service';
import { AppCoreService } from './services/app-core.service';

/**
 * Authentication Guard Service.
 * Checks whether the user is authenticated.
 * If yes, the user can proceed, otherwise login is required.
 * Use this service as route guard.
 * ex. {
    path: '',
    component: GraphManagerComponent,
    canActivate: [AuthGuardService],
    resolve: {
      drsGraphs: DrsGraphsResolverService
    }
  }
 */
@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private userService: UserService, private appCoreService: AppCoreService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.userService.isValid().pipe(
      map(response => {
        if (response === true) {
          return true;
        } else {
          this.appCoreService.redirectToLoginPage();
          return false;
        }
      },
      error => {
        throw new Error(error);
      },
    ));
  }
}
