import {Component, OnInit} from '@angular/core';
import {HealthInsuranceCardDetails} from '../drs/shared/model/health-insurance-card/health-insurance-card-details';
import {ActivatedRoute, Router} from '@angular/router';
import {flatMap} from 'rxjs/operators';
import {CalendarProps} from '../shared/calendar/calendar-props';
import {createYearRange} from '../shared/calendar/calendar-utils';
import {HealthInsuranceCardService} from '../drs/shared/services/health-insurance-card-service';
import {HealthInsuranceCardUpdateRequest} from '../drs/shared/model/health-insurance-card/health-insurance-card-update-request';
import {HealthInsuranceCardUpdateStatus} from '../drs/shared/model/health-insurance-card/health-insurance-card-update-status';
import {HealthInsuranceCardStatus} from '../drs/shared/model/health-insurance-card/health-insurance-card-status';

@Component({
  selector: 'app-health-insurance-card-details',
  templateUrl: './health-insurance-card-details.component.html',
  styleUrls: ['./health-insurance-card-details.component.scss']
})
export class HealthInsuranceCardDetailsComponent implements OnInit {

  healthInsuranceCard: HealthInsuranceCardDetails;

  readOnlyMode = true;
  disableAcceptButton: boolean;
  disableRejectButton: boolean;

  dateFormat = 'dd.mm.yy';
  today = new Date();
  year = this.today.getFullYear();

  dateOfBirthProps: CalendarProps;
  expiryDateProps: CalendarProps;

  inputPatterns = {
    firstName: '^[A-Z\x7f-\xff\' .-]+$',
    lastName: '^[A-Z\x7f-\xff\' .-]+$',
    personalIdNumber: '^(756)\\.([0-9]{4})\\.([0-9]{4})\\.([0-9]{2})$',
    institutionIdNumber: '^[0-9]+ - [a-zA-Z0-9 ]+$',
    cardIdNumber: '^[0-9]{20}$'
  };

  constructor(
    private healthInsuranceService: HealthInsuranceCardService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.params.pipe(
      flatMap(params => {
        const cardId: number = +params['id'];
        return this.healthInsuranceService.getHealthInsuranceCard(cardId);
      }))
      .subscribe((data: HealthInsuranceCardDetails) => {

        this.healthInsuranceCard = data;

        this.disableAcceptButton = this.healthInsuranceCard.status === HealthInsuranceCardStatus.ACCEPTED;
        this.disableRejectButton = this.healthInsuranceCard.status === HealthInsuranceCardStatus.REJECTED;

        this.dateOfBirthProps = this.initDateOfBirth(data.dateOfBirth);
        this.expiryDateProps = this.initExpiryDate(data.expiryDate);
      });
  }

  onAcceptCardClick() {
    this.updateCardStatus(HealthInsuranceCardUpdateStatus.ACCEPTED);
  }

  onRejectCardClick() {
    this.updateCardStatus(HealthInsuranceCardUpdateStatus.REJECTED);
  }

  private updateCardStatus(updateStatus: HealthInsuranceCardUpdateStatus) {
    const cardStatusUpdate: HealthInsuranceCardUpdateRequest = {
      status: updateStatus,
    };

    this.healthInsuranceService.updateCardStatus(this.healthInsuranceCard.id, cardStatusUpdate).subscribe(() => {
      this.router.navigateByUrl('/health-insurance-card');
    });
  }

  private initDateOfBirth(dateOfBirth: string) {
    return {
      value: new Date(dateOfBirth),
      yearRange: createYearRange(1900, this.year),
      maxDate: this.today
    };
  }

  private initExpiryDate(expiryDate: string) {
    return {
      value: new Date(expiryDate),
      yearRange: createYearRange(this.year, this.year + 11),
      minDate: this.today
    };
  }
}
