import * as skygearAPI from 'skygear';

/**
 * Represents the skygear model (until they will provide typing files).
 */
export interface SkygearEntity {
  id: string;
  _id: string;
}

export interface SkygearUser extends skygearAPI.Record.User, SkygearEntity {
  _id: string;
  misantoId: string;
  username: string;
}

export interface SkygearMessage {
  record_type: string;
  event_type: string;
  record: SkygearMessageRecord;
}

export interface SkygearMessageRecord {
  conversation: SkygearConversation;
  createdAt: Date;
  createdBy: string;
  body: string;
  metadata: SkygearMetadata;
  attachment?: SkygearRecordAttachment;
}

export interface SkygearConversation extends SkygearEntity {
  _id: string;
  participant_ids: string[];
  unread_count: number;
}

export interface SkygearMetadata extends SkygearEntity {
  type: string;
}

export interface SkygearRecordAttachment {
  contentType: string;
  file: File;
  name: string;
  url: string;
}

export class SkygearHelper {
  static getCoreId(entity: SkygearEntity): string {
    return entity._id.split('/')[1];
  }
}
