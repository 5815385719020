<app-patient-header></app-patient-header>
<form #registrationRequestForm="ngForm" (submit)="onSubmit()">
  <div class="header-container">
    <div class="avatar-container">
      <app-avatar-registration-detail [previewModeOn]="previewModeOn"
                                      [avatarId]="getCardSelfieImageId()"
                                      [checkboxStatusObject]="checkboxStatusObject"
                                      (evaluateRegistration)="setRegistrationStatus($event)"></app-avatar-registration-detail>
      <div class="save-buttons-control-panel">
        <button pButton label="{{'btn.cancel' | translate}}" class="ui-button-secondary" (click)="cancel()"></button>
        <button pButton label="{{'btn.save' | translate}}" type="submit"
                [disabled]="!requestIsDenied && (!registrationRequestForm.form.valid || !statusSet) || previewModeOn"></button>
      </div>
    </div>
    <app-card-image-registration-detail [cardTitle]="'registrations.ID.card.front.header.label' | translate"
                                        [cardImgId]="getCardFrontImageId()">
      <div class="input-container">
        <span>{{'registrations.userIdentification.firstName.label' | translate}}</span>
        <input name="first-name" type="text" placeholder="{{'registrations.userIdentification.firstName.label' | translate}}"
               pInputText [(ngModel)]="registration.firstName"
               [disabled]="previewModeOn" autocomplete="off" required/>
      </div>
      <div class="input-container">
        <span>{{'registrations.userIdentification.lastName.label' | translate}}</span>
        <input name="last-name" type="text" placeholder="{{'registrations.userIdentification.lastName.label' | translate}}"
               pInputText [(ngModel)]="registration.lastName"
               [disabled]="previewModeOn" autocomplete="off" required/>
      </div>
      <div class="input-container">
        <span>{{'registrations.userIdentification.streetName.label' | translate}}</span>
        <input name="street-name" type="text" placeholder="{{'registrations.userIdentification.streetName.label' | translate}}"
               pInputText [(ngModel)]="registration.streetName"
               [disabled]="previewModeOn" autocomplete="off" required/>
      </div>
      <div class="input-container">
        <span>{{'registrations.userIdentification.streetNumber.label' | translate}}</span>
        <input name="street-number" type="text" placeholder="{{'registrations.userIdentification.streetNumber.label' | translate}}"
               pInputText [(ngModel)]="registration.streetNumber"
               [disabled]="previewModeOn" autocomplete="off" required/>
      </div>
      <div class="input-container">
        <span>{{'registrations.userIdentification.zipCode.label' | translate}}</span>
        <input name="zip-code" type="number" placeholder="{{'registrations.userIdentification.zipCode.label' | translate}}"
               pInputText [(ngModel)]="registration.zipCode"
               [disabled]="previewModeOn" autocomplete="off" required/>
      </div>
      <div class="input-container">
        <span>{{'registrations.userIdentification.city.label' | translate}}</span>
        <input name="city" type="text" placeholder="{{'registrations.userIdentification.city.label' | translate}}"
               pInputText [(ngModel)]="registration.city"
               [disabled]="previewModeOn" autocomplete="off" required/>
      </div>
    </app-card-image-registration-detail>
  </div>

  <app-card-image-registration-detail [cardTitle]="'registrations.ID.card.back.header.label' | translate"
                                      [cardImgId]="getCardBackImageId()">
    <div class="input-container">
      <span>{{'registrations.userIdentification.idNumber.label' | translate}}</span>
      <input name="id-number" type="text" placeholder="{{'registrations.userIdentification.idNumber.label' | translate}}"
             pInputText [(ngModel)]="registration.idNumber"
             [disabled]="previewModeOn" autocomplete="off" required/>
    </div>
  </app-card-image-registration-detail>
</form>
