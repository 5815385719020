import { DrsAnswerDto } from './drs-answer-dto';
import { DrsEdgeDto } from './drs-edge-dto';
import { DrsVersionDto } from './drs-version-dto';

/**
 * DrsNodeDataDto.
 */
export interface DrsNodeDataDto {
  question?: string;
  description?: string;
  possibleAnswers?: DrsAnswerDto[];
  name?: string;
  edge?: DrsEdgeDto;
  featureUuid?: string;
  featureVersionUuid?: string;
  featureVersion?: DrsVersionDto;
  linkedGraphId?: number;
  linkedGraphFullIdentifier?: string;
  featureType?: FeatureType;
  featureRepresentationType?: FeatureRepresentationType;
  featureImage?: FormData;
  featureImageId?: number;
  aspectRatio?: number;
  featureId?: number;
  featureFullIdentifier?: string;
}

export class DrsNodeDataDtoImpl implements DrsNodeDataDto {
  possibleAnswers: DrsAnswerDto[];

  constructor() {
    this.possibleAnswers = [];
  }
}

export enum FeatureType {
  STATUS = 'STATUS',
  CHAT = 'CHAT',
  PROCEDURE = 'PROCEDURE',
  DIAGNOSIS = 'DIAGNOSIS'
}

export enum FeatureRepresentationType {
  RADIO_BUTTON = 'RADIO_BUTTON',
  CLICKABLE_MAP = 'CLICKABLE_MAP'
}

export enum NodeType {
  FEATURE_NODE = 'FEATURE_NODE',
  LINK_NODE = 'LINK_NODE',
}
