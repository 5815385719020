import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-app-container',
  templateUrl: './app-container.component.html',
  styleUrls: ['./app-container.component.scss']
})
export class AppContainerComponent implements OnInit {
  sidebarActive: boolean;

  public constructor(
    private titleService: Title,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.titleService.setTitle(this.translateService.instant('drs.head.title'));
  }

  onMenuButtonClick(event: Event) {
    this.sidebarActive = !this.sidebarActive;
    event.preventDefault();
  }
}
