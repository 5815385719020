<div class="ui-grid" *ngIf="healthInsuranceCard">
  <div class="ui-grid-col-4">
    <div class="ui-grid">
      <div class="ui-grid-col-12">
        <app-card-image-registration-detail [cardTitle]="'registrations.ID.card.front.header.label' | translate"
                                        [cardImgId]="healthInsuranceCard.userIdentificationCard.frontImageId">
        </app-card-image-registration-detail>
      </div>
      <div class="ui-grid-col-12">
        <app-card-image-registration-detail [cardTitle]="'registrations.ID.card.back.header.label' | translate"
                                        [cardImgId]="healthInsuranceCard.userIdentificationCard.backImageId">
        </app-card-image-registration-detail>
      </div>
    </div>
  </div>
  <div class="health-insurance-card-container ui-grid-col-8">
    <div class="ui-grid">
      <div class="ui-grid-9">
        <app-health-insurance-card-image
          [cardImgId]="healthInsuranceCard.backCardImageId">
        </app-health-insurance-card-image>
      </div>
      <div class="ui-grid-col-3">
      </div>
    </div>
    <div class="ui-grid">
      <div class="ui-grid-col-6">
        <div class="input-container">
          <span>{{'healthInsuranceCard.details.firstName' | translate}}</span>
          <input name="first-name" type="text"
                 placeholder="{{'healthInsuranceCard.details.firstName' | translate}}"
                 pInputText [(ngModel)]="healthInsuranceCard.firstName"
                 [disabled]="readOnlyMode"
                 minlength="2"
                 maxlength="255"
                 [pattern]="inputPatterns.firstName"
                 autocomplete="off"
                 required/>
        </div>
        <div class="input-container">
          <span>{{'healthInsuranceCard.details.lastName' | translate}}</span>
          <input name="last-name" type="text"
                 placeholder="{{'healthInsuranceCard.details.lastName' | translate}}"
                 pInputText [(ngModel)]="healthInsuranceCard.lastName"
                 [disabled]="readOnlyMode"
                 minlength="2"
                 maxlength="255"
                 [pattern]="inputPatterns.lastName"
                 autocomplete="off"
                 required/>
        </div>
        <div class="input-container">
          <span>{{'healthInsuranceCard.details.dateOfBirth' | translate}}</span>
          <p-calendar
            [(ngModel)]="dateOfBirthProps.value"
            [yearRange]="dateOfBirthProps.yearRange"
            [maxDate]="dateOfBirthProps.maxDate" [yearNavigator]="true"
            [monthNavigator]="true"
            [dateFormat]="dateFormat"
            [disabled]="readOnlyMode"
            required></p-calendar>
        </div>
        <div class="input-container">
          <span>{{'healthInsuranceCard.details.personalIdNumber' | translate}}</span>
          <input name="personal-id-number" type="text"
                 placeholder="{{'healthInsuranceCard.details.personalIdNumber' | translate}}"
                 pInputText [(ngModel)]="healthInsuranceCard.personalIdNumber"
                 [disabled]="readOnlyMode"
                 [pattern]="inputPatterns.personalIdNumber"
                 autocomplete="off"
                 required/>
        </div>
      </div>
      <div class="ui-grid-col-6 left-input">
        <div class="input-container">
          <span>{{'healthInsuranceCard.details.institutionIdNumber' | translate}}</span>
          <input name="institution-id-number" type="text"
                 placeholder="{{'healthInsuranceCard.details.institutionIdNumber' | translate}}"
                 pInputText [(ngModel)]="healthInsuranceCard.institutionIdNumber"
                 [disabled]="readOnlyMode"
                 [pattern]="inputPatterns.institutionIdNumber"
                 autocomplete="off"
                 required/>
        </div>
        <div class="input-container">
          <span>{{'healthInsuranceCard.details.cardIdNumber' | translate}}</span>
          <input name="card-id-number" type="text"
                 placeholder="{{'healthInsuranceCard.details.cardIdNumber' | translate}}"
                 pInputText [(ngModel)]="healthInsuranceCard.cardIdNumber"
                 [disabled]="readOnlyMode"
                 [pattern]="inputPatterns.cardIdNumber"
                 autocomplete="off"
                 required/>
        </div>
        <div class="input-container">
          <span>{{'healthInsuranceCard.details.expiryDate' | translate}}</span>
          <p-calendar
            [(ngModel)]="expiryDateProps.value"
            [minDate]="expiryDateProps.minDate"
            [yearRange]="expiryDateProps.yearRange"
            [yearNavigator]="true"
            [monthNavigator]="true"
            [dateFormat]="dateFormat"
            [disabled]="readOnlyMode"
            required></p-calendar>
        </div>
      </div>
    </div>
    <div>
      <button pButton label="{{'healthInsuranceCard.btn.validate' | translate}}" type="submit"
              [disabled]="readOnlyMode"></button>
    </div>
    <div>
      <div class="save-buttons-control-panel">
        <button pButton label="{{'healthInsuranceCard.btn.accept' | translate}}"
                (click)="onAcceptCardClick()"
                [disabled]="disableAcceptButton"
                type="submit"></button>
        <button pButton label="{{'healthInsuranceCard.btn.deny' | translate}}"
                (click)="onRejectCardClick()"
                [disabled]="disableRejectButton"
                type="submit"></button>
      </div>
    </div>
  </div>
</div>

