import {Component, Input, OnInit} from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-delete-confirmation-modal',
  templateUrl: './delete-confirmation-modal.component.html',
})
export class DeleteConfirmationModalComponent implements OnInit {
  @Input() modalKey: string; // Unique key for every single modal to enable multiple usage at the same page
  @Input() header: string; // String that will be used as header
  @Input() headerKey: string; // Key whose text will be used as header
  @Input() message: string; // String that will be used as message
  @Input() messageKey: string; // Key whose text will be used as message

  constructor(private confirmationService: ConfirmationService, private translateService: TranslateService) {}

  ngOnInit() {
    this.header = this.header
      || (this.headerKey && this.translateService.instant(this.headerKey))
      || this.translateService.instant('modal.confirmation.delete.header');

    this.message = this.message
      || (this.messageKey && this.translateService.instant(this.messageKey))
      || this.translateService.instant('modal.confirmation.delete.message');
  }

  public showModal(acceptCallback: () => void, rejectCallback: () => void): void {
    this.confirmationService.confirm({
      header: this.header,
      message: this.message,
      accept: () => {
        if (acceptCallback) { acceptCallback(); }
      },
      reject: () => {
        if (rejectCallback) {rejectCallback(); }
      },
    });
  }
}
