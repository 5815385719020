import { Injectable } from '@angular/core';
import { UserService } from './core/user.service';
import { DrsUserDetailsDto } from './drs/shared/model/drs-user-details-dto';
import { Observable } from 'rxjs';

@Injectable()
export class MisantoSecurityService {
  userDetails: Observable<DrsUserDetailsDto>;

  constructor(private userService: UserService) {
    this.userDetails = this.userService.getUserDetails();
  }
}
