/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./preview-panel.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "../../bypass-security.pipe";
import * as i5 from "@angular/platform-browser";
import * as i6 from "../../../../node_modules/primeng/components/sidebar/sidebar.ngfactory";
import * as i7 from "primeng/components/sidebar/sidebar";
import * as i8 from "../../../../node_modules/primeng/components/dialog/dialog.ngfactory";
import * as i9 from "primeng/components/dialog/dialog";
import * as i10 from "./preview-panel.component";
var styles_PreviewPanelComponent = [i0.styles];
var RenderType_PreviewPanelComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PreviewPanelComponent, data: {} });
export { RenderType_PreviewPanelComponent as RenderType_PreviewPanelComponent };
function View_PreviewPanelComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "preview-show-help-text-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onShowHelpText() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("drs.btn.preview.showMore.label")); _ck(_v, 1, 0, currVal_0); }); }
function View_PreviewPanelComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "object", [["class", "pdf-viewer"], ["type", "image/png"]], [[8, "data", 5]], null, null, null, null)), i1.ɵppd(1, 1), (_l()(), i1.ɵeld(2, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 2, "a", [], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent.parent.parent, 0), _co.imageUrl)); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("apip.header.pdf.viewer.error.message")); _ck(_v, 3, 0, currVal_1); var currVal_2 = i1.ɵinlineInterpolate(1, "", _co.imageUrl, ""); _ck(_v, 5, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("apip.header.pdf.viewer.error.link.label")); _ck(_v, 6, 0, currVal_3); }); }
function View_PreviewPanelComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "preview-clickable-map-image"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PreviewPanelComponent_4)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imageUrl; _ck(_v, 2, 0, currVal_0); }, null); }
function View_PreviewPanelComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_PreviewPanelComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [["class", "preview-question"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PreviewPanelComponent_2)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "preview-answers-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PreviewPanelComponent_3)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [["class", "preview-answers"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PreviewPanelComponent_5)), i1.ɵdid(11, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.previewData.helpText; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.previewData.imageId; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.previewData.possibleAnswers; _ck(_v, 11, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.previewData.question; _ck(_v, 2, 0, currVal_0); }); }
function View_PreviewPanelComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.previewData.helpText; _ck(_v, 1, 0, currVal_0); }); }
export function View_PreviewPanelComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.BypassSecurityPipe, [i5.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 4, "p-sidebar", [["class", "preview-sidebar"]], null, [[null, "onHide"], [null, "visibleChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onHide" === en)) {
        var pd_0 = (_co.onPopupClose() !== false);
        ad = (pd_0 && ad);
    } if (("visibleChange" === en)) {
        var pd_1 = ((_co.isPreviewSidebarOpen = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_Sidebar_0, i6.RenderType_Sidebar)), i1.ɵdid(2, 12763136, null, 0, i7.Sidebar, [i1.ElementRef, i1.Renderer2], { style: [0, "style"], modal: [1, "modal"], visible: [2, "visible"] }, { onHide: "onHide", visibleChange: "visibleChange" }), i1.ɵpod(3, { width: 0 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_PreviewPanelComponent_1)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 7, "p-dialog", [], null, [[null, "visibleChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.isPreviewSidebarOpen && (_co.displayHelpText = $event)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_Dialog_0, i8.RenderType_Dialog)), i1.ɵdid(7, 180224, null, 2, i9.Dialog, [i1.ElementRef, i1.Renderer2, i1.NgZone], { visible: [0, "visible"], header: [1, "header"], style: [2, "style"] }, { visibleChange: "visibleChange" }), i1.ɵqud(603979776, 1, { headerFacet: 1 }), i1.ɵqud(603979776, 2, { footerFacet: 1 }), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), i1.ɵpod(11, { minWidth: 0 }), (_l()(), i1.ɵand(16777216, null, 1, 1, null, View_PreviewPanelComponent_6)), i1.ɵdid(13, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, "450px"); var currVal_1 = false; var currVal_2 = _co.isPreviewSidebarOpen; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.previewData; _ck(_v, 5, 0, currVal_3); var currVal_4 = (_co.isPreviewSidebarOpen && _co.displayHelpText); var currVal_5 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 7, 1, i1.ɵnov(_v, 10).transform("drs.preview.helpText.popup.title")), ""); var currVal_6 = _ck(_v, 11, 0, 350); _ck(_v, 7, 0, currVal_4, currVal_5, currVal_6); var currVal_7 = (_co.previewData && _co.previewData.helpText); _ck(_v, 13, 0, currVal_7); }, null); }
export function View_PreviewPanelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-preview-panel", [], null, null, null, View_PreviewPanelComponent_0, RenderType_PreviewPanelComponent)), i1.ɵdid(1, 573440, null, 0, i10.PreviewPanelComponent, [], null, null)], null, null); }
var PreviewPanelComponentNgFactory = i1.ɵccf("app-preview-panel", i10.PreviewPanelComponent, View_PreviewPanelComponent_Host_0, { isPreviewSidebarOpen: "isPreviewSidebarOpen", previewData: "previewData" }, { previewPopupClose: "previewPopupClose" }, []);
export { PreviewPanelComponentNgFactory as PreviewPanelComponentNgFactory };
