import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {
  DocumentCategoryEnum,
  DocumentDetailDto,
  DocumentModeratorEnum,
  DocumentStatusEnum,
  UpdateDocumentStatusRequest
} from '../../drs/shared/model/patients/document-detail-dto';
import {SelectItem} from 'primeng/api';
import {HttpClient} from '@angular/common/http';
import {PatientsService} from '../../patients.service';
import {Location} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {backendPaths} from '../../core/config';
import {formatDateToString} from '../../utils/date';
import {PatientDetailsHeaderStore} from '../../drs/shared/services/patient-details-header-store.service';

@Component({
  selector: 'app-document-detail',
  templateUrl: './document-detail.component.html',
  styleUrls: ['./document-detail.component.scss']
})
export class DocumentDetailComponent implements OnInit {
  editMode: boolean;
  document: DocumentDetailDto;
  categories: SelectItem[];
  statuses: SelectItem[];
  imageUrl: string;
  documentFiles: string[];
  calendarDate;
  patientId: number;

  @ViewChild('deletePatientDocumentModal', { static: true }) deleteDocumentModal;
  @ViewChild('undoDeletePatientDocumentModal', { static: true }) undoDeletePatientDocumentModal;

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private patientsService: PatientsService,
    private location: Location,
    private patientDetailsHeaderStore: PatientDetailsHeaderStore,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.categories = this.createCategoriesCollection(DocumentCategoryEnum);
    this.statuses = this.createStatusCollection(DocumentStatusEnum);
    this.editMode = false;

    const id = +this.route.snapshot.params['patientId'];
    this.patientDetailsHeaderStore.showPatientDetails(id);

    this.route.data.subscribe(data => {
      this.document = data.document;
      this.patientId = this.route.snapshot.params['patientId'];

      this.imageUrl =
        backendPaths.patientCockpit.patientDocumentFile.replace('{patientId}', String(this.patientId));

      // TODO: sstamenk[MIS001MISA-2085] remove the carousel and show single document.
      this.documentFiles = this.document.documentFileId != null ? [this.document.documentFileId] : [];

      if (this.document.date) {
        const splitDate = this.document.date.split('-');
        const days = Number(splitDate[0]);
        const months = Number(splitDate[1]) - 1;
        const years = Number(splitDate[2]);
        this.calendarDate = new Date(days, months, years);
      }
    });
  }

  saveDocument() {
    this.document.date = formatDateToString(this.calendarDate, 'YYYY-MM-DD');
    this.patientsService.updatePatientDocument(this.patientId, this.document).subscribe(data => {
      this.editMode = false;
      this.document = data;
    });
  }

  goBack() {
    this.location.back();
  }

  createCategoriesCollection(categoriesEnum) {
    const categories: SelectItem[] = [];

    Object.keys(categoriesEnum).map(category => {
      categories.push({label: this.translateService.instant('api.document.category.' + category), value: category});
    });
    return categories;
  }

  createStatusCollection(statusEnum): SelectItem[] {
    return Object.keys(statusEnum)
      .filter(status => DocumentStatusEnum.MARKED_AS_DELETED !== status)
      .map(status => {
        return {label: this.translateService.instant('api.document.status.' + status), value: status};
      });
  }

  getModeratorKeyTranslated(moderator: DocumentModeratorEnum): string {
    if (moderator ===  DocumentModeratorEnum.PATIENT) {
      return this.translateService.instant('document.detail.moderator.PATIENT');
    } else if (moderator === DocumentModeratorEnum.MISANTO) {
      return 'MiSANTO';
    }
    return '-';
  }

  onDeletePatientDocument() {
    this.deleteDocumentModal.showModal(
      () => this.updateDocumentStatus(DocumentStatusEnum.MARKED_AS_DELETED)
    );
  }

  onUndoDeleteDocument() {
    this.undoDeletePatientDocumentModal.showModal(
      () => this.updateDocumentStatus(DocumentStatusEnum.REQUESTED)
    );
  }

  isMarkedAsDeleted() {
    return this.document.status === DocumentStatusEnum.MARKED_AS_DELETED;
  }

  private updateDocumentStatus(updatedStatus: DocumentStatusEnum) {
    const updateStatusRequest: UpdateDocumentStatusRequest = {status: updatedStatus};
    this.patientsService.updatePatientDocumentStatus(this.patientId, this.document.id, updateStatusRequest)
      .subscribe(data => {
        this.document = data;
    });
  }
}
