/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../node_modules/primeng/components/scrollpanel/scrollpanel.ngfactory";
import * as i3 from "primeng/components/scrollpanel/scrollpanel";
import * as i4 from "./app.menu.component";
import * as i5 from "./app-container.component";
import * as i6 from "@ngx-translate/core";
import * as i7 from "@angular/router";
var styles_AppMenuComponent = [];
var RenderType_AppMenuComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppMenuComponent, data: {} });
export { RenderType_AppMenuComponent as RenderType_AppMenuComponent };
export function View_AppMenuComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { layoutMenuScrollerViewChild: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 7, "div", [["class", "layout-sidebar"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(3, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(4, { "layout-sidebar-active": 0 }), (_l()(), i0.ɵeld(5, 0, null, null, 3, "p-scrollPanel", [], null, null, null, i2.View_ScrollPanel_0, i2.RenderType_ScrollPanel)), i0.ɵdid(6, 4374528, [[1, 4], ["scrollPanel", 4]], 0, i3.ScrollPanel, [i0.ElementRef, i0.NgZone], null, null), (_l()(), i0.ɵeld(7, 0, null, 0, 1, "ul", [["app-submenu", ""], ["class", "layout-menu"], ["root", "true"]], null, null, null, View_AppSubMenuComponent_0, RenderType_AppSubMenuComponent)), i0.ɵdid(8, 49152, null, 0, i4.AppSubMenuComponent, [i5.AppContainerComponent, i4.AppMenuComponent], { item: [0, "item"], root: [1, "root"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "layout-sidebar"; var currVal_1 = _ck(_v, 4, 0, _co.app.sidebarActive); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.model; var currVal_3 = "true"; _ck(_v, 8, 0, currVal_2, currVal_3); }, null); }
export function View_AppMenuComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-menu", [], null, null, null, View_AppMenuComponent_0, RenderType_AppMenuComponent)), i0.ɵdid(1, 4308992, null, 0, i4.AppMenuComponent, [i5.AppContainerComponent, i6.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppMenuComponentNgFactory = i0.ɵccf("app-menu", i4.AppMenuComponent, View_AppMenuComponent_Host_0, {}, {}, []);
export { AppMenuComponentNgFactory as AppMenuComponentNgFactory };
var styles_AppSubMenuComponent = [];
var RenderType_AppSubMenuComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppSubMenuComponent, data: { "animation": [{ type: 7, name: "children", definitions: [{ type: 0, name: "visible", styles: { type: 6, styles: { height: "*" }, offset: null }, options: undefined }, { type: 0, name: "hidden", styles: { type: 6, styles: { height: "0px" }, offset: null }, options: undefined }, { type: 1, expr: "visible => hidden", animation: { type: 4, styles: null, timings: "600ms cubic-bezier(0.86, 0, 0.07, 1)" }, options: null }, { type: 1, expr: "hidden => visible", animation: { type: 4, styles: null, timings: "600ms cubic-bezier(0.86, 0, 0.07, 1)" }, options: null }], options: {} }] } });
export { RenderType_AppSubMenuComponent as RenderType_AppSubMenuComponent };
function View_AppSubMenuComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [["class", "pi pi-chevron-down layout-submenu-toggler"]], null, null, null, null, null))], null, null); }
function View_AppSubMenuComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "a", [], [[8, "href", 4], [1, "target", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.itemClick($event, _v.parent.context.$implicit, _v.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", "menuitem-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppSubMenuComponent_3)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_3 = _v.parent.context.$implicit.items; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (_v.parent.context.$implicit.url || "#"); var currVal_1 = _v.parent.context.$implicit.target; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _v.parent.context.$implicit.label; _ck(_v, 2, 0, currVal_2); }); }
function View_AppSubMenuComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [["class", "pi pi-chevron-down layout-submenu-toggler"]], null, null, null, null, null))], null, null); }
function View_AppSubMenuComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "a", [["routerLinkActive", "active-menuitem-routerlink"]], [[1, "target", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.itemClick($event, _v.parent.context.$implicit, _v.parent.context.index) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 671744, [[2, 4]], 0, i7.RouterLinkWithHref, [i7.Router, i7.ActivatedRoute, i1.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵdid(2, 1720320, null, 2, i7.RouterLinkActive, [i7.Router, i0.ElementRef, i0.Renderer2, [2, i7.RouterLink], [2, i7.RouterLinkWithHref]], { routerLinkActiveOptions: [0, "routerLinkActiveOptions"], routerLinkActive: [1, "routerLinkActive"] }, null), i0.ɵqud(603979776, 1, { links: 1 }), i0.ɵqud(603979776, 2, { linksWithHrefs: 1 }), i0.ɵpod(5, { exact: 0 }), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [["class", "menuitem-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppSubMenuComponent_5)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_3 = _v.parent.context.$implicit.routerLink; _ck(_v, 1, 0, currVal_3); var currVal_4 = _ck(_v, 5, 0, true); var currVal_5 = "active-menuitem-routerlink"; _ck(_v, 2, 0, currVal_4, currVal_5); var currVal_7 = _v.parent.context.$implicit.items; _ck(_v, 9, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.target; var currVal_1 = i0.ɵnov(_v, 1).target; var currVal_2 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_6 = _v.parent.context.$implicit.label; _ck(_v, 7, 0, currVal_6); }); }
function View_AppSubMenuComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ul", [["app-submenu", ""]], [[24, "@children", 0]], null, null, View_AppSubMenuComponent_0, RenderType_AppSubMenuComponent)), i0.ɵdid(1, 49152, null, 0, i4.AppSubMenuComponent, [i5.AppContainerComponent, i4.AppMenuComponent], { item: [0, "item"] }, null)], function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isActive(_v.parent.context.index) ? "visible" : "hidden"); _ck(_v, 0, 0, currVal_0); }); }
function View_AppSubMenuComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "li", [], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { "active-menuitem": 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppSubMenuComponent_2)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppSubMenuComponent_4)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppSubMenuComponent_6)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.badgeStyleClass; var currVal_1 = _ck(_v, 3, 0, _co.isActive(_v.context.index)); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = !_v.context.$implicit.routerLink; _ck(_v, 5, 0, currVal_2); var currVal_3 = _v.context.$implicit.routerLink; _ck(_v, 7, 0, currVal_3); var currVal_4 = _v.context.$implicit.items; _ck(_v, 9, 0, currVal_4); }, null); }
export function View_AppSubMenuComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppSubMenuComponent_1)), i0.ɵdid(1, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.root ? _co.item : _co.item.items); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AppSubMenuComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["app-submenu", ""]], null, null, null, View_AppSubMenuComponent_0, RenderType_AppSubMenuComponent)), i0.ɵdid(1, 49152, null, 0, i4.AppSubMenuComponent, [i5.AppContainerComponent, i4.AppMenuComponent], null, null)], null, null); }
var AppSubMenuComponentNgFactory = i0.ɵccf("[app-submenu]", i4.AppSubMenuComponent, View_AppSubMenuComponent_Host_0, { item: "item", root: "root" }, {}, []);
export { AppSubMenuComponentNgFactory as AppSubMenuComponentNgFactory };
