import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { DrsModule } from './drs/drs.module';
import { AppMenuComponent, AppSubMenuComponent } from './app-container/app.menu.component';
import { AppTopBarComponent } from './app-container/app.topbar.component';
import { AppFooterComponent } from './app-container/app.footer.component';
import { ButtonModule } from 'primeng/button';
import {
  CheckboxModule,
  FileUploadModule,
  InputTextModule,
  MessagesModule,
  OverlayPanelModule,
  PasswordModule,
  ScrollPanelModule
} from 'primeng/primeng';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BootstrapService } from './core/bootstrap.service';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { CustomErrorsHandler } from './drs/shared/services/errors-handler';
import { ErrorPopupComponent } from './drs/error-popup/error-popup.component';
import { LoggingErrorsService } from './logging-errors.service';
import { SkygearService } from './skygear.service';
import { FormsModule } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';
import { AuthGuardService } from './core/authGuard.service';
import { UserService } from './core/user.service';
import { MisantoSecurityService } from './misanto-security.service';
import { AppContainerComponent } from './app-container/app-container.component';
import { RegistrationsTableComponent } from './registrations-table/registrations-table.component';
import { RegistrationDetailsComponent } from './registration-details/registration-details.component';
import { HealthInsuranceCardDetailsComponent } from './health-insurance-card-details/health-insurance-card-details.component';
import { UserIdentificationService } from './drs/shared/services/user-identification-service';
import { TableModule } from 'primeng/table';
import { CarouselModule } from 'primeng/primeng';
import { DropdownModule } from 'primeng/dropdown';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { AvatarRegistrationDetailComponent } from './registration-details/avatar-registration-detail/avatar-registration-detail.component';
import { AuditingService } from './drs/shared/services/auditing.service';
import {
  CardImageRegistrationDetailComponent
} from './registration-details/card-image-registration-detail/card-image-registration-detail.component';
import {
  HealthInsuranceCardImageDetailsComponent
} from './health-insurance-card-details/health-insurance-card-image-details/health-insurance-card-image-details.component';
import {
  DocumentUploadComponent
} from './patient-cockpit/document-upload/document-upload.component';
import { PatientListComponent } from './patient-cockpit/patient-list/patient-list.component';
import { PatientDetailComponent } from './patient-cockpit/patient-detail/patient-detail.component';
import { PatientsService } from './patients.service';
import { DocumentDetailComponent } from './document/document-detail/document-detail.component';
import { ConsultationComponent } from './consultation/consultation.component';
import { CalendarModule } from 'primeng/calendar';
import { ScrollingModule } from '@angular/cdk/scrolling';
import {MenuModule} from 'primeng/menu';
import {HealthInsuranceCardOverviewComponent} from './health-insurance-card-overview/health-insurance-card-overview.component';
import {HealthInsuranceCardService} from './drs/shared/services/health-insurance-card-service';
import {DocumentUploadService} from './drs/shared/services/document-upload-service';
import {ToastModule} from 'primeng/toast';
import {PatientDetailDocumentComponent} from './patient-cockpit/patient-detail-document/patient-detail-document.component';
import {PatientHeaderComponent} from './patient-cockpit/patient-header/patient-header.component';
import {PatientDetailsHeaderStore} from './drs/shared/services/patient-details-header-store.service';

export function bootstrapServiceFactory(bootstrapService: BootstrapService) {
  return () => bootstrapService.bootstrapApp();
}

@NgModule({
  declarations: [
    AppComponent,
    AppMenuComponent,
    AppSubMenuComponent,
    AppTopBarComponent,
    AppFooterComponent,
    ErrorPopupComponent,
    AppContainerComponent,
    RegistrationsTableComponent,
    RegistrationDetailsComponent,
    HealthInsuranceCardDetailsComponent,
    HealthInsuranceCardOverviewComponent,
    AvatarRegistrationDetailComponent,
    CardImageRegistrationDetailComponent,
    HealthInsuranceCardImageDetailsComponent,
    PatientListComponent,
    PatientDetailComponent,
    PatientHeaderComponent,
    PatientDetailDocumentComponent,
    DocumentDetailComponent,
    ConsultationComponent,
    DocumentUploadComponent,
  ],
  imports: [
    BrowserModule,
    CoreModule,
    SharedModule,
    BrowserAnimationsModule,
    DrsModule,
    AppRoutingModule,
    ScrollPanelModule,
    ButtonModule,
    MessagesModule,
    FormsModule,
    InputTextModule,
    PasswordModule,
    MultiSelectModule,
    CheckboxModule,
    TableModule,
    CarouselModule,
    ToggleButtonModule,
    DropdownModule,
    CalendarModule,
    ScrollingModule,
    OverlayPanelModule,
    MenuModule,
    FileUploadModule,
    ToastModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: bootstrapServiceFactory,
      deps: [BootstrapService],
      multi: true,
    },
    {provide: ErrorHandler, useClass: CustomErrorsHandler},
    LoggingErrorsService,
    SkygearService,
    AuthGuardService,
    AuditingService,
    UserService,
    MisantoSecurityService,
    UserIdentificationService,
    PatientsService,
    PatientDetailsHeaderStore,
    HealthInsuranceCardService,
    DocumentUploadService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
