import { Component, Input } from '@angular/core';
import {HealthInsuranceCardService} from '../../drs/shared/services/health-insurance-card-service';

@Component({
  selector: 'app-health-insurance-card-image',
  templateUrl: './health-insurance-card-image-details.component.html',
  styleUrls: ['./health-insurance-card-image-details.component.scss'],
})
export class HealthInsuranceCardImageDetailsComponent {
  @Input() cardImgId: string;
  FILE_PATH = HealthInsuranceCardService.HEALTH_INSURANCE_CARD_IMAGE_PATH;

  createImageUrl() {
    return this.FILE_PATH + this.cardImgId;
  }
}

