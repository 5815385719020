import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DrsGraphOverviewDto } from '../shared/model/drs-graph-overview';
import * as _ from 'lodash';

@Component({
  selector: 'app-modal-dialog',
  templateUrl: './modal-dialog.component.html',
  styleUrls: ['./modal-dialog.component.scss']
})
export class ModalDialogComponent {
  displayModal = false;
  graph: DrsGraphOverviewDto;

  @Input() headerName: String;

  @Output() displayChange = new EventEmitter<boolean>();
  @Output() saveChange = new EventEmitter<DrsGraphOverviewDto>();

  constructor() {
  }

  setTreeDetailsModal() {
    this.displayModal = true;
  }

  hideTreeDetailsModal() {
    this.displayModal = false;
  }

  setTreeDetailsData(graph: DrsGraphOverviewDto) {
    this.graph = _.cloneDeep(graph);
  }

  onSaveTreeDetails() {
    this.saveChange.emit(this.graph);
    this.hideTreeDetailsModal();
  }
}
