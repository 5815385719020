<div class="waiting-room-container" [ngClass]="{'show': activeClass}">
  <div class="top-menu-toolbar" #filterTopBar>
    <div class="filter-buttons" #btnOpen (click)="toggleFilter(btnOpen, FILTER_OPEN)">
      <span *ngIf="allPatientsWaiting">
        {{'chat.waitingRoom.sortField.open' | translate}}
        ({{countOpenConversations}})
      </span>
    </div>
    <div class="filter-buttons" #btnMine (click)="toggleFilter(btnMine, FILTER_MINE)">
      <span *ngIf="allPatientsWaiting">
        {{'chat.waitingRoom.sortField.mine' | translate}}
        ({{countMineConversations}})
      </span>
    </div>
    <div class="filter-buttons" #btnCompleted (click)="toggleFilter(btnCompleted, FILTER_COMPLETED)">
      <span *ngIf="allPatientsWaiting">
        {{'chat.waitingRoom.sortField.completed' | translate}}
        ({{countCompletedConversations}})
      </span>
    </div>
  </div>
  <div class="all-patients-cards-container">
    <app-patient-card style="margin: 0;" *ngFor="let patient of patientsWaiting;" [patient]="patient"
                      (selectedPatient)="onSelectPatient($event)"
                      (finishedConversation)="onFinishConversation($event)"
                      [isSelected]="patient.misantoConversation.id === currentPatient?.misantoConversation?.id"></app-patient-card>
  </div>
</div>
