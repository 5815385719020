<div class="registration-avatar-container">
  <img src="{{FILE_PATH + avatarId}}" alt="Avatar"/>
  <div class="controls-accept-deny-registration">
    <p-checkbox (onChange)="accept()"
                [disabled]="previewModeOn"
                [(ngModel)]="checkboxStatusObject.acceptRequest"
                binary="true"
                label="{{'registrations.detail.accept.btn.label' | translate}}"></p-checkbox>
    <p-checkbox (onChange)="decline()"
                [disabled]="previewModeOn"
                [(ngModel)]="checkboxStatusObject.declineRequest"
                binary="true"
                label="{{'registrations.detail.decline.btn.label' | translate}}"></p-checkbox>
  </div>
</div>
