import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';


import { DrsGraph } from '../model/drs-graph';
import { DrsNodeDto } from '../model/drs-node-dto';
import { DrsAppDataDto } from '../model/drs-app-data';
import { AppCoreService } from '../../../core/services/app-core.service';
import { DrsFeatureNodeDto } from '../model/drs-feature-node-dto';
import { CreateGraph, DrsGraphOverviewDto } from '../model/drs-graph-overview';
import { AppDataService } from '../../../core/app-data/app-data.service';
import * as _ from 'lodash';
import { Predicates } from '../../../core/util/observables';
import { filter } from 'rxjs/operators';
import { FullId } from '../model/full-id';
import {LoaderService} from '../../../shared/loader/loader.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

/**
 * Service for drs graph operations.
 */
@Injectable()
export class DrsService {
  private static DRS_PATH = '/drs';
  private static APP_DATA_PATH = '/app-data';
  private static GRAPH_PATH = 'graph';
  public static FILE_PATH = AppCoreService.REST_BASE_URL + DrsService.DRS_PATH + '/file/';
  private NODE_PATH_SEGMENT = 'node';
  private FEATURE_PATH_SEGMENT = 'feature';
  private DUPLICATE_ROOT_NODE_PATH_SEGMENT = 'duplicate-root-node';
  private CLONE_PATH_SEGMENT = 'clone';
  private FILE_PATH_SEGMENT = 'file';
  private VERSIONS_PATH = 'version';
  private ROOT_NODE_PATH = 'rootNode';
  private SEARCH_PATH_SEGMENT = 'search';
  private CUT_PATH = 'cut';
  private COPY_PATH = 'copy';
  private drsBaseRestUrl: string;
  private graphRestUrl: string;

  constructor(private http: HttpClient, private appDataService: AppDataService, private loaderService: LoaderService) {
    this.drsBaseRestUrl = AppCoreService.REST_BASE_URL + DrsService.DRS_PATH;
    this.graphRestUrl = this.drsBaseRestUrl + '/' + DrsService.GRAPH_PATH;
  }

  /**
   * Get аll the versions
   *
   */
  getVersions() {
    return this.http.get(`${this.drsBaseRestUrl}/${this.VERSIONS_PATH}`);
  }

  get selectedVersion(): number {
    return this.appDataService.appData.defaultDrsVersion;
  }

  get appInfoBaseRestUrl(): string {
    return this.drsBaseRestUrl + '/' + this.selectedVersion + DrsService.APP_DATA_PATH;
  }

  /**
   * Gets drs graphs.
   *
   * @param {string} type the id of the graph
   * @return {Observable<DrsGraph[]>}
   */
  getDrsGraphs(type: string): Observable<DrsGraphOverviewDto[]> {
    const params = new HttpParams().set('searchTerm=', `type:${type},version:${this.selectedVersion.toString()}`);
    return this.http.get<DrsGraphOverviewDto[]>(`${this.graphRestUrl}`, { params });
  }

  /**
   * Gets drs graph names by condition id.
   *
   * @param {number} conditionId the id of the condition
   * @return {Observable<DrsGraphOverviewDto[]>}
   */
  getDrsGraphNamesByConditionId(conditionId: number): Observable<DrsGraphOverviewDto[]> {
    const params = new HttpParams().set('conditionId', conditionId.toString());
    return this.http.get<DrsGraphOverviewDto[]>(`${this.drsBaseRestUrl}/${DrsService.GRAPH_PATH}`, { params });
  }

  /**
   * Gets drs graph by id.
   *
   * @param {string} drsGraphId the id of the graph
   * @return {Observable<Order>}
   */
  getDrsGraph(drsGraphId: string): Observable<DrsGraph> {
    const url = `${this.graphRestUrl}/${drsGraphId}`;
    this.loaderService.setUrl(url);

    return this.http.get<DrsGraph>(url).pipe(
      filter(Predicates.noErrors)
    );
  }

  /**
   * Duplicate node templates
   *
   * @param {number} graphFullId the full identifier of the graph
   * @return {DrsNodeDto}
   */
  duplicateNodeTemplate(graphFullId: string) {
    return this.http.post<DrsNodeDto>(
      `${this.graphRestUrl}/${graphFullId}/${this.DUPLICATE_ROOT_NODE_PATH_SEGMENT}`,
      {},
    );
  }

  /**
   * Deletes drs graph by id.
   *
   * @param graphFullId the full id of the graph
   * @return {Observable<any>}
   */
  deleteDrsGraph(graphFullId: string): Observable<any> {
    return this.http.delete(`${this.graphRestUrl}/${graphFullId}`).pipe(
      filter(Predicates.noErrors)
    );
  }

  /**
   * Create new graph.
   * @param newGraph the data for the new graph
   */
  createDrsGraph(newGraph: CreateGraph) {
    return this.http.post(`${this.drsBaseRestUrl}/${DrsService.GRAPH_PATH}`, newGraph);
  }

  /**
   * Edit existing graph.
   * @param {DrsGraph} drsGraphOverview
   */
  updateDrsGraph(drsGraphOverview: DrsGraphOverviewDto): Observable<DrsGraphOverviewDto> {
    const body = {
      label: drsGraphOverview.label,
      type: drsGraphOverview.type
    };
    return this.http.put<DrsGraphOverviewDto>(`${this.graphRestUrl}/${drsGraphOverview.fullIdentifier}`, body).pipe(
      filter(Predicates.noErrors)
    );
  }

  /**
   * Saves drs node and all nodes downward (save the tree below this node).
   *
   * @param graphFullId the id of the graph
   * @param drsNodeDto dto to be saved
   */
  saveDrsNodeDownwards(graphFullId: string, drsNodeDto: DrsNodeDto): Observable<FullId> {
    const url = `${this.graphRestUrl}/${graphFullId}/${this.NODE_PATH_SEGMENT}`;
    this.loaderService.setUrl(url);

    return this.http.post<FullId>(url, drsNodeDto, httpOptions).pipe(
      filter(Predicates.noErrors)
    );
  }

  /**
   * Deletes a node by that node's id
   *
   * @param graphFullId the full id of the related graph
   * @param drsNodeDto dto to be deleted
   */
  deleteDrsNode(graphFullId: string, drsNodeDto: DrsNodeDto): Observable<FullId> {
    const url = `${this.drsBaseRestUrl}/graph/${graphFullId}/node/${drsNodeDto.id}`;
    this.loaderService.setUrl(url);

    return this.http.delete<FullId>(url).pipe(
      filter(Predicates.noErrors)
    );
  }

  /**
   * Saves a drs feature.
   *
   * @param featureFullId the full id of the target feature
   * @param {FormData} formData node which contains the feature to be saved and the image
   */
  updateFeature(featureFullId: string, formData: FormData) {
    const url = `${this.drsBaseRestUrl}/${this.FEATURE_PATH_SEGMENT}/${featureFullId}`;
    this.loaderService.setUrl(url);

    return this.http.put(url, formData).pipe(
      filter(Predicates.noErrors)
    );
  }

  /**
   * Makes a call to the backend to cut the node matching the given id.
   *
   * @param graphFullId the full identifier of the graph where copy is done
   * @param nodeId the id of the node to cut
   * @param drsGraphOverviewDto the graph overview
   */
  cutNodeTemplate(graphFullId: string, nodeId: number, drsGraphOverviewDto: DrsGraphOverviewDto) {
    const url = `${this.drsBaseRestUrl}/graph/${graphFullId}/node`;
    const params = new HttpParams().set('cutFrom', nodeId.toString());
    this.loaderService.setUrl(url);

    return this.http.post(url, drsGraphOverviewDto, { params }).pipe(
      filter(Predicates.noErrors)
    );
  }

  /**
   * Makes a call to the backend to copy the node matching the given id.
   *
   * @param graphFullId the full identifier of the graph where copy is done
   * @param nodeId the id of the node to be copied
   * @param drsGraphOverviewDto the graph overview
   */
  copyNodeTemplate(graphFullId: string, nodeId: number, drsGraphOverviewDto: DrsGraphOverviewDto) {
    const params = new HttpParams().set('copyFrom', nodeId.toString());
    return this.http.post(`${this.drsBaseRestUrl}/graph/${graphFullId}/node`, drsGraphOverviewDto, { params });
  }

  /**
   * Create a drs feature.
   *
   * @param {DrsNodeDto} drsNodeDto node which contains the feature to be saved
   */
  createFeature(drsNodeDto: DrsNodeDto) {
    const body = {
      drsVersion: this.selectedVersion,
      node: drsNodeDto
    };
    return this.http.post(`${this.drsBaseRestUrl}/${this.FEATURE_PATH_SEGMENT}`, body, httpOptions);
  }

  /**
   * Duplicate drs version.
   */
  cloneVersion() {
    return this.http.post(`${this.drsBaseRestUrl}/${this.selectedVersion}/${this.CLONE_PATH_SEGMENT}`, httpOptions);
  }

  /**
   * Upload image.
   */
  uploadImage(data: FormData) {
    return this.http.post(`${this.drsBaseRestUrl}/${this.FILE_PATH_SEGMENT}`, data);
  }

  /**
   * Get feature by id.
   */
  getFeatureById(featureFullId: string) {
    return this.http.get(`${this.drsBaseRestUrl}/${this.FEATURE_PATH_SEGMENT}/${featureFullId}`);
  }

  /**
   * Gets app info.
   *
   * @return {Observable<DrsAppDataDto>}
   */
  getDrsAppData(): Observable<DrsAppDataDto> {
    return this.http.get<DrsAppDataDto>(`${this.appInfoBaseRestUrl}`);
  }

  /**
   * Create feature duplicate.
   *
   * @param featureId the feature id
   * @return {Observable<DrsFeatureNodeDto>}
   */
  duplicateFeature(featureId: string): Observable<DrsFeatureNodeDto> {
    const url = `${this.drsBaseRestUrl}/${this.FEATURE_PATH_SEGMENT}?duplicateFrom=${featureId}`;
    this.loaderService.setUrl(url);

    return this.http.post<DrsFeatureNodeDto>(url, null, httpOptions);
  }

  findNodeInDrsGraph(nodeId: string, drsGraph: DrsGraph) {
    // node stack
    const stack = [];
    // keeps the visited nodeIds
    const visited = [];

    if (drsGraph.rootNode.nodeId === nodeId) {
      // the node is found
      return drsGraph.rootNode;
    }

    stack.push(drsGraph.rootNode);

    while (stack && stack.length !== 0) {
      const topNode = stack[stack.length - 1];
      const unvisitedChildNode = this.getUnvisitedChildNode(topNode, visited);
      if (unvisitedChildNode !== undefined) {
        if (unvisitedChildNode.nodeId === nodeId) {
          // the node is found
          return unvisitedChildNode;
        }
        stack.push(unvisitedChildNode);
        visited.push(unvisitedChildNode.nodeId);
      } else {
        stack.pop();
      }
    }
    return null;
  }

  private getUnvisitedChildNode(node: DrsNodeDto, visited): DrsNodeDto {
    for (const childNode of node.children) {
      if (!_.includes(visited, childNode.nodeId)) {
        return childNode;
      }
    }
    return undefined;
  }
}
