<div class="chat-user-details-container">

  <div class="information-details-row user-name-container">
    <div>
      <p>ID</p>
      <a *ngIf="patientDetails" [routerLink]="['patientcockpit/' + this.patientDetails.id]" target="_blank">
        {{patientDetails.username}}
      </a>
    </div>
  </div>
  <div class="information-details-row">
    <div>
      <p>{{'consultation.userDetails.gender.label' | translate}}</p>
      <h4 *ngIf="patientDetails?.sex">{{'consultation.userDetails.SexEnum.' + patientDetails.sex | translate}}</h4>
    </div>
    <div>
      <p>{{'consultation.userDetails.age.label' | translate}}</p>
      <h4 *ngIf="patientDetails">{{patientDetails.age?.toFixed(1)}}</h4>
    </div>
  </div>
  <div class="information-details-row">
    <div>
      <p>{{'consultation.userDetails.weight.label' | translate}}</p>
      <h4 *ngIf="patientDetails">{{patientDetails.weight}}</h4>
    </div>
    <div>
      <p>{{'consultation.userDetails.height.label' | translate}}</p>
      <h4 *ngIf="patientDetails">{{patientDetails.height}}</h4>
    </div>
  </div>
</div>
