import { Injectable } from '@angular/core';
import { distinctUntilChanged, map, skip, switchMap } from 'rxjs/operators';
import { BehaviorSubject ,  Subscription ,  Observable ,  Subject ,  timer } from 'rxjs';

import { DrsVersionSummary, VersionState } from '../model/drs-version-summary';
import { HttpClient } from '@angular/common/http';
import { backendPaths } from '../../../core/config';
import { AppDataService } from '../../../core/app-data/app-data.service';
import { ApplicationMessage, MessageSeverity } from '../../../core/messages/application-message';
import { TranslateService } from '@ngx-translate/core';
import { AppCoreService } from '../../../core/services/app-core.service';
import { AppDataDto } from '../../../core/app-data/app-data';

/**
 * Manages the editor mode for the DRS.
 */
@Injectable()
export class DrsEditorModeService {

  private static VERSION_STATUS_POLL_INTERVAL_MILLIS = 5000;

  private drsEditorLockedSource = new BehaviorSubject<boolean>(false);

  /**
   * Emits the expected state of the UI editor.
   */
  public readonly isEditorLocked$: Observable<boolean> = this.drsEditorLockedSource.pipe(
    distinctUntilChanged()
  );

  private messagesSource = new BehaviorSubject<ApplicationMessage[]>([]);

  /**
   * Emits application messages related to the DRS.
   */
  public readonly messages$: Observable<ApplicationMessage[]> = this.messagesSource.asObservable();

  private reloadDataSource = new Subject<void>();

  /**
   * Emits when the data should be reloaded.
   */
  public readonly reloadData$ = this.reloadDataSource.asObservable();

  private selectedVersion: number;
  private subscriptions: Subscription;

  constructor(
    private http: HttpClient,
    private appDataService: AppDataService,
    private translate: TranslateService,
    private appCoreService: AppCoreService) {

    this.selectedVersion = this.appDataService.getDefaultDrsVersion();
  }

  /**
   * Checks whether the version with the given number is read-only. Such version cannot be edited
   * at any time, so no polling is done.
   *
   * @param versionNumber the version number
   */
  public isVersionReadOnly(versionNumber: number): Observable<boolean> {
    return this.getVersionSummary(versionNumber)
      .pipe(
        map((versionSummary: DrsVersionSummary) =>
          VersionState.ARCHIVE === versionSummary.state || VersionState.PRODUCTION === versionSummary.state
        )
      );
  }

  /**
   * Puts the UI in 'read-only' mode meaning editing is not allowed at any time.
   */
  public setReadOnlyMode() {
    this.setLocked(true);
    this.notifyReadOnlyMode();
    this.unsubscribeFromEditorModeChanges();
  }

  /**
   * Resets the state of the UI.
   */
  public resetState() {
    this.setLocked(false);
    this.messagesSource.next([]);
  }

  /**
   * Sets the current locked state of the editor.
   *
   * @param locked whether the editor is locked
   */
  public setLocked(locked: boolean) {
    this.drsEditorLockedSource.next(locked);
  }

  /**
   * Stops listening for editor mode changes.
   */
  public unsubscribeFromEditorModeChanges() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  /**
   * Listens for changes in the state of the currently active version and updates the UI accordingly.
   */
  public subscribeForEditorModeChanges() {
    this.subscriptions = new Subscription();
    this.handleDrsVersionChange();

    this.getAppData()
      .pipe(
        map((appData: AppDataDto) => appData.defaultDrsVersion)
      )
      .subscribe((defaultDrsVersion: number) => {
        this.updateVersionIfStale(defaultDrsVersion);
        this.startPollingInterval();
        this.handleEditorLockedChange();
      });
  }

  private handleDrsVersionChange() {
    const subscription = this.appDataService.versionChanged$.subscribe((version: number) => {
      this.selectedVersion = version;
    });
    this.subscriptions.add(subscription);
  }

  private updateVersionIfStale(defaultDrsVersion: number) {
    if (this.appDataService.getDefaultDrsVersion() !== defaultDrsVersion) {
      this.appDataService.setDefaultDrsVersion(defaultDrsVersion);
    }
  }

  private startPollingInterval() {
    const intervalSubscription = timer(0, DrsEditorModeService.VERSION_STATUS_POLL_INTERVAL_MILLIS)
      .pipe(
        switchMap(() => this.isVersionLocked())
      )
      .subscribe((isLocked: boolean) => this.setLocked(isLocked));
    this.subscriptions.add(intervalSubscription);
  }

  private isVersionLocked(): Observable<boolean> {
    return this.getVersionSummary(this.selectedVersion).pipe(
      map((summary: DrsVersionSummary) => summary.locked)
    );
  }

  private getVersionSummary(version: number): Observable<DrsVersionSummary> {
    const url = `${backendPaths.apiBase}${backendPaths.drs.base}/${version}`;
    return this.http.get<DrsVersionSummary>(url);
  }

  private handleEditorLockedChange() {
    // adding skip(1) will skip the initial value
    const subscription = this.isEditorLocked$.pipe(skip(1)).subscribe((isLocked: boolean) => {
      if (isLocked) {
        this.notifyVersionLocked();
      } else {
        this.notifyVersionUnlocked();
        this.reloadAppData();
      }
    });
    this.subscriptions.add(subscription);
  }

  private notifyReadOnlyMode() {
    this.translate.get('drs.version.readOnly').subscribe((message: string) => {
      this.messagesSource.next([{
        severity: MessageSeverity.WARN,
        summary: message,
        closable: false
      }]);
    });
  }

  private notifyVersionLocked() {
    this.translate.get('drs.version.notEditable').subscribe((message: string) => {
      this.messagesSource.next([{
        severity: MessageSeverity.WARN,
        summary: message,
        closable: false
      }]);
    });
  }

  private notifyVersionUnlocked() {
    this.translate.get('drs.version.unlocked').subscribe(message => {
      this.messagesSource.next([{
        severity: MessageSeverity.INFO,
        summary: message,
        closable: true
      }]);
    });
  }

  private reloadAppData() {
    this.getAppData().subscribe((appData: AppDataDto) => {
      this.appDataService.setDefaultDrsVersion(appData.defaultDrsVersion);
      this.reloadDataSource.next();
    });
  }

  private getAppData(): Observable<AppDataDto> {
    const browserLanguage = this.translate.getBrowserLang();
    return this.appCoreService.getAppData(browserLanguage);
  }
}
