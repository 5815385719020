import { Component } from '@angular/core';
import { PatientDetailsHeaderStore } from '../../drs/shared/services/patient-details-header-store.service';
import { formatStringToDate } from '../../utils/date';
import { nullToEmpty } from '../../utils/strings';
import { PatientDetailsDto } from '../../drs/shared/model/patients/patient-details-dto';
import { DateFormat } from '../../utils/date-format';

@Component({
  selector: 'app-patient-header',
  templateUrl: './patient-header.component.html',
  styleUrls: ['./patient-header.component.scss']
})
export class PatientHeaderComponent {

  patient$ = this.patientDetailsService.patient;

  constructor(private patientDetailsService: PatientDetailsHeaderStore) {}

  reformatPatientDateOfBirth(date: string): string {
    return formatStringToDate(date, DateFormat.MISANTO_DATE_DEFAULT);
  }

  emailAddress(patient: PatientDetailsDto): string {
    if (patient) {
      return 'mailto:' + patient.email;
    }
    return '';
  }

  formatAddress(patient: PatientDetailsDto): string {

    if (!patient || !patient.address) {
      return '';
    }

    const patientAddress = patient.address;
    let formattedAddress = `${nullToEmpty(patientAddress.streetName)} ${nullToEmpty(patientAddress.streetNumber)},
${nullToEmpty(patientAddress.zipCode)} ${nullToEmpty(patientAddress.city)}`;

    if (patient.address.countryCode) {
      formattedAddress = `${formattedAddress}, ${patient.address.countryCode}`;
    }

    return formattedAddress;
  }

  routeToPatient(): string {
    return `/patientcockpit/${this.patientDetailsService.getPatientId()}`;
  }
}
