
/**
 * Sorts a table based on a list of status order.
 * @param event the table event.
 * @param statusSortOrder the status order.
 */
export function sortTable(event, statusSortOrder: string[]) {
  const order = {};

  for (let i = 0; i < statusSortOrder.length; i++) {
    order[statusSortOrder[i]] = i;
  }

  event.data.sort((data1, data2) => {
    return compareValues(data1, data2, order, event);
  });
}

function compareValues(data1, data2, order, event) {
  const filedValue1 = data1[event.field];
  const filedValue2 = data2[event.field];
  let result = null;

  if (filedValue1 == null && filedValue2 != null) {
    result = -1;
  } else if (filedValue1 != null && filedValue2 == null) {
    result = 1;
  } else if (filedValue1 == null && filedValue2 == null) {
    result = 0;
  } else if (typeof filedValue1 === 'string' && typeof filedValue2 === 'string' && event.field !== 'status') {
    result = filedValue1.localeCompare(filedValue2);
  } else if (event.field === 'status') {
    result = (order[filedValue1] - order[filedValue2]) || filedValue1.localeCompare(filedValue2);
  } else {
    result = (filedValue1 < filedValue2) ? -1 : (filedValue1 > filedValue2) ? 1 : 0;
  }
  return (event.order * result);
}
