<div class="patient-documents-container">
  <div class="document-heading">
    <h4 class="details-heading" [ngClass]="{'text-underline': markedDeleted}">{{ headerKey | translate }}</h4>
    <h4 *ngIf="displayAddDocumentButton()" class="newDocument">
      <a routerLink="/patient/{{patientId}}/document-upload" class="basic-link addDocument">
        <i class="fa fa-plus">
          {{'document.upload.btn.addDocument.label' | translate}}</i>
      </a>
    </h4>
  </div>
  <div class="document-details">
    <div class="document-panel" *ngFor="let document of documents">
      <div class="row">
        <div class="col">
          <h5>{{ getCategoryKeyTranslated(document.category) }}</h5>
        </div>
      </div>
      <div class="row">
        <div class="col no-line">
          <p>{{ document.description }}</p>
        </div>
        <div class="col align-right">
          <p>{{ getInvestigationDateFormatted(document.date) }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <span>{{ 'patient.cockpit.documents.doctorFirstName.label' | translate }}</span>
          <p>{{ document.doctorFirstName }}</p>
        </div>
        <div class="col">
          <span>{{ 'patient.cockpit.documents.doctorLastName.label' | translate }}</span>
          <p>{{ document.doctorLastName }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <span>{{ 'patient.cockpit.documents.uploader.label' | translate }}</span>
          <p>{{ getModeratorKeyTranslated(document.uploadedBy) }}</p>
        </div>
        <div *ngIf="document.deletedBy" class="col">
          <span>{{ 'document.detail.deleted.by.label' | translate }}</span>
          <p>{{ getModeratorKeyTranslated(document.deletedBy) }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <a routerLink="/patientcockpit/{{ patientId }}/document/{{ document.id }}" class="basic-link">{{ 'patient.cockpit.link.details.label' | translate }}</a>
        </div>
      </div>
    </div>
  </div>
</div>
