import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PatientsService } from '../patients.service';
import { Resolve } from '@angular/router';
import { PatientDto } from '../drs/shared/model/patients/patient-dto';

@Injectable()
export class PatientsResolverService implements Resolve<PatientDto[]> {

  constructor(private patientsService: PatientsService) {
  }

  resolve(): Observable<PatientDto[]> | Promise<PatientDto[]> {
    return this.patientsService.getPatients();
  }
}
