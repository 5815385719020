import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {Observable} from 'rxjs';
import {DrsService} from '../shared/services/drs.service';
import {DrsAppDataDto} from '../shared/model/drs-app-data';

/**
 * Resolves the app data.
 */
@Injectable()
export class AppDataResolverService implements Resolve<DrsAppDataDto> {

  constructor(private drsService: DrsService) {
  }

  resolve(): Observable<DrsAppDataDto> {
    return this.drsService.getDrsAppData();
  }
}
